import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import "./styles.css";
import { calendarClassName } from "utils/constant";
import CalendarLegend from "./CalendarLegend";

const CustomCalendarOwner = ({
  minDateTime,
  equipBlockedDates,
  setEquipBlockedDates,
  handlReset,
}) => {
  const bookedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentBookedDates
  );
  const blockedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentBlockedDates
  );
  const requestedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentRequestedDates
  );

  const isWeekendAvailable = useSelector(
    (state: IStoreValue) => state.equipmentReducer.rentOnWeekend
  );

  const partiallyBookedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partiallyBookedDates
  );
  const partiallyPendingDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partiallyPendingDates
  );

  const AllReservedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.reservedDates
  );

  const partialDatesCounts = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partialDatesCount
  );

  const AllPartialDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.allPartialDates
  );

  const PartialOrderRenterList = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partialOrderRenterList
  );

  const CalendarDateAngle = useSelector(
    (state: IStoreValue) => state.equipmentReducer.calendarDateAngle
  );

  const validDateClassName = (formattedDate) => {
    if (
      !CalendarDateAngle ||
      !CalendarDateAngle.hasOwnProperty(formattedDate)
    ) {
      return "";
    }

    const { startAngle, endAngle } = CalendarDateAngle[formattedDate];

    const key = `${startAngle}-${endAngle}`;
    return calendarClassName[key] || "";
  };

  const getDateStyle = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const today = moment(minDateTime?.minDate).format("YYYY-MM-DD");
    if (formattedDate >= today) {
      let isWeekend = false;

      if (
        (date.weekDay.index === 0 && isWeekendAvailable?.sunday === "0") ||
        (date.weekDay.index === 6 && isWeekendAvailable?.saturday === "0")
      ) {
        isWeekend = true;
      }

      if (blockedDates?.includes(formattedDate)) {
        return {
          className: "",
          style: {
            // backgroundColor: "red",
            color: "#fff",
            cursor: "pointer",
          },
          disabled: false, // Disable selection
        };
      } else if (AllPartialDates?.includes(formattedDate)) {
        const highLight =
        PartialOrderRenterList[formattedDate]?.["usersId"]?.length > 1;
        return {
          className: validDateClassName(formattedDate),
          style: {
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true,
          highLight,
        };
      } else if (bookedDates?.includes(formattedDate)) {
        return {
          className: "full-booked",
          style: {
            backgroundColor: "#faa61a",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
          highLight: false,
        };
      } else if (requestedDates?.includes(formattedDate)) {
        return {
          className: "full-pending",
          style: {
            backgroundColor: "skyblue",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
          highLight: false,
        };
      } else if (isWeekend) {
        return {
          className: "",
          style: {
            backgroundColor: "#606060",
            color: "#b7b7b7",
            cursor: "pointer",
          },
          disabled: false, // Disable selection
        };
      }
    }

    return {
      style: {},
      disabled: false,
      className: "",
    };
  };

  const handleRangeChange = (dates) => {
    setEquipBlockedDates(dates);
  };

  const addDividerInDate = () => {
    const highlitedLines = document.querySelectorAll(".partial-divider");
    highlitedLines?.forEach((ele) => {
      ele.classList.remove("partial-divider");
    });
    setTimeout(() => {
      const dayElement = Array.from(
        document.querySelectorAll(".rmdp-day")
      ).filter((day) => day.querySelector('span[data-cross-line="line"]'));
      dayElement?.forEach((div) => {
        if (!div.getAttribute("class")?.includes("partial-divider")) {
          div.classList.add("partial-divider");
        }
      });
    }, 100);
  };

  const handleMonthChange = () => {
    addDividerInDate();
  }

  useEffect(() => {
    handlReset();
    addDividerInDate();
  }, [blockedDates]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.5rem",
        width: "100%",
        // maxHeight: { xs: "500px", sm: "700px", md: "750px", overflowY: "auto" },
      }}
    >
      <Box
        sx={{
          mt: 0,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          maxWidth: "375px",
          // backgroundColor: "#faa61a0d",
          padding: "10px",
        }}
      >
        {/* New Added Design */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #3a3a3a",
          }}
        >
          <Calendar
            value={equipBlockedDates}
            mapDays={({ date }) => {
              const dateConfig = getDateStyle(date);
              return {
                style: dateConfig.style,
                disabled: dateConfig.disabled,
                "data-status": dateConfig.className,
                "data-cross-line": dateConfig?.highLight ? "line" : "",
              };
            }}
            onChange={handleRangeChange}
            onMonthChange={handleMonthChange}
            numberOfMonths={1}
            format="DD/MM/YYYY"
            minDate={minDateTime.minDate || new Date()}
            disableMonthPicker={true}
            disableYearPicker={true}
          />
        </Box>

        <CalendarLegend owner={true}/>
      </Box>
    </Box>
  );
};

export default CustomCalendarOwner;
