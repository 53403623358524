import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Dispatch,
} from "react";
import moment from "moment";
import {
  Box,
  CircularProgress,
  Typography,
  Backdrop,
  Link,
  Modal,
  FormLabel,
  Button,
  FormControl,
  TextField,
  styled,
  Divider,
} from "@mui/material";

import { IStoreValue } from "utils/types";
import { EquipmentAPIs } from "../../service/api-service";
import { useDispatch, useSelector } from "react-redux";

import { setalertMessagetype, setalertMessage } from "redux/redux-slice";
let reason = "";

const Extendorderdetail = ({ ExtendedOrder, UserInfo, Success }) => {
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );

  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [ActiveOrder, setActiveOrder] = useState([]);
  const [Profile, setProfile] = useState(null);

  const [Loading, setLoading] = React.useState(false);
  const [ExtendedOrdersData, setExtendedOrdersData] = React.useState(false);
  const [ExtendOrderCancelPopup, setExtendOrderCancelPopup] =
    React.useState(false);
  const [ExtendOrderCancelId, setExtendOrderCancelId] = React.useState(null);
  const [ExtendOrderCancelIdreason, setExtendOrderCancelIdreason] =
    React.useState(null);

  const HandleOrderCancelReason = (event) => {
    setExtendOrderCancelIdreason(event.target.value);
  };

  // Get all orders of Equip
  const CancelExtendedOrderByOwner = async () => {
    try {
      setLoading(true);
      // let reason = ExtendOrderCancelIdreason;

      if (reason == "" || reason == null) {
        dispatch(setalertMessage("Please add reason for cancellation."));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }

      let data = {
        order_id: ExtendOrderCancelId.toString(),
        owner_id: AuthProfileData["user_id"].toString(),
        reason: reason,
      };

      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.CancelOrderByOwner(data, headers);

      if (Result["type"] == "RXSUCCESS") {
        reason = "";
        dispatch(setalertMessage("Order Cancel Successfully."));
        dispatch(setalertMessagetype("success"));
        Success("success");
        setLoading(false);
        setExtendOrderCancelPopup(false);
        const modifiedOrders = ActiveOrder.map((order) => {
          if (parseInt(ExtendOrderCancelId) === parseInt(order?.id)) {
            return { ...order, order_status: "order_canceled" };
          }
          return order;
        });
        if (JSON.stringify(modifiedOrders) !== JSON.stringify(ActiveOrder)) {
          setActiveOrder(modifiedOrders);
        }
      } else {
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setActiveOrder(ExtendedOrder);
  }, [ExtendedOrder]);

  useEffect(() => {
    //console.log('comming...');
    setProfile(UserInfo);
  }, []);

  // Extended Order section start
  const loginstyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
  };

  const NewCssTextField = styled(TextField)({
    "& .MuiInput-underline:after": {
      borderBottom: "0 !important",
      borderBottomColor: "transparent !important",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "0 !important",
      borderBottomColor: "transparent !important",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "0 !important",
      borderBottomColor: "transparent !important",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: "0 !important",
      borderBottomColor: "transparent !important",
    },

    "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
      color: "#2d2d2d",
    },
    "& .MuiInputBase-root": {
      color: "#2d2d2d",
      background: "#fff",
      fontFamily: "Gotham !important",
      fontSize: "clamp(12px, 3vw, 15px) !important",
      borderRadius: "10px !important",
      // border: "2px solid #faa61a",
    },
    "&. MuiInputLabel-shrink": {
      color: "red !important",
    },

    "&. MuiInputLabel-shrink.Mui-focused": {
      color: "red !important",
    },

    "& label.Mui-focused": {
      color: "#2d2d2d !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "2px solid #faa61a",
        backgroundColor: "#fff !important",
      },
      "&:hover fieldset": {
        borderColor: "#2d2d2d",
        border: "2px solid #faa61a",
        backgroundColor: "#fff !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#faa61a",
      },
    },
    "& .MuiInputBase-root-MuiFilledInput-root:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    },
  });

  return (
    <>
      {ActiveOrder.length > 0 && (
        <>
          {/* <br /> */}
          <Divider
            sx={{
              borderColor: "#faa61a",
              borderWidth: "1.2px",
              mt: 1.5,
              mb: 1.5,
            }}
          />
          {/* <br /> */}
          <Box
            className="rate-list-grid g-total"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Typography
              sx={{
                color: "#faa61a",
                fontFamily: "Montserrat !important",
                fontWeight: "700",
              }}
            >
              EXTENDED ORDER DETAILS:
            </Typography>
          </Box>

          {ActiveOrder.map((Ex_Order, i) => {
            var security = Ex_Order.security_deposit;
            var refund_security =
              Ex_Order.returnd_deposit != undefined &&
              Ex_Order.returnd_deposit != null &&
              Ex_Order.returnd_deposit != 0
                ? Ex_Order.returnd_deposit
                : 0;

            var epf = Ex_Order.equipment_protection_fees;

            var refund_epf =
              Ex_Order.refunded_protection_fee != undefined &&
              Ex_Order.refunded_protection_fee != null &&
              Ex_Order.refunded_protection_fee != 0
                ? Ex_Order.refunded_protection_fee
                : 0;

            var final_security = security - refund_security;

            var admin_comm =
              Ex_Order.admin_commission != undefined &&
              Ex_Order.admin_commission != null &&
              Ex_Order.admin_commission != 0
                ? Ex_Order.admin_commission
                : 0;

            var total_charges = Ex_Order.price + final_security - admin_comm;

            refund_security =
              Ex_Order.returnd_deposit != undefined &&
              Ex_Order.returnd_deposit != null &&
              Ex_Order.returnd_deposit != 0
                ? Ex_Order.returnd_deposit
                : "";

            refund_epf =
              Ex_Order.refunded_protection_fee != undefined &&
              Ex_Order.refunded_protection_fee != null &&
              Ex_Order.refunded_protection_fee != 0
                ? Ex_Order.refunded_protection_fee
                : "";

            epf =
              Ex_Order.equipment_protection_fees != undefined &&
              Ex_Order.equipment_protection_fees != null &&
              Ex_Order.equipment_protection_fees != 0
                ? Ex_Order.equipment_protection_fees
                : "";

            return (
              <Box key={Ex_Order?.id}>
                <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontWeight: "500",
                      }}
                    >
                      Rental Dates
                    </Typography>
                <Box
                  sx={{
                    // display:'grid',
                    // gridTemplateColumns:'repeat(2, 1fr)',
                    display: "flex",
                    alignItems:'center',
                    // alignItems: "flex-end",
                    // justifyContent: "space-between",
                    gap: "0.5rem",
                  }}
                >
                  <Box
                  // className="owner-edit-dates"
                  sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'2px'
                  }}
                  >
                    
                    <Typography
                      // className="modal-dates-grid"
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",

                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        Start:
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        {moment(Ex_Order.start_date, ["YYYY-MM-DD"]).format(
                          "MM/DD/YY"
                        )}{" "}
                        {moment(Ex_Order.start_time, ["HH:mm:ii"]).format(
                          "hh:mm a"
                        )}
                        &nbsp;(
                        {Ex_Order["timeZone"] ? Ex_Order["timeZone"] : "UTC"})
                      </Typography>
                    </Typography>
                    <Typography
                      // className="modal-dates-grid"
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",

                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        End:
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        {moment(Ex_Order.end_date, ["YYYY-MM-DD"]).format(
                          "MM/DD/YY"
                        )}{" "}
                        {moment(Ex_Order.end_time, ["HH:mm:ii"]).format(
                          "hh:mm a"
                        )}
                        &nbsp;(
                        {Ex_Order["timeZone"] ? Ex_Order["timeZone"] : "UTC"})
                      </Typography>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                    // className="owner-img-grid"
                  >
                    <Box
                    // className="owner-img"
                    >
                      <Box
                        component={"img"}
                        src={Ex_Order?.profile_pic}
                        alt=""
                        sx={{
                          width: {
                            xs: "40px",
                            sm: "40px",
                            md: "45px",
                            lg: "45px",
                            xl: "45px",
                          },
                          height: {
                            xs: "40px",
                            sm: "40px",
                            md: "45px",
                            lg: "45px",
                            xl: "45px",
                          },
                          border: "2px solid white",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>

                    <Box
                      // className="ro-name"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "13px",
                            sm: "13px",
                            md: "14px",
                            lg: "14px",
                            xl: "16px",
                          },
                        }}
                      >
                        RENTER:
                      </Typography>
                      <Typography
                        // className="gem-ro-name"
                        sx={{
                          color: "#faa61a",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "14px",
                            lg: "14px",
                            xl: "16px",
                          },
                        }}
                      >
                        {Ex_Order?.first_name?.charAt(0)?.toUpperCase() +
                          Ex_Order?.first_name?.slice(1)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="equipment-rate-div">
                  <Box
                    // className="equipment-rate-inner"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Box
                      // className="rate-list-grid"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        Rental Price
                      </Typography>

                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        $
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(Ex_Order.price)}
                      </Typography>
                    </Box>
                    <Box
                      // className="rate-list-grid"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        Security Deposit
                        {refund_security > 0 && (
                          <>
                            (Refunded-$
                            {new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }).format(refund_security)}
                            )
                          </>
                        )}
                      </Typography>

                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        $
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(final_security)}
                      </Typography>
                    </Box>

                    <Box
                      // className="rate-list-grid"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        Admin Fee
                      </Typography>

                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        -$
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(Ex_Order.admin_commission)}
                      </Typography>
                    </Box>

                    {Ex_Order.order_status == "order_canceled" && (
                      <Box
                        className="rate-list-grid"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#faa61a" }}>
                          Order has been Cancelled
                        </Typography>
                      </Box>
                    )}
                    {/* <Divider
                      sx={{
                        borderColor: "#faa61a",
                        borderWidth: "1.2px",
                        mt: 1.5,
                        mb: 1.5,
                      }}
                    /> */}
                    <Box
                      // className="rate-list-grid"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "14px",
                            sm: "14px",
                            md: "15px",
                            lg: "16px",
                            xl: "16px",
                          },
                          fontWeight: "800",
                        }}
                      >
                        Total
                      </Typography>

                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "14px",
                            sm: "14px",
                            md: "15px",
                            lg: "16px",
                            xl: "16px",
                          },
                          fontWeight: "800",
                        }}
                      >
                        $
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(total_charges)}
                      </Typography>
                    </Box>

                    {Ex_Order.order_status == "payment_complete" && (
                      <Box
                        className="rate-list-btn"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#c21414",
                            cursor: "pointer",
                            textDecorationLine: "underline",
                            textDecorationColor: "#c21414",
                            // padding: "2px 8px",
                            borderRadius: "5px",
                            fontFamily: "GothamBold !important",
                          }}
                          onClick={() => {
                            setExtendOrderCancelIdreason("");
                            setExtendOrderCancelId(Ex_Order.id);
                            setExtendOrderCancelPopup(true);
                          }}
                        >
                          X&nbsp;Cancel Order
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Divider
                    sx={{
                      borderColor: "#faa61a",
                      borderWidth: "1.2px",
                      mt: 1.5,
                      mb: 1.5,
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </>
      )}

      {/* Extended order cancel popup */}
      <Modal
        keepMounted
        open={ExtendOrderCancelPopup}
        onClose={() => setExtendOrderCancelPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography
                  variant="h4"
                  style={{ color: "#faa61a", textAlign: "center" }}
                >
                  Cancel
                </Typography>

                <Box>
                  {Loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={Loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                </Box>

                <Box>
                  <FormControl
                    fullWidth
                    className="form-fields"
                    sx={{ width: "100%" }}
                  >
                    <NewCssTextField
                      id="outlined-multiline-static"
                      multiline
                      placeholder={"Cancellation reason"}
                      // maxRows={2}
                      rows={2}
                      fullWidth
                      variant="filled"
                      //label="Instructions"
                      onChange={(value) => {
                        reason = value.target.value;
                      }}
                      // value={ExtendOrderCancelIdreason}
                      className="popup-input"
                    />
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: "#139246" }}
                    onClickCapture={CancelExtendedOrderByOwner}
                  >
                    Ok
                  </Button>
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: "#faa61a !important" }}
                    onClickCapture={() => {
                      setExtendOrderCancelPopup(false);
                      reason = "";
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Extendorderdetail;
