import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css";
import "./styles.css";
import { IStoreValue } from "utils/types";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { getDatesInRange } from "utils/commonFxns";
import { getDateAngles, isSelectionPartial } from "utils/partiallyBookedTime";
import { calendarClassName } from "utils/constant";
import CalendarLegend from "./CalendarLegend";

const CustomCalendar = ({
  equipRentedDates,
  equipRentedTimes,
  setEquipRentedDates,
  label = null,
  minDateTime,
  setEquipRentedTimes,
  setTempDates = undefined,
  setTempRentalTimes = undefined,
  currentMonth,
  setCurrentMonth,
  calendarRef,
  enableDateSelection,
  setEnableDateSelection,
  modalOpen,
}) => {
  const dispatch = useDispatch();
  // const [selectedRange, setSelectedRange] = useState([null, null]);
  const bookedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentBookedDates
  );
  const blockedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentBlockedDates
  );
  const requestedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentRequestedDates
  );

  const isWeekendAvailable = useSelector(
    (state: IStoreValue) => state.equipmentReducer.rentOnWeekend
  );

  const partiallyBookedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partiallyBookedDates
  );
  const partiallyPendingDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partiallyPendingDates
  );

  const AllReservedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.reservedDates
  );

  const partialDatesCounts = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partialDatesCount
  );

  const PartialOrderRenterList = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partialOrderRenterList
  );

  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );

  const AllPartialDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.allPartialDates
  );

  const CalendarDateAngle = useSelector(
    (state: IStoreValue) => state.equipmentReducer.calendarDateAngle
  );

  const AllPartialDatesTimes = useSelector(
    (state: IStoreValue) => state.equipmentReducer.allPartialDatesTimes
  );

  const validDateClassName = (formattedDate) => {
    if (
      !CalendarDateAngle ||
      !CalendarDateAngle.hasOwnProperty(formattedDate)
    ) {
      return "";
    }

    const { startAngle, endAngle } = CalendarDateAngle[formattedDate];

    const key = `${startAngle}-${endAngle}`;
    return calendarClassName[key] || "";
  };

  const getDateStyle = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const today = moment(minDateTime?.minDate).format("YYYY-MM-DD");
    if (formattedDate >= today) {
      if (blockedDates?.includes(formattedDate)) {
        return {
          className: "full-booked",
          style: {
            backgroundColor: "#faa61a",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
          highLight: false,
        };
      } else if (AllPartialDates?.includes(formattedDate)) {
        const highLight =
          PartialOrderRenterList[formattedDate]?.["usersId"]?.length > 1;
        if (AllReservedDates[0]?.hasOwnProperty(formattedDate)) {
          return {
            className: validDateClassName(formattedDate),
            style: {
              color: "#fff",
              cursor: "pointer",
            },
            disabled: false,
            highLight,
          };
        } else {
          return {
            className: validDateClassName(formattedDate),
            style: {
              color: "#fff",
              cursor: "not-allowed",
            },
            disabled: true,
            highLight,
          };
        }
      } else if (bookedDates?.includes(formattedDate)) {
        return {
          className: "full-booked",
          style: {
            backgroundColor: "#faa61a",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
          highLight: false,
        };
      } else if (requestedDates?.includes(formattedDate)) {
        return {
          className: "full-pending",
          style: {
            backgroundColor: "skyblue",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
          highLight: false,
        };
      }
    }
    return {
      style: {},
      disabled: false,
      className: "",
      highLight: false,
    };
  };

  const validatingInBtwnDates = (dateRange) => {
    // console.log("date range",dateRange);
    for (const date of dateRange) {
      if (blockedDates?.includes(date)) {
        return false;
      } else if (AllReservedDates[0]?.hasOwnProperty(date)) {
        return true;
      } else if (requestedDates?.includes(date)) {
        return false;
      } else if (bookedDates.includes(date)) {
        return false;
      }
    }
    return true;
  };

  const isRangeValid = (dateRange) => {
    // console.log("dateRange first",dateRange)
    const matchValue = dateRange.filter((date) => date in AllReservedDates[0]);
    if (matchValue.length >= 1 && dateRange?.length > 2) {
      if (matchValue.length > 2) {
        return false;
      }

      const start = matchValue.includes(dateRange[0]) ? 1 : 0;
      const end = matchValue.includes(dateRange[dateRange.length - 1])
        ? dateRange.length - 1
        : dateRange.length;

      if (
        matchValue.length === 1 &&
        (matchValue.includes(dateRange[0]) ||
          matchValue.includes(dateRange[dateRange.length - 1]))
      ) {
        return validatingInBtwnDates(dateRange.slice(start, end));
      }

      if (
        matchValue.length === 2 &&
        matchValue.includes(dateRange[0]) &&
        matchValue.includes(dateRange[dateRange.length - 1])
      ) {
        return validatingInBtwnDates(dateRange.slice(1, dateRange.length - 1));
      }

      return false;
    }
    return validatingInBtwnDates(dateRange);
  };

  const handleRangeChange = (range) => {
    const [startDate, endDate] = range;
    // console.log("startDate.weekDay.index", startDate?.weekDay?.index);
    // console.log("endDate.weekDay.index", endDate?.weekDay?.index);
    if (
      (startDate?.weekDay?.index === 0 || endDate?.weekDay?.index === 0) &&
      isWeekendAvailable?.sunday === "0"
    ) {
      dispatch(
        setalertMessage("This owner does not allow [pickup/delivery] on sunday")
      );
      dispatch(setalertMessagetype("error"));
      setEquipRentedDates([equipRentedDates[0], equipRentedDates[1]]);
      return;
    } else if (
      (startDate?.weekDay?.index === 6 || endDate?.weekDay?.index === 6) &&
      isWeekendAvailable?.saturday === "0"
    ) {
      dispatch(
        setalertMessage(
          "This owner does not allow [pickup/delivery] on Saturday."
        )
      );
      dispatch(setalertMessagetype("error"));
      setEquipRentedDates([equipRentedDates[0], equipRentedDates[1]]);
      return;
    }
    // if(startDate && startDate.weekDay.index === 0 && isWeekendAvailable?.sunday === "0"){

    // }
    // console.log("startDate",startDate)
    // console.log("endDate",endDate)
    // if (equipRentedDates[0] && equipRentedDates[1] && startDate) {
    //   if (equipRentedDates[0].toDate() > startDate.toDate()) {
    //     setEquipRentedDates([startDate, equipRentedDates[1]]);
    //     setEquipRentedTimes((prev) => ({ ...prev, startTime: null }));
    //     if (setTempRentalTimes) {
    //       setTempRentalTimes((prev) => ({ ...prev, startTime: null }));
    //     }
    //     if (setTempDates) {
    //       setTempDates([startDate, null]);
    //     }
    //   } else if (equipRentedDates[1].toDate() < startDate) {
    //     setEquipRentedDates([equipRentedDates[0], startDate]);
    //     setEquipRentedTimes((prev) => ({ ...prev, endTime: null }));
    //     if (setTempRentalTimes) {
    //       setTempRentalTimes((prev) => ({ ...prev, endTime: null }));
    //     }
    //     if (setTempDates) {
    //       setTempDates([null, startDate]);
    //     }
    //   }
    //   return;
    // } else if (
    //   startDate &&
    //   equipRentedDates[0] &&
    //   equipRentedDates[0].toDate() > startDate.toDate()
    // ) {
    //   setEquipRentedDates([startDate, null]);
    //   setEquipRentedTimes({ startTime: null, endTime: null });
    //   if (setTempRentalTimes) {
    //     setTempRentalTimes({ startTime: null, endTime: null });
    //   }
    //   if (setTempDates) {
    //     setTempDates([startDate, null]);
    //   }
    //   return;
    // }

    if (
      startDate &&
      equipRentedDates[0] &&
      !equipRentedDates[1] &&
      equipRentedDates[0].toDate() > startDate.toDate()
    ) {
      setEquipRentedDates([startDate, null]);
      setEquipRentedTimes({ startTime: null, endTime: null });
      if (setTempRentalTimes) {
        setTempRentalTimes({ startTime: null, endTime: null });
      }
      if (setTempDates) {
        setTempDates([startDate, null]);
      }
      return;
    }

    if (
      startDate &&
      endDate &&
      !isRangeValid(
        getDatesInRange(
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD")
        )
      )
    ) {
      setEquipRentedDates([startDate, null]);
      if (setTempDates) {
        setTempDates([startDate, null]);
      }
      dispatch(setalertMessage("Please select a valid date range."));
      dispatch(setalertMessagetype("error"));
      return;
    }

    if (equipRentedDates[0] && equipRentedDates[1] && startDate) {
      let startD = null;
      let endD = null;
      if (enableDateSelection === "end") {
        startD = equipRentedDates[0];
        endD = startDate;
      }
      if (enableDateSelection === "start") {
        startD = startDate;
        endD = equipRentedDates[1];
      }
      if (
        !isRangeValid(
          getDatesInRange(
            startD.format("YYYY-MM-DD"),
            endD.format("YYYY-MM-DD")
          )
        )
      ) {
        setEquipRentedDates([equipRentedDates[0], equipRentedDates[1]]);
        if (setTempDates) {
          setTempDates([equipRentedDates[0], equipRentedDates[1]]);
        }
        dispatch(setalertMessage("Please select a valid date range."));
        dispatch(setalertMessagetype("error"));
        return;
      }
    }

    if (
      endDate ||
      (startDate &&
        equipRentedDates[0] &&
        equipRentedDates[1] &&
        ((enableDateSelection === "end" &&
          startDate.toDate() >= equipRentedDates[0].toDate()) ||
          (enableDateSelection === "start" &&
            startDate.toDate() <= equipRentedDates[1].toDate())))
    ) {
      let startFormatDate = startDate?.format("YYYY-MM-DD");
      let endFormatDate = endDate?.format("YYYY-MM-DD");
      if (!endDate) {
        if (enableDateSelection === "end") {
          startFormatDate = equipRentedDates[0]?.format("YYYY-MM-DD");
          endFormatDate = startDate?.format("YYYY-MM-DD");
        } else {
          startFormatDate = startDate?.format("YYYY-MM-DD");
          endFormatDate = equipRentedDates[0]?.format("YYYY-MM-DD");
        }
      }

      if (startFormatDate !== endFormatDate) {
        const reservedDates = AllReservedDates[0];
        const startDateSlots = reservedDates?.[startFormatDate];
        const endDateSlots = reservedDates?.[endFormatDate];
        // console.log("startDateSlots",startDateSlots)
        // console.log("endDateSlots",endDateSlots)
        const alertFunc = () => {
          let sDate;
          let eDate = null;
          if (equipRentedDates[0]) {
            sDate = equipRentedDates[0];
          } else {
            sDate = startDate;
          }
          if (equipRentedDates[1]) {
            eDate = equipRentedDates[1];
          }
          setEquipRentedDates([sDate, eDate]);
          if (setTempDates) {
            setTempDates([sDate, eDate]);
          }
          // setEquipRentedTimes(prev=>({...prev,endTime:null}))
          // if(setTempRentalTimes) {
          //   setTempRentalTimes(prev=>({...prev,endTime:null}))
          // }
          dispatch(setalertMessage("Please select a valid date range."));
          dispatch(setalertMessagetype("error"));
        };

        if (endDateSlots) {
          if (endFormatDate > startFormatDate) {
            if (endDateSlots["00:00:00"]["disable"]) {
              alertFunc();
              return;
            }
          } else {
            if (endDateSlots["23:30:00"]["disable"]) {
              alertFunc();
              return;
            }
          }
        } else if (startDateSlots) {
          if (endFormatDate > startFormatDate) {
            if (startDateSlots["23:30:00"]["disable"]) {
              alertFunc();
              return;
            }
          } else {
            if (startDateSlots["00:00:00"]["disable"]) {
              alertFunc();
              return;
            }
          }
        }
      }
      // setEquipRentedTimes((prev) => ({ ...prev, endTime: null }));
    }

    setEquipRentedDates(range);
    if (setTempDates) {
      setTempDates(range);
    }
    if (
      enableDateSelection === "end" &&
      startDate &&
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      startDate.toDate() >= equipRentedDates[0].toDate()
    ) {
      setEquipRentedDates([equipRentedDates[0], startDate]);
      setEquipRentedTimes((prev) => ({ ...prev, endTime: null }));
      if (setTempRentalTimes) {
        setTempRentalTimes((prev) => ({ ...prev, endTime: null }));
      }
      if (setTempDates) {
        setTempDates([equipRentedDates[0], startDate]);
      }
      // return;
    }
    // if (
    //   enableDateSelection === "end" &&
    //   startDate &&
    //   equipRentedDates[0] &&
    //   equipRentedDates[1] &&
    //   startDate.toDate() < equipRentedDates[0].toDate()
    // ) {
    //   setEquipRentedTimes({ startTime: null, endTime: null });
    //   if (setTempRentalTimes) {
    //     setTempRentalTimes({ startTime: null, endTime: null });
    //   }
    // }

    if (
      enableDateSelection === "start" &&
      startDate &&
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      startDate.toDate() <= equipRentedDates[1].toDate()
    ) {
      setEquipRentedDates([startDate, equipRentedDates[1]]);
      setEquipRentedTimes((prev) => ({ ...prev, startTime: null }));
      if (setTempRentalTimes) {
        setTempRentalTimes((prev) => ({ ...prev, startTime: null }));
      }
      if (setTempDates) {
        setTempDates([startDate, null]);
      }
      // setEnableDateSelection("end");
      // return;
    }

    // if (
    //   enableDateSelection === "start" &&
    //   startDate &&
    //   equipRentedDates[0] &&
    //   equipRentedDates[1] &&
    //   startDate.toDate() > equipRentedDates[1].toDate()
    // ) {
    //   setEquipRentedTimes((prev) => ({ startTime: null, endTime: null }));
    //   if (setTempRentalTimes) {
    //     setTempRentalTimes((prev) => ({ startTime: null, endTime: null }));
    //   }
    // }
    if (
      startDate &&
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      ((enableDateSelection === "end" &&
        startDate.toDate() < equipRentedDates[0].toDate()) ||
        (enableDateSelection === "start" &&
          startDate.toDate() > equipRentedDates[1].toDate()))
    ) {
      setEquipRentedTimes({ startTime: null, endTime: null });

      if (setTempRentalTimes) {
        setTempRentalTimes({ startTime: null, endTime: null });
      }
    }

    if (
      range[0]?.format("MM/DD/YY") ===
      moment(minDateTime?.minDate).format("MM/DD/YY")
    ) {
      setEquipRentedTimes((prev) => {
        if (prev?.startTime < minDateTime?.minTime) {
          const startMoment = moment(minDateTime?.minTime, "HH:mm");
          const endMoment = startMoment.clone().add(30, "minutes");
          return {
            startTime: minDateTime?.minTime,
            endTime: endMoment.format("HH:mm"),
          };
        }
        return { ...prev };
      });
    } else if (
      range[0] &&
      range[1] &&
      range[0]?.format("MM/DD/YY") === range[1]?.format("MM/DD/YY")
    ) {
      setEquipRentedTimes((prev) => {
        if (prev?.startTime > prev?.endTime) {
          const startMoment = moment(prev?.startTime, "HH:mm");
          const endMoment = startMoment.clone().add(30, "minutes");
          return { ...prev, endTime: endMoment.format("HH:mm") };
        }
        return { ...prev };
      });
    }
    // setEquipRentedDates(range);
    // if (setTempDates) {
    //   setTempDates(range);
    // }
    // }
  };

  const removeSelectionAttributes = () => {
    const elementsToRemove = document.querySelectorAll(
      ".partial-selecting-end, .booked-start-pending-end, .booked-end-pending-start, .partial-selecting-start,.selection-divider"
    );

    elementsToRemove.forEach((element) => {
      element.classList.remove(
        "partial-selecting-end",
        "booked-start-pending-end",
        "booked-end-pending-start",
        "partial-selecting-start",
        "selection-divider"
      );
    });
  };

  const addDividerInDate = () => {
    const highlitedLines = document.querySelectorAll(".partial-divider");
    highlitedLines?.forEach((ele) => {
      ele.classList.remove("partial-divider");
    });
    setTimeout(() => {
      const dayElement = Array.from(
        document.querySelectorAll(".rmdp-day")
      ).filter((day) => day.querySelector('span[data-cross-line="line"]'));
      dayElement?.forEach((div) => {
        if (!div.getAttribute("class")?.includes("partial-divider")) {
          div.classList.add("partial-divider");
        }
      });
    }, 100);
  };

  const removeSelectionDivider = (element) => {
    if (element) {
      element.forEach((el) => {
        el.classList.remove("selection-divider");
      });
    }
    // const elementsToRemove = document.querySelectorAll(
    //   ".selection-divider"
    // );
  };

  const addSelectionDivider = (firstElement, secondElement = null) => {
    if (firstElement) {
      firstElement.forEach((el) => {
        if (!el.getAttribute("class").includes("selection-divider")) {
          el.classList.add("selection-divider");
        }
      });
    }
    // if(secondElement && !secondElement.getAttribute("class").includes("selection-divider")) {
    //   secondElement.classList.add("selection-divider");
    // }
  };

  const handleMonthChange = (newMonth) => {
    // const date = calendarRef.current.date;
    // console.log("date and time",date);
    // console.log("date and time month",newMonth["month"]);
    const monthKey = "month";
    const yearKey = "year";
    calendarRef.current.set(monthKey, newMonth[monthKey]);
    calendarRef.current.set(yearKey, newMonth[yearKey]);
    removeSelectionAttributes();
    addDividerInDate();
    const [selectedDay, selectedMonth, selectedYear] = newMonth
      ?.format("DD/MM/YYYY")
      ?.split("/");
    if (
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      equipRentedTimes?.startTime &&
      equipRentedTimes?.endTime
    ) {
      const [choosedDay, choosedMonth, choosedYear] = equipRentedDates[0]
        ?.format("DD/MM/YYYY")
        ?.split("/");
      const [chooseEndDay, chooseEndMonth, chooseEndYear] = equipRentedDates[1]
        ?.format("DD/MM/YYYY")
        ?.split("/");
      if (
        (selectedMonth === choosedMonth || selectedMonth === chooseEndMonth) &&
        (selectedYear === choosedYear || selectedYear === chooseEndYear)
      ) {
        setTimeout(
          () => ModifyPartialSelection({ equipRentedDates, equipRentedTimes }),
          100
        );
      }
    }
    setCurrentMonth(newMonth);
  };

  const getPartialDates = ({ startDate, startTime, endDate, endTime }) => {
    if (startDate === endDate) {
      const partialDate = { startDate: null, endDate: null };
      if (startTime === "00:00:00" && endTime === "23:30:00") {
        //
      } else if (startTime === "00:00:00") {
        partialDate["endDate"] = startDate;
      } else {
        partialDate["startDate"] = startDate;
      }
      return partialDate;
    } else {
      const partialDate = { startDate: null, endDate: null };
      if (startTime !== "00:00:00") {
        partialDate["startDate"] = startDate;
      }
      if (endTime !== "23:30:00") {
        partialDate["endDate"] = endDate;
      }
      return partialDate;
    }
  };

  const handleSelectionDivider = (date, elements) => {
    if (!date || !AllReservedDates[0]?.hasOwnProperty(date)) return;

    const usersList = PartialOrderRenterList[date]?.["usersId"];
    const isPartial = usersList?.length > 1 || usersList[0] !== AuthUserId;

    if (isPartial) {
      addSelectionDivider(elements);
    } else {
      removeSelectionDivider(elements);
    }
  };

  const handleSelectionColorization = (element, date) => {
    const elementAttribute = element
      .querySelector("span[data-status]")
      ?.getAttribute("data-status");
    if (!date) {
      return false;
    }
    if (
      elementAttribute?.includes("full-booked") ||
      elementAttribute?.includes("full-pending")
    ) {
      return false;
    }
    const startDate = equipRentedDates[0]?.format("YYYY-MM-DD");
    const endDate = equipRentedDates[1]?.format("YYYY-MM-DD");
    const startTime = equipRentedTimes?.startTime;
    const endTime = equipRentedTimes?.endTime;
    const startTimeNext = "00:00:00";
    const endTimeFirst = "23:30:00";
    const theDateObj = {
      startDate,
      endDate,
      startTime,
      endTime,
      status: "requested",
    };
    if (!endDate) {
      const partialDateObj = AllReservedDates[0][date];
      let endTimeM = "23:30:00";
      const timeSlots = Object.keys(partialDateObj);
      const [hours, minutes] = startTime?.split(":");
      const hoursIdx = Number(hours) * 2;
      const start = minutes === "30" ? hoursIdx + 1 : hoursIdx;
      for (let i = start; i < 48; i++) {
        const time = timeSlots[i];
        if (!partialDateObj[time]["disable"]) {
          endTimeM = time;
        } else {
          break;
        }
      }
      theDateObj["endDate"] = date;
      theDateObj["endTime"] = endTimeM;
    } else if (startDate !== endDate) {
      if (startDate === date) {
        theDateObj["endDate"] = date;
        theDateObj["endTime"] = endTimeFirst;
      }
      if (endDate === date) {
        theDateObj["startDate"] = date;
        theDateObj["startTime"] = startTimeNext;
      }
    }
    const allPartialDatesTimes = [...AllPartialDatesTimes, { ...theDateObj }];
    const { startAngle, endAngle } = getDateAngles({
      date,
      allPartialDatesTimes,
    });
    const key = `${startAngle}-${endAngle}`;
    const name = calendarClassName[key] || "";
    if (!element.classList.contains(name)) {
      element.classList.add(name);
    }
  };

  const ModifyPartialSelection = ({ equipRentedDates, equipRentedTimes }) => {
    if (
      equipRentedDates[0] &&
      equipRentedTimes?.startTime &&
      !equipRentedDates[1] &&
      !equipRentedTimes?.endTime
    ) {
      const startDate = equipRentedDates[0]?.format("YYYY-MM-DD");
      const startElements = document.querySelectorAll(".rmdp-day.rmdp-range");
      if (AllReservedDates.length) {
        handleSelectionDivider(startDate, startElements);
        if (AllReservedDates[0]?.hasOwnProperty(startDate)) {
          startElements.forEach((el) => {
            handleSelectionColorization(el, startDate);
          });
        }
      }
      if (!AllReservedDates[0]?.hasOwnProperty(startDate)) {
        startElements.forEach((el) => {
          if (startDate && equipRentedTimes?.startTime !== "00:00:00") {
            if (!el.classList.contains("partial-selecting-start")) {
              el.classList.add("partial-selecting-start");
            }
          } else {
            el.classList.remove(
              "partial-selecting-start",
            );
          }
        });
      }
    } else if (
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      equipRentedTimes?.startTime &&
      equipRentedTimes?.endTime
    ) {
      const { startDate, endDate } = getPartialDates({
        startDate: equipRentedDates[0]?.format("YYYY-MM-DD"),
        startTime: equipRentedTimes?.startTime,
        endDate: equipRentedDates[1]?.format("YYYY-MM-DD"),
        endTime: equipRentedTimes?.endTime,
      });

      const startElements = document.querySelectorAll(
        ".rmdp-day.rmdp-range.start"
      );
      const endElements = document.querySelectorAll(".rmdp-day.rmdp-range.end");
      if (AllReservedDates.length) {
        if (
          AllReservedDates[0]?.hasOwnProperty(startDate) &&
          AllReservedDates[0]?.hasOwnProperty(endDate)
        ) {
          handleSelectionDivider(startDate, startElements);
          handleSelectionDivider(endDate, endElements);
        } else {
          handleSelectionDivider(startDate, startElements);
          handleSelectionDivider(endDate, endElements);
        }
      }
      // Loop through end elements to add or remove the class
      endElements.forEach((el) => {
        if (endDate && !AllReservedDates[0]?.hasOwnProperty(endDate)) {
          if (!el.classList.contains("partial-selecting-end")) {
            el.classList.add("partial-selecting-end");
          }
        } else {
          el.classList.remove(
            "partial-selecting-end",
            "booked-start-pending-end",
            "booked-end-pending-start"
          );
          handleSelectionColorization(el, endDate);
        }
        // const elementAttribute = el
        //   .querySelector("span[data-status]")
        //   ?.getAttribute("data-status");
        // console.log("elementAttribute end", elementAttribute);
        // if (elementAttribute?.includes("partial-booked-start")) {
        //   if (!el.classList.contains("booked-start-pending-end")) {
        //     el.classList.add("booked-start-pending-end");
        //   }
        // } else if (elementAttribute?.includes("pending")) {
        //   // continue
        // } else if (endDate) {
        // if (!el.classList.contains("partial-selecting-end")) {
        //   el.classList.add("partial-selecting-end");
        // }
        // } else {
        // el.classList.remove(
        //   "partial-selecting-end",
        //   "booked-start-pending-end",
        //   "booked-end-pending-start"
        // );
        // }
      });

      // Loop through start elements to add or remove the class
      startElements.forEach((el) => {
        if (startDate && !AllReservedDates[0]?.hasOwnProperty(startDate)) {
          if (!el.classList.contains("partial-selecting-start")) {
            el.classList.add("partial-selecting-start");
          }
        } else {
          el.classList.remove(
            "partial-selecting-start",
            "booked-start-pending-end",
            "booked-end-pending-start"
          );
          handleSelectionColorization(el, startDate);
        }
        // const elementAttribute = el
        //   .querySelector("span[data-status]")
        //   ?.getAttribute("data-status");
        // console.log("elementAttribute start", elementAttribute);
        // if (elementAttribute?.includes("partial-booked-end")) {
        //   if (!el.classList.contains("booked-end-pending-start")) {
        //     el.classList.add("booked-end-pending-start");
        //   }
        // } else if (elementAttribute?.includes("pending")) {
        //   // continue
        // } else if (startDate) {
        // if (!el.classList.contains("partial-selecting-start")) {
        //   el.classList.add("partial-selecting-start");
        // }
        // } else {
        //   el.classList.remove(
        //     "partial-selecting-start",
        //     "booked-start-pending-end",
        //     "booked-end-pending-start"
        //   );
        // }
      });
    } else {
      removeSelectionAttributes();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      ModifyPartialSelection({ equipRentedDates, equipRentedTimes });
    }, 100);
    if (
      !equipRentedDates[0] &&
      !equipRentedTimes?.startTime &&
      !equipRentedDates[1] &&
      !equipRentedTimes?.endTime
    ) {
      addDividerInDate();
    }
  }, [equipRentedDates, equipRentedTimes]);

  useEffect(() => {
    if (modalOpen) {
      addDividerInDate();
    }
  }, [modalOpen]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.5rem",
        width: "100%",
        maxHeight: { xs: "500px", sm: "700px", md: "750px", overflowY: "auto" },
      }}
    >
      <Box
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          maxWidth: "375px",
          // backgroundColor: "#faa61a0d",
          padding: "10px",
        }}
      >
        {label && (
          <Box
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.3rem",
                md: "1.5rem",
                lg: "1.6rem",
                xl: "1.7rem",
              },
              textAlign: "center",
              // marginBottom: 1,
              fontFamily: "GothamBold !important",
              color: "#faa61a",
              fontWeight: "600",
              mb: 1,
              width: "100%",
            }}
          >
            {label}
          </Box>
        )}

        {/* New Added Design */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #3a3a3a",
          }}
        >
          <Calendar
            ref={calendarRef}
            value={equipRentedDates}
            mapDays={({ date }) => {
              const dateConfig = getDateStyle(date);
              return {
                style: dateConfig.style,
                disabled: dateConfig.disabled,
                "data-status": dateConfig.className,
                "data-cross-line": dateConfig?.highLight ? "line" : "",
              };
            }}
            onChange={handleRangeChange}
            onMonthChange={handleMonthChange}
            range
            numberOfMonths={1}
            format="DD/MM/YYYY"
            minDate={minDateTime?.minDate}
            disableMonthPicker={true}
            disableYearPicker={true}
          />
        </Box>

        <CalendarLegend />
      </Box>
    </Box>
  );
};

export default CustomCalendar;
