import { Box, Card, CardMedia, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import './styles.scss';
import ProfileWarningIcon from "../../assets/images/icon_warning_shadow.png";
// import frontImage from "assets/images/dummy-profile-image.png"
// import backImage from "assets/images/dummy-profile-image.png"

const FlipImageCard = ({frontImage,backImage,modal=false,showWarning=false,flip=false}) => {
  const [flipped, setFlipped] = useState(false);

  // useEffect(()=>{
  //   if(flip) {
  //     setFlipped(true);
  //   }
  // },[flip])

  return (
    <Box
      sx={{ width: "100%", position: "relative", mt: 1, boxSizing:'border-box' }}
      className={`flip-card ${flipped ? "flipped" : ""}`}
    >
      <Box className="flip-card-inner">
        {/* Front of the card */}
        <Card className="flip-card-front">
          <CardMedia
            component="img"
            height="100%"
            image={frontImage}
            alt="Front Image"
          />
        </Card>
        {/* Back of the card */}
        <Card className="flip-card-back">
          <CardMedia
            component="img"
            height="100%"
            image={backImage}
            alt="Back Image"
          />
        </Card>
      </Box>
      {(showWarning && !modal) && (
        <Box
          component={"img"}
          sx={{
            position: "absolute",
            top: "-40px",
            right: "-45px",
            width: "80px",
            height: "80px",
          }}
          // className="dlwarning"
          src={ProfileWarningIcon}
          alt=""
        />
      )}

      {/* Flip Button */}
      {/* <Button
        variant="contained"
        color="primary"
        onClick={() => setFlipped(!flipped)}
        style={{
          position: "absolute",
          bottom: "10px",
          right: "20px",
          marginTop: "20px",
          background: "#faa61a",
        }}
      >
        <CameraswitchIcon sx={{ color: "#fff !important" }} />
      </Button> */}
    </Box>
  );
};

export default FlipImageCard;
