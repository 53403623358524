import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React from "react";

const ConfirmationDialog = ({
  dialogIsOpen,
  message,
  onConfirm,
  handleDialogClose,
  btnName,
}) => {
  return (
    <Box>
      <Dialog open={dialogIsOpen} aria-labelledby="draggable-dialog-title">
        <Box
          sx={{
            background: "#2d2d2d",
            border: "5px solid #faa61a !important",
            borderRadius: "5px",
            padding: "1rem",
          }}
        >
          <DialogContent sx={{ padding: " 0" }}>
            <DialogContentText
              sx={{
                color: "#fff",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "16px",
                  lg: "20px",
                  xl: "20px",
                },
                textAlign: "center",
                width: "400px",
              }}
            >
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Box
              className="btn-load-more"
              sx={{
                color: "#fff !important",
                padding: "6px 40px 6px 40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleDialogClose}
            >
              {btnName?.first}
            </Box>
            <Box
              className="btn-load-more"
              sx={{
                color: "#fff !important",
                padding: "6px 40px 6px 40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={onConfirm}
            >
              {btnName?.second}
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ConfirmationDialog;
