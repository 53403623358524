export const calendarClassName = {
  "booked-booked": "full-booked",
  "requested-requested": "full-pending",
  "booked-requested": "booked-end-pending-start",
  "requested-booked": "booked-start-pending-end",
  "booked-default": "partial-booked-end",
  "default-booked": "partial-booked-start",
  "requested-default": "partial-pending-end",
  "default-requested": "partial-pending-start",
};
