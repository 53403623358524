import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../../service/api-service";
import {
  Box,
  CircularProgress,
  Link,
  Typography,
  Backdrop,
  Button,
  Modal,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  styled,
  InputLabel,
  Switch,
  TextField,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  setalertMessagetype,
  setalertMessage,
  setSessionExpire,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import gemArrow from "assets/images/gem-arrow.png";
import satellite from "assets/images/GPS/gps-icon.png";
import requestGPS from "assets/images/GPS/requestGPS.png";
import noGpsAssigned from "assets/images/GPS/nogpsassigned.png";
import mapNotAvailable from "assets/images/GPS/noGpsAssigned.jpg";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import CurrentFormat from "../../common/CurrenyFormat";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  DirectionsService,
} from "@react-google-maps/api";
import fireAnalyticsEvent from "service/firebase";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
// -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
// filter: grayscale(100%);
import { io } from "socket.io-client";
import { Calendar as DatePicker } from "react-multi-date-picker";
const NewCssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border: "2px solid #faa61a",
  backgroundColor: "#fff",
  ".Mui-focusVisible &": {},
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#fff",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd'  clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%232d2d2d'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

let SOCKET_URL = process.env.REACT_APP_STAGING_SOCKET_URL;
if (process.env.REACT_APP_PLATFORM === "Live") {
  SOCKET_URL = process.env.REACT_APP_LIVE_SOCKET_URL;
} else if (process.env.REACT_APP_PLATFORM === "PreLive") {
  SOCKET_URL = process.env.REACT_APP_PRELIVE_SOCKET_URL;
}
let socket;

const Gps = ({handleListEquipment}) => {
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [loading, setloading] = React.useState(false);
  const [Actionequip, setActionequip] = React.useState([]);
  const [AllEquips, setAllEquips] = React.useState([]);
  const paginationPage = 5;
  const [NewPageLen, setNewPageLen] = React.useState(paginationPage);
  const [CurrentActiveEquip, setCurrentActiveEquip] = React.useState(null);
  const [CurrentActiveEquipObj, setCurrentActiveEquipObj] =
    React.useState(null);
  const [UpdateCallback, setUpdateCallback] = React.useState(null);
  const today = moment().format("YYYY-MM-DD");

  const [GpsRequestPopup, setGpsRequestPopup] = React.useState(false);
  const [GpsRequestPopupResult, setGpsRequestPopupResult] =
    React.useState(false);
  const [RequestResponse, setRequestResponse] = React.useState("");
  const [TotalDevice, setTotalDevice] = React.useState(0);
  const [TotalUnassigned, setTotalUnassigned] = React.useState(0);

  const [GpsAssignPopup, setGpsAssignPopup] = React.useState(false);
  const [GpsDevices, setGpsDevices] = React.useState(null);
  const [DeviceSerialNumber, setDeviceSerialNumber] = React.useState(null);
  const [DeviceAssignEquip, setDeviceAssignEquip] = React.useState([]);
  const [GpsDeviceUnassignPopup, setGpsDeviceUnassignPopup] =
    React.useState(false);
  const [UnassignEquipId, setUnassignEquipId] = React.useState(null);

  const [GpsDeviceLocationArr, setGpsDeviceLocationArr] = React.useState([]);
  const [GpsDeviceLocationDefault, setGpsDeviceLocationDefault] =
    React.useState(null);
  const [ViewallLocation, setViewallLocation] = React.useState(false);
  const [defaulCenterLocation, setdefaulCenterLocation] = React.useState(null);

  const [AssignSerialNumbers, setAssignSerialNumbers] = React.useState([]);
  const [ReAssignPopup, setReAssignPopup] = React.useState(false);

  const mapview = useRef(null);
  const [map, setMap] = React.useState(null);
  // Google map section start
  // const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
  const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: APIKEY,
  });
  const containerStyle = {
    width: "100%",
    height: "550px",
  };
  // Google map section end
  const [gpsDateRangeModel, setGpsDateRangeModel] = useState(false);
  const [gpsDateRange, setGpsDateRange] = useState([]);
  const [gpsDeviceFilter, setGpsDeviceFilter] = useState(false);
  const [minDateValue, setMinDateValue] = useState(null);
  const [gpsDeviceDirection, setGpsDeviceDirection] = useState([]);
  const [directions, setDirections] = useState([]);
  const [realTimeEnabled, setRealTimeEnabled] = useState(false);
  /**
   * Function: ListHome
   * Description:
   * Fetches equipment data from the server, processes and filters it based on various criteria,
   * updates the state, and handles success or error scenarios.
   * If successful, sets the current page, updates the total results, and sets the default active equipment.
   * If an error occurs, dispatches relevant alert messages and handles session expiration.
   *
   * @param {string} user_id - The user ID for fetching equipment data.
   * @param {number} lat - The latitude for location filtering.
   * @param {number} lng - The longitude for location filtering.
   * @param {string} equipId - The optional equipment ID.
   */
  const ListHome = async (user_id, lat, lng, equipId = "default") => {
    try {
      // Set loading state and reset current active equipment
      setloading(true);
      setCurrentActiveEquip(null);

      // Call EquipmentAPIs.MyListedEquip to get equipment data
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const OfficeTrailerData = await EquipmentAPIs.MyListedEquip(
        {
          user_id: user_id.toString(),
          page: "0",
        },
        headers
      );

      // Check if the API response is successful
      if (OfficeTrailerData["type"] == "RXSUCCESS") {
        let equip = OfficeTrailerData["data"];

        // Remove duplicate objects based on 'id' property
        var uniqObjs = [];
        var dupeObjs = [];
        equip.forEach((obj) =>
          [uniqObjs, dupeObjs][
            +(
              equip.map((obj) => obj.id).filter((id) => id === obj.id).length >
              1
            )
          ].push(obj)
        );

        // Filter out completed and returned orders
        dupeObjs = dupeObjs.filter((item, index) => {
          return item.order_status != "completed";
        });
        dupeObjs = dupeObjs.filter((item, index) => {
          return item.order_status != "returned";
        });

        // Sort by order start date
        dupeObjs = dupeObjs.sort((item, item2) => {
          return (
            moment(item.order_start_date, "YYYY-MM-DD").valueOf() -
            moment(item2.order_start_date, "YYYY-MM-DD").valueOf()
          );
        });

        // Remove duplicate objects again based on 'id'
        dupeObjs = dupeObjs.filter((item, index, a) => {
          return a.findIndex((v2) => v2.id === item.id) === index;
        });

        // Initialize arrays for processing data
        var _LastArray = [];
        var _newARRAY = [];
        _LastArray = dupeObjs;
        _newARRAY = dupeObjs;
        var _newDATA = [];

        // Process equipment data to create a new array without duplicates
        equip.map((item, index) => {
          if (
            _LastArray.filter((item2) => {
              return item2.id == item.id;
            }).length == 0
          ) {
            _newDATA.push(item);
          }
        });

        // Filter the new data array for unique items based on 'id'
        if (_newDATA.length != 0) {
          _newDATA = _newDATA.filter((item, index, a) => {
            return a.findIndex((v2) => v2.id === item.id) === index;
          });
        }

        // Combine the new data array with the original array
        _LastArray = [..._newDATA, ..._newARRAY];

        // Initialize arrays for categorizing equipment
        var actionNeededDataEquip = [];
        var otherDataEquip = [];
        var compeltedDataEquip = [];
        var notDelivered = [];

        var deviceAssign = [];

        // Iterate over the processed equipment data
        _LastArray.map((item, index) => {
          if (item.device_assign_status == 1) {
            //deviceAssign.push(item.connected_imei.trim()) //Old Line
            deviceAssign.push(item.connected_imei);
          }

          // Categorize equipment based on order status and date
          item.order_start_date == today &&
          item.order_status == "payment_complete"
            ? actionNeededDataEquip.push(item)
            : (item.order_end_date == today &&
                (item.order_status == "payment_complete" ||
                  item.order_status == "pickup" ||
                  item.order_status == "returned")) ||
              item.order_status == "returned"
            ? actionNeededDataEquip.push(item)
            : item.order_status == "completed"
            ? compeltedDataEquip.push(item)
            : item.order_status == "payment_complete" ||
              item.order_status == "pickup"
            ? otherDataEquip.push(item)
            : notDelivered.push(item);
        });

        // Set device assignments and related data
        setDeviceAssignEquip(deviceAssign);

        // Create a final array combining different equipment categories
        let _last = [
          ...actionNeededDataEquip,
          ...otherDataEquip,
          ...compeltedDataEquip,
          ...notDelivered,
        ];

        // Duplicate the final array for further processing
        // let finalArray = [..._last]
        let NewArray = [..._last];

        // Initialize arrays for assigned and unassigned equipment
        let assignArr = [];
        let unassignArr = [];
        // let connectedImei = [];

        // Process the duplicated array to categorize assigned and unassigned equipment
        NewArray.map((Arritem, index) => {
          if (Arritem.device_assign_status == 1) {
            assignArr.push(Arritem);
            // connectedImei.push(Arritem.connected_imei);
          } else {
            unassignArr.push(Arritem);
          }
        });

        // Set assigned serial numbers
        // setAssignSerialNumbers(connectedImei);

        // Create the final array by combining assigned and unassigned equipment
        let finalArray = [...assignArr, ...unassignArr];

        // Set state variables with the final array
        setActionequip(finalArray);

        // Update all equips based on the update callback type
        if (UpdateCallback == "success") {
          setAllEquips(finalArray.slice(0, NewPageLen));
        } else {
          setAllEquips(finalArray.slice(0, paginationPage));
        }
        GpsDevice(user_id);
        //setloading(false);
        // console.log('finalArray', finalArray);
        // console.log('equipId',equipId);

        // Hide GPS-related popups
        setGpsAssignPopup(false);
        setGpsDeviceUnassignPopup(false);

        if (equipId !== "default") {
          //  console.log("equipId :", equipId);
          setViewallLocation(false);
          setCurrentActiveEquip(equipId);

          // Update the active equipment status and fetch its GPS device location
          let activeEquip = AllEquips.filter(
            (equipment, indx) => equipment.id === CurrentActiveEquipObj["id"]
          );
          activeEquip[0].device_assign_status = "1";
          setCurrentActiveEquipObj(activeEquip[0]);
          GetGpsDeviceLocation(equipId, "single");
        }

        //console.log('assignArr', assignArr.length, equipId)
        // Handle default equipment activation when equipId is 'default'
        if (equipId == "default" && finalArray.length > 0 && finalArray[0]) {
          if (assignArr.length > 0) {
            setViewallLocation(false);
            setCurrentActiveEquip(finalArray[0].id);
            setCurrentActiveEquipObj(finalArray[0]);
            GetGpsDeviceLocation(finalArray[0].id, "single");
          } else {
            // by GD 11Dec added else condition to active a equipment when there is no equipment with GPS assigned
            // Activate equipment when there is no equipment with GPS assigned
            setViewallLocation(false);
            setCurrentActiveEquip(finalArray[0].id);
            setCurrentActiveEquipObj(finalArray[0]);
          }
        }
      } else if (
        OfficeTrailerData["status"] == false &&
        OfficeTrailerData["slug"] == "Session Expired"
      ) {
        // Dispatch session expiration action
        dispatch(setSessionExpire(true));
        setloading(false);
        return false;
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(OfficeTrailerData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Log and handle errors
      console.log(err);
      setloading(false);
    }
  };

  /**
   * Function: HandleRentalYardPagination
   * Description:
   * Handles the pagination logic for the Gps tab.
   * Calculates the new page length based on the total equipment and paginationPage value.
   * Updates the state with the new page length and sets the equipment array accordingly.
   * Sets loading state to false after completion.
   */ // Function to handle rental yard pagination
  const HandleRentalYardPagination = () => {
    setloading(true);

    // Calculate total and current page length
    let total = Number(Actionequip.length);
    let pagerlen = Number(AllEquips.length);
    let new_len = Number(pagerlen) + Number(paginationPage);
    setNewPageLen(new_len);

    // Update AllEquips state based on new length
    if (new_len > total) {
      setAllEquips(Actionequip.slice(0, total));
    } else {
      setAllEquips(Actionequip.slice(0, new_len));
    }
    setloading(false);
  };

  /**
   * Function: GpsRequestCreate
   * Description:
   * Creates a GPS request by sending a request to the server,
   * updates the state, and handles success or error scenarios.
   * If successful, hides the GPS request popup, sets the result popup,
   * and updates the response message.
   * If an error occurs, dispatches relevant alert messages.
   */
  //Make Gps Request
  const GpsRequestCreate = async () => {
    try {
      setloading(true);

      // Call EquipmentAPIs.GpsRequest to create a GPS request
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const RequestData = await EquipmentAPIs.GpsRequest(
        {
          user_id: AuthUserID.toString(),
        },
        headers
      );

      // Check if the API response is successful
      if (RequestData["type"] == "RXSUCCESS") {
        // Hide GPS request popup, show result popup, and update response
        setGpsRequestPopup(false);
        setGpsRequestPopupResult(true);
        setRequestResponse(RequestData["message"]);
        // dispatch(setalertMessage(RequestData['message']));
        // dispatch(setalertMessagetype('success'));
        setloading(false);
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(RequestData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Log and handle errors
      console.log(err);
      setloading(false);
    }
  };

  /**
   * Function: GpsDevice
   * Description:
   * Fetches GPS device data from the server, updates the state,
   * and handles success or error scenarios.
   * If successful, processes the device data and updates relevant state variables.
   * If an error occurs, dispatches relevant alert messages.
   * @param {string} user_id - The user ID for fetching GPS device data.
   * @param {string} clickfrom - The source of the click event (e.g., "Assign").
   */ // Get GPS Device
  const GpsDevice = async (user_id, clickfrom = null) => {
    try {
      setloading(true);

      // Call EquipmentAPIs.GetGpsDevice to get GPS device data
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const GpsDeviceData = await EquipmentAPIs.GetGpsDevice(
        {
          user_id: user_id.toString(),
        },
        headers
      );

      // Check if the API response is successful
      if (GpsDeviceData["type"] == "RXSUCCESS") {
        let AssigndeviceList = DeviceAssignEquip;
        let deviceArr = GpsDeviceData["data"];
        let deviceArr_new = [];

        // Process device data to filter unassigned devices
        const connectEmei = [];
        if (deviceArr.length > 0) {
          deviceArr.map((item, index) => {
            if (!AssigndeviceList.includes(item.imei.trim())) {
              deviceArr_new.push(item);
              if (item?.connected_status !== "1") {
                connectEmei.push(item.imei.trim());
              }
            }
          });
        }
        setAssignSerialNumbers(connectEmei);
        // Set the state with unassigned devices and update total device counts
        setGpsDevices(deviceArr_new);

        // Show GPS request popup if there are no assigned devices
        if (GpsDeviceData["total_assign"] == 0) {
          setGpsRequestPopup(true);
        }

        // Update total device counts and set loading state to false
        setTotalDevice(GpsDeviceData["total_assign"]);
        setTotalUnassigned(GpsDeviceData["total_unassign"]);
        setloading(false);

        // Handle special actions if the click event is from the "Assign" button
        if (clickfrom == "Assign") {
          if (deviceArr_new.length > 0) {
            // Show GPS assign popup and reset device serial number
            setGpsAssignPopup(true);
            setDeviceSerialNumber(null);
          } else {
            // Show GPS request popup if there are no unassigned devices
            setGpsRequestPopup(true);
          }
        }
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(GpsDeviceData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Log and handle errors
      console.log(err);
      setloading(false);
    }
  };

  /**
   * Function: GpsDeviceAssign
   * Description:
   * Assigns a GPS device to equipment by sending a request to the server,
   * updates the state, and handles success or error scenarios.
   * If successful, dispatches a success alert message, hides the GPS assign popup,
   * and triggers a refresh of the equipment list.
   * If an error occurs or device serial number is not selected,
   * dispatches relevant error alert messages.
   */
  // Assign GPS device to Equip
  const GpsDeviceAssign = async () => {
    try {
      setloading(true);

      // Check if the device serial number is not selected
      if (DeviceSerialNumber == "" || DeviceSerialNumber == null) {
        dispatch(setalertMessage("Please select device first"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }

      // Call EquipmentAPIs.EquipConnectGpsDevice to assign a GPS device
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const GpsDeviceConnectData = await EquipmentAPIs.EquipConnectGpsDevice(
        {
          device_id: DeviceSerialNumber.toString(),
          equip_id: CurrentActiveEquipObj["id"].toString(),
          user_id: AuthUserID.toString(),
        },
        headers
      );

      // Check if the API response is successful
      if (GpsDeviceConnectData["type"] == "RXSUCCESS") {
        // Dispatch success alert messages
        dispatch(setalertMessage(GpsDeviceConnectData["message"]));
        dispatch(setalertMessagetype("success"));

        // Set loading state to false, hide popups, and refresh equipment list
        setloading(false);
        setGpsAssignPopup(false);
        setReAssignPopup(false);
        ListHome(
          AuthProfileData["user_id"],
          AuthProfileData["lat"],
          AuthProfileData["lng"],
          CurrentActiveEquipObj["id"]
        );
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(GpsDeviceConnectData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Log and handle errors
      console.log(err);
      setloading(false);
    }
  };

  /**
   * Function: GpsDeviceUnassign
   * Description:
   * Unassigns a GPS device from equipment by sending a request to the server,
   * updates the state, and handles success or error scenarios.
   * If successful, dispatches a success alert message, hides the GPS unassign popup,
   * and triggers a refresh of the equipment list.
   * If an error occurs, dispatches relevant error alert messages.
   */ // Unassign GPS device from Equip
  const GpsDeviceUnassign = async () => {
    try {
      setloading(true);

      // Call EquipmentAPIs.EquipDisconnectGpsDevice to unassign a GPS device
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const GpsDeviceConnectData = await EquipmentAPIs.EquipDisconnectGpsDevice(
        {
          equip_id: UnassignEquipId.toString(),
          user_id: AuthUserID.toString(),
        },
        headers
      );

      // Check if the API response is successful
      if (GpsDeviceConnectData["type"] == "RXSUCCESS") {
        // Dispatch success alert messages
        dispatch(setalertMessage(GpsDeviceConnectData["message"]));
        dispatch(setalertMessagetype("success"));

        // Set loading state to false, hide popups, and refresh equipment list
        setloading(false);
        // setGpsAssignPopup(false)
        setGpsDeviceUnassignPopup(false);
        ListHome(
          AuthProfileData["user_id"],
          AuthProfileData["lat"],
          AuthProfileData["lng"]
        );
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(GpsDeviceConnectData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Log and handle errors
      console.log(err);
      setloading(false);
    }
  };

  /**
   * Function: GetGpsDeviceLocation
   * Description:
   * Gets the location of a GPS device or multiple GPS devices from the server,
   * updates the state, and handles success or error scenarios.
   * If successful, processes the location data, sets the default center location,
   * and adjusts the map bounds if there are multiple locations.
   * If an error occurs, dispatches relevant error alert messages.
   *
   * @param {string} equipId - The equipment ID for fetching GPS device location.
   * @param {string} type - The type of response ('single' or 'multiple').
   */ // Get connected device location
  const GetGpsDeviceLocation = async (equipId, type = "single") => {
    try {
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      let GpsDeviceData = "";

      // Call the appropriate API based on the type of response needed
      if (type == "single") {
        GpsDeviceData = await EquipmentAPIs.GetSingleConnectedGpsDevice(
          {
            equip_id: equipId.toString(),
            user_id: AuthUserID.toString(),
          },
          headers
        );
      } else {
        GpsDeviceData = await EquipmentAPIs.GetMultipleConnectedGpsDevice(
          {
            user_id: AuthUserID.toString(),
          },
          headers
        );
      }

      // console.log("GpsDeviceData: " , GpsDeviceData);

      // Check if the API response is successful
      if (GpsDeviceData["type"] == "RXSUCCESS") {
        // Process the location data and filter out entries without battery level
        let locationArr = GpsDeviceData["data"];

        let new_array = [];
        locationArr.map((item) => {
          // if (item.battery_level != "") {
          // new_array.push(item);
          // }

          // by GD 17 Jan 2024 due to gps display error
          if (
            parseFloat(item.latitude) != 0 &&
            parseFloat(item.longitude) != 0
          ) {
            new_array.push(item);
          }
          // end by GD
        });

        // Set the state with filtered location data
        if (new_array.length > 1) {
          const indexToMove = new_array.findIndex(
            (item) => item.equip_id === CurrentActiveEquipObj?.id
          );
          if (indexToMove !== -1 && indexToMove !== 0) {
            const movedObject = new_array.splice(indexToMove, 1)[0];
            new_array.unshift(movedObject);
          }
        }
        if (!new_array.length) {
          setRealTimeEnabled(true);
        }
        setGpsDeviceLocationArr(new_array);

        // Prepare an array of coordinates for map adjustments
        let array = [];
        new_array.map((item) => {
          // if (item.battery_level != "") {
          array.push({
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          });
          // }
        });

        // Adjust the map bounds if there are multiple locations
        if (array.length > 1) {
          setTimeout(() => {
            const bounds = new window.google.maps.LatLngBounds();
            array.forEach((marker) => bounds.extend(marker));
            map.fitBounds(bounds);
          }, 500);
        }

        // Set the default center location based on the first entry in the filtered data
        if (new_array.length > 0) {
          let location = {
            lat: parseFloat(new_array[0]["latitude"]),
            lng: parseFloat(new_array[0]["longitude"]),
          };
          setdefaulCenterLocation(location);
        }

        // Set loading state to false
        setloading(false);
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(GpsDeviceData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Log and handle errors
      console.log(err);
      setloading(false);
    }
  };

  /** // Function to handle the "View All" checkbox
   * Function: HandleViewAll
   * Description:
   * Handles the state change of the "View All" checkbox, triggering
   * the appropriate action based on whether the checkbox is checked or unchecked.
   * If checked, calls GetGpsDeviceLocation with 'all' type to show all GPS device locations.
   * If unchecked, calls GetGpsDeviceLocation with 'single' type to show the location of a single GPS device.
   */
  const HandleViewAll = (event) => {
    let val = event.target.checked;
    setViewallLocation(val);

    // Call GetGpsDeviceLocation with the appropriate type based on checkbox state
    if (val) {
      GetGpsDeviceLocation(CurrentActiveEquip, "all");
      setGpsDeviceFilter(false);
      setDirections([]);
      setRealTimeEnabled(false);
      disconnectSocket();
    } else {
      GetGpsDeviceLocation(CurrentActiveEquip, "single");
    }
  };

  /** // Function to handle assigning a GPS device
   * Function: HandleAssignGP
   * Description:
   * Handles the assignment of a GPS device to equipment.
   * Checks if the selected GPS device is already assigned to another equipment.
   * If assigned, displays a reassign popup; otherwise, proceeds with the assignment.
   */
  const HandleAssignGP = () => {
    // Get the selected serial number
    let selectdSerialNumber = DeviceSerialNumber;

    // Flag to check if the selected GPS device is already assigned to another equipment
    let ob = false;

    // Check if GpsDevices and selectdSerialNumber are available
    if (GpsDevices && selectdSerialNumber) {
      // Iterate through GpsDevices to find a match for the selected serial number
      GpsDevices.map((item, index) => {
        if (selectdSerialNumber == item.device_tableid) {
          // Check if the selected serial number is included in AssignSerialNumbers
          if (
            AssignSerialNumbers &&
            !AssignSerialNumbers.includes(item.imei.trim())
          ) {
            ob = true;
            return;
          }
        }
      });
    }

    if (ob) {
      // Close the assign popup and show the reassign popup
      setGpsAssignPopup(false);
      setReAssignPopup(true);
    } else {
      // Proceed with GPS device assignment
      GpsDeviceAssign();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (AuthProfileData && AuthProfileData != null) {
      // by GD 29 Jan 2024 for firebase/google analylics
      // fireAnalyticsEvent("Office_Trailer_GPS");
      fireAnalyticsEvent("GPS_Office_Trailer_to_GPS");

      //Load all Equipments
      ListHome(
        AuthProfileData["user_id"],
        AuthProfileData["lat"],
        AuthProfileData["lng"]
      );
    }
  }, [AuthProfileData]);

  async function evaluateGpsDateRange(range) {
    const start = range[0]?.format?.();
    const end = range[1]?.format?.();
    // if (start) {
    //   const selectedMonth = new Date(start);
    //   selectedMonth.setDate(1);
    //   setMinDateValue(selectedMonth);
    // } else {
    //   setMinDateValue(null);
    // }
    if (start && end) {
      setGpsDateRange([start, end]);
    } else {
      setGpsDateRange([]);
      setGpsDeviceFilter(false);
    }
  }

  function structureWaypoints(reversedDirection) {
    const originPoints = reversedDirection[0][0];
    const lastArray = reversedDirection[reversedDirection.length - 1];
    const destinationPoints = lastArray[lastArray.length - 1];
    const directionsService = new google.maps.DirectionsService();
    const origin = {
      lat: parseFloat(originPoints["latitude"]),
      lng: parseFloat(originPoints["longitude"]),
    };
    const destination = {
      lat: parseFloat(destinationPoints["latitude"]),
      lng: parseFloat(destinationPoints["longitude"]),
    };
    setGpsDeviceDirection([{ origin, destination }]);
    const routes = [];
    let requestsCompleted = 0;

    reversedDirection.forEach((points) => {
      const waypoints = points.map((point) => ({
        location: {
          lat: parseFloat(point["latitude"]),
          lng: parseFloat(point["longitude"]),
        },
        stopover: true,
      }));

      directionsService.route(
        {
          origin,
          destination,
          waypoints,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            routes.push(response);
          } else {
            console.error("Directions request failed due to " + status);
          }
          requestsCompleted++;

          if (requestsCompleted === reversedDirection.length) {
            setDirections(routes);
          }
        }
      );
    });
  }

  async function getGpsDeviceLocationFilter() {
    try {
      const deviceId = GpsDeviceLocationArr[0]
        ? GpsDeviceLocationArr[0]["device_id"]
        : null;
      if (!deviceId) {
        throw new Error("No device detected!");
      }
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = {
        start_date: gpsDateRange[0]?.replaceAll("/", "-"),
        end_date: gpsDateRange[1]?.replaceAll("/", "-"),
        device_id: deviceId?.toString(),
      };
      setloading(true);
      const response = await EquipmentAPIs.getGpsDeviceFilterLocation(
        params,
        headers
      );
      console.log("locattion=========>", response);
      if (response.status == 200) {
        const reversedDirection = response.data.reverse();
        setGpsDeviceFilter(true);
        if (reversedDirection.length === 0) {
          setDirections([]);
          throw new Error("No activity detected for this device!");
        }
        structureWaypoints(reversedDirection);
      }
      setloading(false);
    } catch (err) {
      setloading(false);
      dispatch(setalertMessage(err.message));
      dispatch(setalertMessagetype("error"));
      console.log("Error while rendering directions", err);
    }
  }

  async function getRealtimeLocation(event) {
    const isRealTime = event.target.checked;
    if (isRealTime && AuthProfileData) {
      setRealTimeEnabled(isRealTime);
      socket = io(SOCKET_URL, {
        query: {
          from: "web",
          user_id: AuthProfileData["user_id"],
        },
      });
      socket.on("connect", () => {
        console.log("connected");
      });
      setGpsDeviceFilter(false);
      setDirections([]);
      setGpsDateRange([]);
      map.setZoom(18);
      map.panTo({
        lat: parseFloat(GpsDeviceLocationArr[0]["latitude"]),
        lng: parseFloat(GpsDeviceLocationArr[0]["longitude"]),
      });
      socket.on("server_message", (data) => {
        const id = GpsDeviceLocationArr[0]["device_id"];
        if (id === +data["device_id"]) {
          const newGpsLocation = { ...GpsDeviceLocationArr[0] };
          newGpsLocation["latitude"] = data["latitude"];
          newGpsLocation["longitude"] = data["longitude"];
          map.panTo({
            lat: parseFloat(data["latitude"]),
            lng: parseFloat(data["longitude"]),
          });
          setGpsDeviceLocationArr([newGpsLocation]);
        }
      });
    } else {
      disconnectSocket();
      setRealTimeEnabled(false);
    }
  }

  function disconnectSocket() {
    if (socket) {
      socket.disconnect();
      socket = undefined;
    }
  }

  useEffect(() => {
    //added by pp on 8 apr 2024 due to trigger a change when user leave chat thread or close the browser tab
    window.addEventListener("beforeunload", (e) => {
      disconnectSocket();
    });
    return () => {
      disconnectSocket();
    };
  }, []);

  return (
    <>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      <Box className="rentyrd-grid">
        <Box className="rentyrd-grid-item rentyrd-left">
          <Box className="gps-heading">
            <Box className="gps-heading-left">
              <Typography className="gps-h">GPS</Typography>

              <Box className="gps-grid-left-heading">
                <Typography> Total Devices: {TotalDevice}</Typography>
                <Typography> Total Unassigned: {TotalUnassigned}</Typography>
              </Box>
            </Box>

            <Box>
              <Button
                className="request-more-gps-btn"
                type="button"
                variant="contained"
                sx={{textTransform:"unset !important"}}
                // onClickCapture={() => {
                //   setGpsRequestPopup(true)
                // }}
                onClickCapture={GpsRequestCreate}
              >
                Order GPS
              </Button>
            </Box>
          </Box>

          {Actionequip.length > 0 ? (
            <>
              {AllEquips.map((equipment, indx) => (
                <>
                  <Box
                    className={
                      CurrentActiveEquip == equipment.id
                        ? equipment.device_assign_status == 1
                          ? "rentyrd-card rental-box-active"
                          : "rentyrd-card rental-box-active rentyrd-card-grayscale"
                        : equipment.device_assign_status == 1
                        ? "rentyrd-card"
                        : "rentyrd-card rentyrd-card-grayscale"
                    }
                    onClick={(event) => {
                      setViewallLocation(false);
                      setCurrentActiveEquip(equipment.id);
                      setCurrentActiveEquipObj(equipment);
                      setGpsDeviceFilter(false);
                      setDirections([]);
                      setGpsDateRange([]);
                      getRealtimeLocation(event);
                      // setMinDateValue(null);
                      if (equipment.device_assign_status == 1) {
                        // by GD 29 Jan 2024 for firebase/google analylics
                        // fireAnalyticsEvent("Office_Trailer_GPS_Active_GPS");
                        fireAnalyticsEvent(
                          "GPS_Office_Trailer_to_GPS_to_Active_GPS"
                        );
                        GetGpsDeviceLocation(equipment.id, "single");
                      }
                    }}
                    // style={{ filter: equipment.device_assign_status == 1 ? "" : "grayscale(100%)" }}
                  >
                    <Box className="rentyrd-card-grid">
                      <Box
                        className="rentyrd-card-item-left"
                        style={{
                          filter:
                            equipment.device_assign_status == 1
                              ? ""
                              : "grayscale(100%)",
                        }}
                      >
                        <Box className="rentyrd-card-img">
                          <img
                            className="eqppmnt-img"
                            src={equipment["thumbnail"]}
                            alt=""
                          />
                          {(equipment.order_status != null &&
                            equipment.order_status == "returned") ||
                          equipment.order_status == "pickup" ||
                          equipment.order_status == "payment_complete" ? (
                            <Typography>ACTIVE</Typography>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>
                      <Box className="rentyrd-card-item-right">
                        <Box className="rentyrd-right">
                          <Box className="gps-card-top-content">
                            <Typography
                              className="vehicle-model"
                              title={equipment.make ? equipment.make : ""}
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                              }}
                            >
                              {equipment.make ? equipment.make : "NA"}
                            </Typography>
                            {equipment.device_assign_status == 1 ? (
                              <Typography className="connected-link">
                                CONNECTED
                              </Typography>
                            ) : (
                              <Typography
                                className="connected-assign-link"
                                onClick={() => {
                                  GpsDevice(AuthUserID, "Assign");
                                  setCurrentActiveEquipObj(equipment);
                                  // if(GpsDevices.length > 0) {
                                  //   setGpsAssignPopup(true)
                                  //   setCurrentActiveEquipObj(equipment)
                                  //   setDeviceSerialNumber(null)
                                  // } else {
                                  //   setGpsRequestPopup(true)
                                  // }
                                }}
                              >
                                {" "}
                                {TotalUnassigned > 0 ? "ASSIGN GPS" : "ADD GPS"}
                              </Typography>
                            )}
                          </Box>

                          <Typography
                            className="vehicle-type"
                            title={equipment.model}
                            style={{
                              filter:
                                equipment.device_assign_status == 1
                                  ? ""
                                  : "grayscale(100%)",
                            }}
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                            }}
                          >
                            {equipment.model ? equipment.model : "NA"}
                          </Typography>
                          <Box
                            className="rentyrd-address"
                            style={{
                              filter:
                                equipment.device_assign_status == 1
                                  ? ""
                                  : "grayscale(100%)",
                            }}
                          >
                            <Typography
                              title={equipment.city ? equipment.city : ""}
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                              }}
                            >
                              {/* {equipment.city ? equipment.city : "NA"}
                              {equipment.state ? equipment.state : "NA"} */}
                              {equipment.city
                                ? equipment.city.length > 20
                                  ? equipment.city.substring(0, 20) + "... "
                                  : equipment.city
                                : "NA"}

                              {equipment.state
                                ? equipment.state.length > 20
                                  ? ", " +
                                    equipment.state.substring(0, 20) +
                                    "..."
                                  : ", " + equipment.state
                                : ""}
                            </Typography>
                            {equipment.owner_pickup_return == "1" ? (
                              <Typography>Delivery Available</Typography>
                            ) : (
                              <Typography></Typography>
                            )}
                          </Box>
                          <Box
                            className="rentyrd-rates"
                            style={{
                              filter:
                                equipment.device_assign_status == 1
                                  ? ""
                                  : "grayscale(100%)",
                            }}
                          >
                            <Box className="rentyrd-rates-item">
                              <Typography>
                                $
                                <CurrentFormat
                                  Currency={equipment.price_month}
                                />
                                <span className="rentyrd-rates-span">/mth</span>
                              </Typography>
                            </Box>
                            <Box className="rentyrd-rates-item">
                              <Typography>
                                $
                                <CurrentFormat
                                  Currency={equipment.price_per_week}
                                />
                                <span className="rentyrd-rates-span">/wk</span>
                              </Typography>
                            </Box>
                            <Box className="rentyrd-rates-item">
                              <Typography>
                                $
                                <CurrentFormat Currency={equipment.price_day} />
                                <span className="rentyrd-rates-span">/day</span>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      className="rentyrd-card-bottom"
                      style={{
                        filter:
                          equipment.device_assign_status == 1
                            ? ""
                            : "grayscale(100%)",
                      }}
                    >
                      <Typography className="card-uid">
                        EID{equipment.id}
                      </Typography>

                      {equipment.device_assign_status == 1 && (
                        <>
                          <Box className="rentyrd-card-bottom-right">
                            <Typography className="gps-imei-id">
                              GPS: {equipment.connected_imei}
                            </Typography>
                            <Typography
                              className="gps-status"
                              onClick={() => {
                                setUnassignEquipId(equipment.id);
                                setGpsDeviceUnassignPopup(true);
                              }}
                            >
                              Unassign
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>

                  {AllEquips.length != indx + 1 && <hr className="gray-line" />}
                </>
              ))}

              {Actionequip.length > AllEquips.length &&
                paginationPage < Actionequip.length && (
                  <Box className="pagination-section">
                    <Box
                      className="btn-load-more"
                      onClick={HandleRentalYardPagination}
                    >
                      {"See More"}
                      <img
                        src={gemArrow}
                        alt="GoEquipMe"
                        className="gem-arrow arrow-load-more"
                      />
                    </Box>
                  </Box>
                )}
            </>
          ) : (
            <>
              <Typography className="empty-rent">
                <span>You do not have any equipment listed yet.</span>
                <span>
                  To list your first item&nbsp;
                  <Link className="rentyrd-menu-link" onClick={(event)=>handleListEquipment(event)}>
                    click here
                  </Link>
                </span>
              </Typography>
            </>
          )}
        </Box>

        {CurrentActiveEquip &&
          CurrentActiveEquipObj["device_assign_status"] == 1 && (
            <Box className="rentyrd-grid-item rentyrd-right">
              <Box className="rentyrd-left-float">
                <Box className="equipment-details-heading">
                  <Typography>Last Reported Location </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "1rem 1rem",
                    gap: "0.5rem",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "0rem" }}
                  >
                    <Checkbox
                      sx={{
                        "&:hover": { bgcolor: "transparent" },
                      }}
                      onChange={HandleViewAll}
                      disableRipple
                      color="default"
                      checkedIcon={<BpCheckedIcon />}
                      icon={<BpIcon />}
                      checked={ViewallLocation}
                      inputProps={{ "aria-label": "Checkbox demo" }}
                    />
                    <label className="agree-label">View All</label>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <Box
                      className="range-pick"
                      sx={{
                        cursor: "pointer",
                        padding: "0px 10px",
                        border: "3px solid #faa61a",
                        width: "auto",
                        background: "#fff",
                        height: "40px",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        ...(realTimeEnabled || ViewallLocation
                          ? { opacity: 0.5, pointerEvents: "none" }
                          : {}),
                      }}
                      onClick={() => {
                        if (!realTimeEnabled && !ViewallLocation) {
                          setGpsDateRangeModel(true);
                        }
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <Typography sx={{ color: '#000 !important', fontSize: '14px', margin: '0', padding: '0' }}>
                          {gpsDeviceFilter && gpsDateRange.length === 2 ? moment(gpsDateRange[0]).format("MM/DD/YYYY") : "Start Date"}
                        </Typography>
                        <HorizontalRuleIcon />
                        <Typography sx={{ color: '#000 !important', fontSize: '14px', margin: '0', padding: '0' }}>
                          {gpsDeviceFilter && gpsDateRange.length === 2 ? moment(gpsDateRange[1]).format("MM/DD/YYYY") : "End Date"}
                        </Typography> */}
                        {gpsDeviceFilter && gpsDateRange.length === 2 ? (
                          <>
                            <Typography
                              sx={{
                                color: "#000 !important",
                                fontSize: "14px",
                                margin: "0",
                                padding: "0",
                              }}
                            >
                              {moment(gpsDateRange[0]).format("MM/DD/YYYY")}
                            </Typography>
                            <HorizontalRuleIcon />
                            <Typography
                              sx={{
                                color: "#000 !important",
                                fontSize: "14px",
                                margin: "0",
                                padding: "0",
                              }}
                            >
                              {moment(gpsDateRange[1]).format("MM/DD/YYYY")}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            sx={{
                              color: "#000 !important",
                              fontSize: "14px",
                              margin: "0",
                              padding: "0",
                            }}
                          >
                            History
                          </Typography>
                        )}
                      </Box>

                      <CalendarMonthIcon />
                    </Box>
                    <FormControl component="fieldset">
                      {GpsDeviceLocationArr[0] &&
                        GpsDeviceLocationArr[0]["device_typeid"] == "1216" && (
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="start"
                              control={
                                <Switch
                                  style={{ color: "#faa61a" }}
                                  disabled={ViewallLocation ? true : false}
                                  checked={realTimeEnabled}
                                  onChange={getRealtimeLocation}
                                />
                              }
                              sx={{
                                color: "#fff",
                              }}
                              label="Realtime:"
                              labelPlacement="start"
                            />
                          </FormGroup>
                        )}
                    </FormControl>
                  </Box>
                </Box>
                <Box className="rentyrd-card">
                  {GpsDeviceLocationArr.length > 0 ? (
                    <>
                      <GoogleMap
                        onLoad={(map) => setMap(map)}
                        ref={mapview}
                        center={defaulCenterLocation}
                        zoom={12}
                        mapContainerStyle={containerStyle}
                      >
                        {!gpsDeviceFilter && (
                          <>
                            {GpsDeviceLocationArr.map((locationItem, indx) => {
                              let number = Number(indx) + 1;
                              return (
                                <Marker
                                  title={
                                    CurrentActiveEquip == locationItem.id &&
                                    GpsDeviceLocationArr.length === 1
                                      ? "Current Location"
                                      : ""
                                  }
                                  position={{
                                    lat: parseFloat(locationItem["latitude"]),
                                    lng: parseFloat(locationItem["longitude"]),
                                  }}
                                  key={number}
                                  //label={locationItem.id.toString()}
                                  icon={{
                                    url:
                                      GpsDeviceLocationArr.length === 1
                                        ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                                        : CurrentActiveEquip == locationItem.id
                                        ? `http://maps.google.com/mapfiles/ms/icons/blue-dot.png`
                                        : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                    scaledSize: new window.google.maps.Size(
                                      40,
                                      40
                                    ), // Change the size of the icon
                                  }}
                                  // onClick={() => {
                                  //   setCurrentActiveEquip(locationItem.id);
                                  // }}
                                />
                              );
                            })}
                          </>
                        )}
                        {gpsDeviceFilter && gpsDeviceDirection.length && (
                          <>
                            {gpsDeviceDirection[0]["origin"]["lat"] ===
                              gpsDeviceDirection[0]["destination"]["lat"] &&
                            gpsDeviceDirection[0]["origin"]["lng"] ===
                              gpsDeviceDirection[0]["destination"]["lng"] ? (
                              <>
                                <Marker
                                  title={"Start and End Location"}
                                  position={{
                                    lat: gpsDeviceDirection[0]["origin"]["lat"],
                                    lng: gpsDeviceDirection[0]["origin"]["lng"],
                                  }}
                                  icon={{
                                    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                    scaledSize: new window.google.maps.Size(
                                      40,
                                      40
                                    ),
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Marker
                                  title={"Start Location"}
                                  position={{
                                    lat: gpsDeviceDirection[0]["origin"]["lat"],
                                    lng: gpsDeviceDirection[0]["origin"]["lng"],
                                  }}
                                  icon={{
                                    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                    scaledSize: new window.google.maps.Size(
                                      40,
                                      40
                                    ),
                                  }}
                                />
                                <Marker
                                  title={"End Location"}
                                  position={{
                                    lat: gpsDeviceDirection[0]["destination"][
                                      "lat"
                                    ],
                                    lng: gpsDeviceDirection[0]["destination"][
                                      "lng"
                                    ],
                                  }}
                                  icon={{
                                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                                    scaledSize: new window.google.maps.Size(
                                      40,
                                      40
                                    ),
                                  }}
                                />
                              </>
                            )}
                          </>
                        )}
                        {directions.map((direction, index) => (
                          <DirectionsRenderer
                            key={index}
                            directions={direction}
                            options={{ suppressMarkers: true }}
                          />
                        ))}
                      </GoogleMap>

                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "20px",
                          fontFamily: "Gotham !important",
                          marginTop: "0.5rem",
                        }}
                      >
                        {GpsDeviceLocationArr[0] &&
                        GpsDeviceLocationArr[0]["device_typeid"] != "1216"
                          ? "Devices report location once every 24 hours"
                          : ""}
                      </Typography>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          padding: "0px 30px",
                          fontSize: "22px",
                          position: "absolute",
                          fontFamily: "GothamBold !important",
                          fontWeight: "500",
                          top: "30%",
                          zIndex: 2,
                        }}
                      >
                        Map data not yet available. Make sure the GPS device is
                        powered on. If location does not show after 24 hours,
                        contact support using the chat widget found in the
                        bottom right.
                        <Typography
                          sx={{
                            color: "#faa61a",
                            fontSize: "24px",
                            fontFamily: "GothamBold !important",
                            fontWeight: "500",
                          }}
                          onClick={() => {
                            localStorage.setItem("OpenChat", "Open");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Or click here.
                        </Typography>
                      </Typography>
                      <Box
                        component="img"
                        src={mapNotAvailable}
                        alt="gps"
                        sx={{ maxWidth: "100%", height: "auto", zIndex: 1 }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}

        {CurrentActiveEquip &&
          CurrentActiveEquipObj["device_assign_status"] == 0 && (
            <Box className="rentyrd-grid-item rentyrd-right">
              <Box className="rentyrd-left-float">
                <Box className="equipment-details-heading no-gps">
                  {/* <Typography>No Gps assigned </Typography> */}
                  <img src={noGpsAssigned} className="nogps-img"></img>
                </Box>
              </Box>
            </Box>
          )}
      </Box>

      {/* Make Gps Request Popup */}
      <Modal
        keepMounted
        open={GpsRequestPopup}
        onClose={() => setGpsRequestPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setGpsRequestPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {loading && (
                <div>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              )}
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Box className="gps-modal-heading">
                  <img src={satellite} className="satelite-icon" alt="" />
                  <Typography variant="h5" sx={{ color: "#fff !important" }}>
                    GoEquipMe<span style={{ color: "#faa61a" }}>GPS</span>
                  </Typography>
                </Box>

                <Box className="rentyrd-dates">
                  <Box className="rentyrd-dates-item rental-dates">
                    <img
                      src={requestGPS}
                      className=""
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Box>

                <Typography variant="h4" sx={{ color: "#faa61a" }}>
                  Equipment Tracking is Here!
                </Typography>

                <Typography sx={{ color: "#fff" }}>
                  Get GoEquipMe GPS and use the app to track your equipment
                  24/7. See how good our special user rates are! Just hit the
                  REQUEST button and GoEquipMe will contact you.
                </Typography>

                <Button
                  className="gps-request-modal-btn"
                  type="button"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                  onClickCapture={GpsRequestCreate}
                >
                  REQUEST
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Make Gps Request Result Popup */}
      <Modal
        keepMounted
        open={GpsRequestPopupResult}
        onClose={() => {
          setGpsRequestPopupResult(false);
          setRequestResponse("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => {
              setGpsRequestPopupResult(false);
              setRequestResponse("");
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {loading && (
                <div>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              )}
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Typography sx={{ color: "#fff" }}>
                  {RequestResponse}
                  {/* Thank you, a GoEquipMe representative will reach out to you.*/}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Assign GPS Device to Equip */}
      <Modal
        keepMounted
        open={GpsAssignPopup}
        onClose={() => setGpsAssignPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setGpsAssignPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Box className="gps-modal-heading">
                  <img src={satellite} className="satelite-icon" alt="" />
                  <Typography variant="h5" sx={{ color: "#fff !important" }}>
                    Assign<span style={{ color: "#faa61a" }}>GPS</span>
                  </Typography>
                </Box>

                {/* <Typography variant="h5" sx={{ color: "#000" }}>
                  <img src={satellite} className="step-icon" alt="" />
                  ASSIGN<span style={{ color: "#faa61a" }}>GPS</span>
                </Typography> */}

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  {CurrentActiveEquipObj != null && (
                    <>
                      <Box className="owner-div">
                        <Box className="owner-img booked-img">
                          <img
                            src={
                              CurrentActiveEquipObj["thumbnail"]
                                ? CurrentActiveEquipObj["thumbnail"]
                                : ""
                            }
                            alt=""
                            style={{ borderRadius: "0px" }}
                          />
                        </Box>
                        <Box sx={{ fontWeight: "bold" }}>
                          <Typography>
                            {CurrentActiveEquipObj["make"]
                              ? CurrentActiveEquipObj["make"]
                              : "Not Available"}
                          </Typography>
                          <Typography style={{ color: "#faa61a" }}>
                            {CurrentActiveEquipObj["model"]
                              ? CurrentActiveEquipObj["model"]
                              : "Not Available"}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="rental-sec-item-top">
                        <Typography>
                          To assign your GPS device to this equipment, enter or
                          choose the serial # below
                        </Typography>
                        <Box className="location-sec-grid">
                          <FormControl
                            variant="filled"
                            sx={{ marginTop: "1rem" }}
                          >
                            <InputLabel
                              id="demo-simple-select-filled-label"
                              sx={{
                                color: "#2d2d2d !important",
                                fontSize: "15px !important",
                              }}
                            >
                              Select GPS IMEI # number
                            </InputLabel>

                            <Select
                              className="select-dropdwn gps-modal-select"
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select"
                              value={DeviceSerialNumber}
                              label="Select GPS IMEI # number"
                              fullWidth
                              onChange={(e) => {
                                setDeviceSerialNumber(e.target.value);
                              }}
                              sx={{
                                "& .MuiSelect-select": {
                                  color: "#2D2D2D !important",
                                  fontFamily: "Gotham !important",
                                  fontSize: "14px !important",
                                },
                                "& .MuiFilledInput-underline:after": {
                                  borderBottom: "0 !important",
                                  borderBottomColor: "transparent !important",
                                },
                                "& .MuiFilledInput-underline:before": {
                                  borderBottom: "0 !important",
                                  borderBottomColor: "transparent !important",
                                },
                              }}
                            >
                              <MenuItem value="">
                                Select GPS IMEI # number
                              </MenuItem>
                              {GpsDevices &&
                                GpsDevices.map((item, index) => (
                                  <MenuItem value={item.device_tableid}>
                                    {index + 1}. {item.imei}
                                    {AssignSerialNumbers &&
                                      AssignSerialNumbers.includes(
                                        item.imei
                                      ) && (
                                        <>
                                          &nbsp;
                                          <span style={{ color: "#ff0000" }}>
                                            (Assigned)
                                          </span>
                                        </>
                                      )}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>

                <Button
                  className="gps-request-modal-btn"
                  type="button"
                  variant="contained"
                  sx={{ mt: 2, mb: 2, backgroundColor: "#139246" }}
                  //onClickCapture={GpsDeviceAssign}
                  onClickCapture={HandleAssignGP}
                >
                  ASSIGN
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Unassign GPS Device from Equip */}
      <Modal
        keepMounted
        open={GpsDeviceUnassignPopup}
        onClose={() => setGpsDeviceUnassignPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setGpsDeviceUnassignPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Box className="gps-modal-heading">
                  <img src={satellite} className="satelite-icon" alt="" />
                  <Typography variant="h5" sx={{ color: "#fff !important" }}>
                    Unassign<span style={{ color: "#faa61a" }}>GPS</span>
                  </Typography>
                </Box>

                {/* <Typography variant="h5" sx={{ color: "#000" }}>
                  <img src={satellite} className="step-icon" alt="" />
                  UNASSIGN<span style={{ color: "#faa61a" }}>GPS</span>
                </Typography> */}

                <Box>
                  {/* {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )} */}

                  <Box className="rental-sec-item-top">
                    <Typography>
                      Are you sure you want to unassign GPS device for this
                      equipment.
                    </Typography>
                    <Box className="location-sec-grid">
                      <Button
                        className="signin-btn common-yellow-btn"
                        type="button"
                        variant="contained"
                        sx={{
                          mt: 3,
                          mb: 2,
                          backgroundColor: "#faa61a !important",
                        }}
                        onClickCapture={() => {
                          setGpsDeviceUnassignPopup(false);
                        }}
                      >
                        No
                      </Button>
                      <Button
                        className="signin-btn"
                        type="button"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                        onClickCapture={GpsDeviceUnassign}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* ReAssign GPS Device Equip confirm popup */}
      <Modal
        keepMounted
        open={ReAssignPopup}
        onClose={() => setReAssignPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setReAssignPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Box className="gps-modal-heading">
                  <img src={satellite} className="satelite-icon" alt="" />
                  <Typography variant="h5" sx={{ color: "#fff !important" }}>
                    Reassign<span style={{ color: "#faa61a" }}>GPS</span>
                  </Typography>
                </Box>

                <Box>
                  <Box className="rental-sec-item-top">
                    <Typography>
                      Are you sure you want to reassign this GPS device?
                    </Typography>
                    <Box className="location-sec-grid">
                      <Button
                        className="signin-btn common-yellow-btn"
                        type="button"
                        variant="contained"
                        sx={{
                          mt: 3,
                          mb: 2,
                          backgroundColor: "#faa61a !important",
                        }}
                        onClickCapture={() => {
                          setReAssignPopup(false);
                        }}
                      >
                        No
                      </Button>
                      <Button
                        className="signin-btn"
                        type="button"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                        onClickCapture={GpsDeviceAssign}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* calender view model  */}

      <Modal
        keepMounted
        open={gpsDateRangeModel}
        onClose={() => setGpsDateRangeModel(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setGpsDateRangeModel(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main gps-calender-popup">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form edit-eqp-inner popup-cmmn popup-white"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box className="gps-modal-heading gps-range-model-heading">
                  <CalendarMonthIcon sx={{ color: "#faa61a !important" }} />
                  <Typography
                    variant="h5"
                    sx={{ color: "#fff !important", ml: "1rem" }}
                  >
                    Select Date Range
                  </Typography>
                </Box>
                <DatePicker
                  numberOfMonths={1}
                  disableMonthPicker
                  disableYearPicker
                  minDate={minDateValue}
                  maxDate={new Date()}
                  value={gpsDateRange.length === 0 ? null : gpsDateRange}
                  onChange={(range) => {
                    evaluateGpsDateRange(range);
                  }}
                  range
                />
                {gpsDateRange.length === 2 && (
                  <>
                    <Box className="gps-model-footer">
                      <Typography className="typography">
                        Start Date -{" "}
                        {moment(gpsDateRange[0]).format("MM/DD/YYYY")}
                      </Typography>
                      <Typography className="typography">
                        End Date -{" "}
                        {moment(gpsDateRange[1]).format("MM/DD/YYYY")}
                      </Typography>
                      <Box className="gps-model-confirmation">
                        <Button
                          className="signin-btn common-yellow-btn"
                          type="button"
                          variant="contained"
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "#faa61a !important",
                          }}
                          onClick={() => {
                            setGpsDateRange([]);
                            setGpsDeviceFilter(false);
                            setDirections([]);
                            // setMinDateValue(null);
                          }}
                        >
                          Reset
                        </Button>
                        <Button
                          className="signin-btn"
                          type="button"
                          variant="contained"
                          onClick={() => {
                            setGpsDateRangeModel(false);
                            getGpsDeviceLocationFilter();
                          }}
                          sx={{ backgroundColor: "#139246" }}
                        >
                          Search
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default Gps;
