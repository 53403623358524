import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useState, useEffect, useMemo, useRef } from "react";

import RentalSummary from "./RentalSummary";
import ImageGallery from "./ImageGallery";
import LocationIcon from "assets/images/location.png";
import EquipProtection from "assets/images/details-page/EquipmentProtection.png";
import UsCurrencyFormat from "components/MyofficeTrailer/EquipmentOrders/UsCurrencyFormat";
import dummyProfile from "assets/images/dummy-profile-pic.jpeg";
import ThumbsImg from "assets/images/likeMe.png";
import MessageIcon from "assets/images/details-page/msgg.png";
import RentalYardIcon from "assets/images/details-page/visit.png";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CustomCalendar from "components/Calendar/CustomCalendar";
import CustomCalendarTime from "components/Calendar/CustomCalendarTime";
import moment from "moment";
import {
  setEquipmentBlockedDates,
  setEquipmentBookedDates,
  setEquipmentRequestedDates,
  setPartiallyBookedDates,
  setPartiallyPendingDates,
  setAllPartialDates,
  setSessionExpire,
  setalertMessage,
  setalertMessagetype,
  setRentOnWeekend,
  setWalletBalance,
  setReservedDates,
  setPartialDatesCount,
  setPartialOrderRenterList,
  setCalendarDateAngle,
  setAllPartialDatesTimes,
} from "redux/redux-slice";
import { convertTimeZone, getDatesInRange } from "utils/commonFxns";
import { EquipmentAPIs } from "service/api-service";
import AddBalance from "components/AddBalance/AddBalance";
import CloseIcon from "@mui/icons-material/Close";
import PaymentHold from "components/AddBalance/PaymentHold";
import RentalSummary2 from "./RentalSummary2";
import {
  getAllSlotsForDate,
  generateTimeSlots,
  hasMultipleRenter,
  getDateAngles,
} from "utils/partiallyBookedTime";
const headingStyle = {
  color: "#fff",
  fontFamily: "GothamBold !important",
  fontSize: {
    xs: "16px",
    sm: "18px",
    md: "20px",
    lg: "22px",
    xl: "24px",
  },
};

const subheadingStyle = {
  color: "#faa61a",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  textTransform: "uppercase",
  mb: 0.5,
};

const textStyle = {
  color: "#fff",
  fontFamily: "Montserrat !important",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  fontWeight: "500",
};

const dividerStyle = {
  borderColor: "#faa61a",
  borderWidth: "3px",
  mb: 1,
  mt: 1,
};

const grayDivider = {
  borderColor: "gray",
  borderWidth: "1.5px",
  mb: 0.5,
  mt: 0.5,
};

const rentalFlex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",
};

const flexCommon = {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
};

const underlineYellowText = {
  color: "#faa61a",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  textDecorationColor: "#faa61a",
  textDecoration: "underline",
};

const dollarUSLocale = Intl.NumberFormat("en-US");

// interface rentalDates {
//   startDate: string;
//   endDate: string;
// }

interface PriceDetails {
  rentPrice: number;
  securityDeposit: number;
  equipProtection: number;
  rentalTotal: number;
  deliveryPrice: number;
  grandTotal: number;
  totalDays: number;
}

interface rentalTimes {
  startTime: string;
  endTime: string;
}

const EquipDetailsRent = ({
  galleryImages,
  equipmentData,
  handleChatMessage,
  LikeLoading,
  LikeStatus,
  LikeDislikeEquip,
  locationViewRef,
  timeZoneAbbr,
  timeZone,
  bookingSteps,
  setBookingSteps,
  setCompleteProfilePopup,
  transportOption,
  setTransportOption,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );

  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );

  const WalletBalance = useSelector(
    (state: IStoreValue) => state.equipmentReducer.WalletBalance
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [equipRentedDates, setEquipRentedDates] = useState<any>([null, null]);
  const [equipRentedTimes, setEquipRentedTimes] = useState<rentalTimes>({
    startTime: null,
    endTime: null,
  });

  const [currentMonth, setCurrentMonth] = useState(new Date());

  const calendarRef = useRef(null);

  const [chooseTransport, setChooseTransport] = useState<string>(null);
  const [priceDetails, setPriceDetails] = useState<PriceDetails>({
    rentPrice: 0,
    securityDeposit: 0,
    equipProtection: 0,
    rentalTotal: 0,
    deliveryPrice: 0,
    grandTotal: 0,
    totalDays: 0,
  });

  const [minDateTime, setMinDateTime] = useState({
    minDate: new Date(),
    minTime: null,
  });

  const [DeliveryInstructionsval, setDeliveryInstructionsval] = useState("");
  const [DeliveryInstructionsvallength, setDeliveryInstructionsvallength] =
    useState(400);

  const defaultdeliveryLocation = {
    lat: 39.321980897034734,
    lng: -111.10149320091872,
  };
  const [deliveryLocation, setDeliveryLocation] = useState(
    defaultdeliveryLocation
  );

  const [FullAddress, setFullAddress] = useState({
    city: "",
    state: "",
    country: "",
    postal_code: "",
    address: "",
  });
  const [Distance, setDistance] = useState(0);
  const [transportCharge, setTransportCharge] = useState({
    minimumDeliveryCharge: 0,
    additionalDeliveryCharge: 0,
  });

  const [deliveryError, setDeliveryError] = useState(false);

  const [addBalanceBox, setAddBalanceBox] = useState<boolean>(false);
  const handleAddBalancePopupOpen = () => setAddBalanceBox(true);
  const handleAddBalancePopupClose = () => setAddBalanceBox(false);

  const [payWithCard, setPayWithCard] = useState<boolean>(false);
  const handlePayWithCardOpen = () => setPayWithCard(true);
  const handlePayWithCardClose = () => setPayWithCard(false);

  const [holdAmount, setHoldAmount] = useState<number>(0);
  const [orderRequestData, setOrderRequestData] = useState<any>({});
  const [requestedOrderId, setRequestedOrderId] = useState<number>(0);
  const [orderSuccessPopup, setOrderSuccessPopup] = useState(false);

  // const timeSlots = useMemo(() => generateTimeSlots(), []);

  const getUserProfile = async (user_id) => {
    try {
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const profileData: IApiResponseRawData = await EquipmentAPIs.getProfile(
        {
          user_id: user_id.toString(),
        },
        headers
      );
      if (profileData["type"] == "RXSUCCESS") {
        const balance = Number(profileData["data"]["balance"]);
        dispatch(setWalletBalance(balance));
        // setLoadWalletBalance(false)
        // setproceedpaysection(true);
        // setcheckoutsection(false);
      } else if (
        profileData["status"] == false &&
        profileData["slug"] == "Session Expired"
      ) {
        dispatch(setSessionExpire(true));
        return false;
      }
    } catch (err) {
      console.log("Get user profile error", err);
    }
  };

  // const getDatesInRange = (startDate, endDate) => {
  //   let start = new Date(startDate);
  //   let end = new Date(endDate);
  //   let dates = [];

  //   // Loop from start to end date
  //   while (start <= end) {
  //     let year = start.getFullYear();
  //     let month = String(start.getMonth() + 1).padStart(2, "0"); // Add leading zero
  //     let day = String(start.getDate()).padStart(2, "0"); // Add leading zero

  //     // Push the date in 'YYYY-MM-DD' format
  //     dates.push(`${year}-${month}-${day}`);

  //     // Move to the next day
  //     start.setDate(start.getDate() + 1);
  //   }

  //   return dates;
  // };

  const getPartialDates = ({ startDate, startTime, endDate, endTime }) => {
    if (
      startDate === endDate &&
      (startTime !== "00:00:00" || endTime !== "23:30:00")
    ) {
      return {
        halfStart: {
          startDate,
          endDate,
          startTime,
          endTime,
        },
        halfEnd: null,
      };
    } else {
      const partialDate = { halfStart: null, halfEnd: null };
      if (startTime !== "00:00:00") {
        partialDate["halfStart"] = {
          startDate,
          endDate: startDate,
          startTime,
          endTime: "23:30:00",
        };
      }
      if (endTime !== "23:30:00") {
        partialDate["halfEnd"] = {
          startDate: endDate,
          endDate,
          startTime: "00:00:00",
          endTime,
        };
      }
      return partialDate;
    }
  };

  const handleDateChanges = (equipmentData) => {
    const blockDates = `${equipmentData["block_dates"]}`;
    const blockedDatesArray = blockDates.split(",");
    const modifiedBlockedDates = [];
    blockedDatesArray.forEach((date) => {
      if (date) {
        modifiedBlockedDates.push(date);
      }
    });

    const partiallySetBookedStart = new Set();
    const partiallySetBookedEnd = new Set();
    const partiallySetPendingStart = new Set();
    const partiallySetPendingEnd = new Set();

    const partiallyBookedStart = [];
    const partiallyBookedEnd = [];
    const partiallyPendingStart = [];
    const partiallyPendingEnd = [];

    const bookedDateArray = [];

    equipmentData?.bookedDates?.forEach((dateElement) => {
      const dateRange = getDatesInRange(
        dateElement?.start_date,
        dateElement?.end_date
      );
      bookedDateArray.push(...dateRange);
      const { halfEnd, halfStart } = getPartialDates({
        startDate: dateElement?.start_date,
        startTime: dateElement?.start_time,
        endDate: dateElement?.end_date,
        endTime: dateElement?.end_time,
      });
      if (halfStart) {
        partiallyBookedStart.push({
          ...halfStart,
          status: "booked",
          userId: dateElement?.user_id,
        });
        if (
          halfStart?.startDate &&
          !partiallySetBookedStart.has(halfStart?.startDate)
        ) {
          partiallySetBookedStart.add(halfStart?.startDate);
        }
      }
      if (halfEnd) {
        partiallyBookedEnd.push({
          ...halfEnd,
          status: "booked",
          userId: dateElement?.user_id,
        });
        if (halfEnd?.endDate && !partiallySetBookedEnd.has(halfEnd?.endDate)) {
          partiallySetBookedEnd.add(halfEnd?.endDate);
        }
      }
    });

    const requestedDates = [];

    equipmentData?.requestDates?.forEach((dateElement) => {
      const dateRange = getDatesInRange(
        dateElement?.start_date,
        dateElement?.end_date
      );
      requestedDates.push(...dateRange);
      const { halfEnd, halfStart } = getPartialDates({
        startDate: dateElement?.start_date,
        startTime: dateElement?.start_time,
        endDate: dateElement?.end_date,
        endTime: dateElement?.end_time,
      });
      if (halfStart) {
        partiallyPendingStart.push({
          ...halfStart,
          status: "requested",
          userId: dateElement?.user_id,
        });
        if (
          halfStart?.startDate &&
          !partiallySetPendingStart.has(halfStart?.startDate)
        ) {
          partiallySetPendingStart.add(halfStart?.startDate);
        }
      }
      if (halfEnd) {
        partiallyPendingEnd.push({
          ...halfEnd,
          status: "requested",
          userId: dateElement?.user_id,
        });
        if (halfEnd?.endDate && !partiallySetPendingEnd.has(halfEnd?.endDate)) {
          partiallySetPendingEnd.add(halfEnd?.endDate);
        }
      }
    });

    const partialDatesArray = [
      ...Array.from(new Set(partiallySetBookedStart)),
      ...Array.from(new Set(partiallySetBookedEnd)),
      ...Array.from(new Set(partiallySetPendingStart)),
      ...Array.from(new Set(partiallySetPendingEnd)),
    ];

    // console.log("partiallyBookedStart", partiallyBookedStart);
    // console.log("partiallyBookedEnd", partiallyBookedEnd);
    // console.log("partiallyPendingStart", partiallyPendingStart);
    // console.log("partiallyPendingEnd", partiallyPendingEnd);
    // console.log("partiallyBookedStart", partiallySetBookedStart);
    // console.log("partiallyBookedEnd", partiallySetBookedEnd);
    // console.log("partiallyPendingStart", partiallySetPendingStart);
    // console.log("partiallyPendingEnd", partiallySetPendingEnd);
    // console.log("all combined", partialDatesArray);
    const partialBookingDatesCount = {};
    partialDatesArray.forEach((date: string) => {
      partialBookingDatesCount[date] =
        (partialBookingDatesCount[date] || 0) + 1;
    });
    // console.log("partialBookingDatesCount", partialBookingDatesCount);

    const allPartialDatesTimes = [
      ...partiallyBookedStart,
      ...partiallyBookedEnd,
      ...partiallyPendingStart,
      ...partiallyPendingEnd,
    ];
    // console.log("all allPartialDatesTimes", allPartialDatesTimes);
    const allPartialDates = new Set();
    allPartialDatesTimes.forEach(({ startDate }) => {
      if (!allPartialDates.has(startDate)) {
        allPartialDates.add(startDate);
      }
    });

    const generatedKeyValues = {};
    const partialOrderRenterList = {};
    const calendarDateAngle = {};
    allPartialDates.forEach((date) => {
      const allGeneratedSlots = getAllSlotsForDate({
        date,
        reservedDate: allPartialDatesTimes,
      });
      const renters = hasMultipleRenter({ date, allPartialDatesTimes });
      partialOrderRenterList[`${date}`] = renters;
      const dateAngle = getDateAngles({ date, allPartialDatesTimes });
      calendarDateAngle[`${date}`] = dateAngle;
      // console.log("allGeneratedSlots",allGeneratedSlots);
      const count = Object.entries(allGeneratedSlots)?.reduce((sum, item) => {
        if (!item[1]["disable"]) {
          return sum + 1;
        }
        return sum;
      }, 0);
      if (count >= 1) {
        generatedKeyValues[`${date}`] = allGeneratedSlots;
      }
    });
    // console.log("allPartialDatesTimes",allPartialDatesTimes);
    const { time, currentDate } = convertTimeZone(equipmentData["timezone"]);
    const hours24 = moment(time, ["h:mm A"]).format("HH:mm");
    setMinDateTime({ minDate: new Date(currentDate), minTime: `${hours24}` });
    dispatch(setEquipmentRequestedDates(Array.from(new Set(requestedDates))));
    dispatch(setEquipmentBookedDates(Array.from(new Set(bookedDateArray))));
    dispatch(setEquipmentBlockedDates(modifiedBlockedDates));
    dispatch(setAllPartialDates(Array.from(allPartialDates)));
    dispatch(setReservedDates([generatedKeyValues]));
    dispatch(setPartialOrderRenterList(partialOrderRenterList));
    dispatch(setCalendarDateAngle(calendarDateAngle));
    dispatch(setAllPartialDatesTimes(allPartialDatesTimes));
    dispatch(
      setRentOnWeekend({
        sunday: equipmentData["sunday"],
        saturday: equipmentData["weekends"],
      })
    );
    dispatch(
      setPartiallyBookedDates([
        Array.from(partiallySetBookedStart),
        Array.from(partiallySetBookedEnd),
      ])
    );
    dispatch(
      setPartiallyPendingDates([
        Array.from(partiallySetPendingStart),
        Array.from(partiallySetPendingEnd),
      ])
    );
    dispatch(setPartialDatesCount(partialBookingDatesCount));
  };

  const calculatePrice = ({
    __rentStartDate,
    __rentEndDate,
    _startTime,
    _endTime,
  }) => {
    if (!__rentStartDate || !__rentEndDate || !_startTime || !_endTime) return;
    const startDate = moment(
      `${__rentStartDate} ${_startTime}`,
      "YYYY/MM/DD HH:mm"
    );
    const endDate = moment(`${__rentEndDate} ${_endTime}`, "YYYY/MM/DD HH:mm");

    if (startDate.isSame(endDate, "day") && endDate.isBefore(startDate)) {
      dispatch(
        setalertMessage("The selected pickup or drop-off time is invalid.")
      );
      dispatch(setalertMessagetype("error"));
      setPriceDetails({
        rentPrice: 0,
        securityDeposit: 0,
        equipProtection: 0,
        rentalTotal: 0,
        deliveryPrice: 0,
        grandTotal: 0,
        totalDays: 0,
      });
      return false;
    }

    const duration = moment.duration(endDate.diff(startDate));
    const totalDays = Math.floor(duration.asDays());

    const months = Math.floor(duration.asMonths());
    const weeks = Math.floor(duration.subtract(months, "months").asWeeks());
    const days = Math.floor(duration.subtract(weeks, "weeks").asDays());
    const hours = Math.floor(duration.subtract(days, "days").asHours());

    let monthPrice = Number(equipmentData["price_month"]),
      weekPrice = Number(equipmentData["price_per_week"]),
      dayPrice =
        months > 0 ? monthPrice / 30 : Number(equipmentData["price_day"]),
      hourPrice = Number(equipmentData["price_per_hour"]);

    const priceH = Math.min(hours * hourPrice, dayPrice);
    const priceD = days * dayPrice + priceH;
    const adjustedDayPrice = months > 0 ? priceD : Math.min(priceD, weekPrice);
    const priceW = weeks * weekPrice + adjustedDayPrice;
    const rentPrice = months * monthPrice + Math.min(priceW, monthPrice);

    // Calculate fees and grand total
    const securityDeposit = parseFloat((rentPrice * 0.2).toFixed(2));
    const equipProtection = parseFloat((rentPrice * 0.15).toFixed(2));
    const rentalTotal = rentPrice + securityDeposit + equipProtection;

    let deliveryPrice = 0;
    if (chooseTransport === "owner") {
      deliveryPrice =
        Number(equipmentData["minimum_delivery_charges"]) +
        Number(transportCharge?.additionalDeliveryCharge);
    }
    const grandTotal = rentalTotal + deliveryPrice;

    console.log("rental total", {
      rentPrice,
      securityDeposit,
      equipProtection,
      rentalTotal,
      deliveryPrice,
      grandTotal,
      totalDays,
    });
    // Set all values in a single state update
    setPriceDetails({
      rentPrice,
      securityDeposit,
      equipProtection,
      rentalTotal,
      deliveryPrice,
      grandTotal,
      totalDays,
    });
  };

  const DeliveryInstructions = (event) => {
    // Get the length and remaining characters
    let value_len = event.target.value.length;
    let left_len = 400 - value_len;

    // Set the length in the state
    setDeliveryInstructionsvallength(left_len);

    // Check if the length is within the limit
    if (value_len < 400) {
      // Update the delivery instructions value in the state
      setDeliveryInstructionsval(event.target.value);
    } else {
      // If the length exceeds the limit, show an error and return false
      dispatch(setalertMessage("Delivery instructions limit exceeded."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
  };

  const calculateDistance = async (center, address) => {
    try {
      if (!center?.lat && !center?.lng) {
        return false;
      }
      setDeliveryLocation(center);
      setFullAddress(address);
      let lat = center.lat;
      let lng = center.lng;

      let auth_user = localStorage.getItem("token-info");
      auth_user = JSON.parse(auth_user);

      // Get distance data from the server
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const distanceData: IApiResponseRawData = await EquipmentAPIs.getDistence(
        {
          equip_id: equipmentData["id"]?.toString(),
          lat: lat?.toString(),
          lng: lng?.toString(),
        },
        headers
      );

      // If the distance data is successfully retrieved
      if (distanceData["type"] == "RXSUCCESS") {
        let price = 0;

        // Check if the distance is within the allowed maximum miles
        if (
          distanceData["data"]["distance"] <= Number(equipmentData["max_miles"])
        ) {
          setDistance(distanceData["data"]["distance"]);
          let freeMiles = Number(equipmentData["free_delivery_miles"]);
          let additional_miles_fee = Number(
            equipmentData["additional_miles_fee"]
          );

          // Calculate delivery price based on distance, free miles, and additional miles fee
          let additionalMilesPrice = 0;
          if (freeMiles >= Math.ceil(distanceData["data"]["distance"])) {
            additionalMilesPrice = 0;
          } else {
            //price = (Math.ceil(distanceData["data"]["distance"]) - freeMiles) * additional_miles_fee;
            price = distanceData["data"]["distance"] - freeMiles;
            price = price * additional_miles_fee;
            price = price * 2;
            additionalMilesPrice = price;
          }
          setTransportCharge((prev) => ({
            ...prev,
            additionalDeliveryCharge: additionalMilesPrice,
          }));

          /****************/
          //calculation fraction issue fixed
          let pp = price.toFixed(2);
          price = parseFloat(pp);

          let del_fee = equipmentData["minimum_delivery_charges"];
          del_fee = parseFloat(del_fee).toFixed(2);
          /****************/

          // Calculate final transport total and grand total
          let final_trnsport_total = Number(del_fee) + Number(price);
          // settransportTotal(final_trnsport_total);

          let grand_total =
            Number(final_trnsport_total) + Number(priceDetails?.rentalTotal);
          setPriceDetails((prev) => ({
            ...prev,
            grandTotal: grand_total,
            deliveryPrice: final_trnsport_total,
          }));
          // setgrandTotal(grand_total);
          setDeliveryError(false);
        } else {
          // If the distance is beyond the allowed maximum, show an error and stop calculating
          dispatch(
            setalertMessage("Delivery is not available to your location 125.")
          );
          dispatch(setalertMessagetype("error"));
          setDeliveryError(true);
          return false;
        }
      } else if (
        distanceData["status"] == false &&
        distanceData["slug"] == "Session Expired"
      ) {
        // If session expires, set session expiration state
        dispatch(setSessionExpire(true));
        return false;
      } else {
        // If there is an issue with distance data, stop calculating
        return false;
      }
    } catch (err) {
      // If there is an error during the calculation, stop calculating and log the error
      console.log(err);
    }
  };

  const handleTransportSelection = (event) => {
    console.log("event.target.value", event.target.value);
    if (event.target.value === "renter") {
      setPriceDetails((prev) => ({
        ...prev,
        deliveryPrice: 0,
        grandTotal: prev.rentalTotal,
      }));
      setTransportCharge((prev) => ({ ...prev, additionalDeliveryCharge: 0 }));
      setFullAddress({
        city: "",
        state: "",
        country: "",
        postal_code: "",
        address: "",
      });
    }
    setChooseTransport(event.target.value);
  };

  const submitRentalRequest = async () => {
    try {
      console.log("AuthUserId", AuthUserId);
      setLoading(true);
      const startTimeUTC = moment(
        `${equipRentedDates[0]?.format("YYYY/MM/DD")}T${
          equipRentedTimes?.startTime
        }`,
        "YYYY-MM-DDTHH:mm"
      ).utc();
      const endTimeUTC = moment(
        `${equipRentedDates[1]?.format("YYYY/MM/DD")}T${
          equipRentedTimes?.endTime
        }`,
        "YYYY-MM-DDTHH:mm"
      )
        .utc()
        .toDate()
        .getTime();
      const orderData = {
        user_id: AuthUserId?.toString(),
        equip_id: equipmentData.id.toString(),
        txn_id: Date.now().toString(),
        order_details: "test",
        status: "1",
        amount:
          Number(priceDetails?.rentalTotal) -
          Number(priceDetails?.securityDeposit) -
          Number(priceDetails?.equipProtection),
        start_date: equipRentedDates[0]?.format("YYYY/MM/DD"),
        end_date: equipRentedDates[1]?.format("YYYY/MM/DD"),
        start_time: equipRentedTimes?.startTime,
        end_time: equipRentedTimes?.endTime,
        delivery_required: chooseTransport === "owner" ? "1" : "0",
        instruction: DeliveryInstructionsval,
        city: chooseTransport === "owner" ? FullAddress.city : "",
        state: chooseTransport === "owner" ? FullAddress.state : "",
        country: chooseTransport === "owner" ? FullAddress.country : "",
        postal_code: chooseTransport === "owner" ? FullAddress.postal_code : "",
        address: chooseTransport === "owner" ? FullAddress.address : "",
        delivery_charges: transportCharge?.additionalDeliveryCharge / 2,
        pickup_chages: transportCharge?.additionalDeliveryCharge / 2,
        total_days: priceDetails?.totalDays?.toString(),
        total_miles: Distance,
        delivery_lat: deliveryLocation.lat.toString(),
        delivery_lng: deliveryLocation.lng.toString(),
        equip_user_id: equipmentData["user_id"].toString(),
        minimum_delivery_fees:
          chooseTransport === "owner"
            ? equipmentData["minimum_delivery_charges"]
            : "0",
        order_date: startTimeUTC,
        security_deposit: priceDetails?.securityDeposit?.toString(),
        equipment_protection_fees: priceDetails?.equipProtection?.toString(),
        total_price: priceDetails?.grandTotal?.toString(),
        end_time_utc: endTimeUTC,
        order_from: "web",
        // Added for card payment hold
        payment_hold: false,
        CardObj: {},
        is_expire: true,
      };

      const userBalance = WalletBalance ?? 0;
      if (userBalance < priceDetails?.grandTotal) {
        const remainingBalance = priceDetails?.grandTotal - userBalance;
        setHoldAmount(remainingBalance);
        setOrderRequestData(orderData);
        handlePayWithCardOpen();
        return false;
      }

      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const orderResult: IApiResponseRawData = await EquipmentAPIs.addOrder(
        orderData,
        headers
      );
      if (orderResult["type"] === "RXSUCCESS") {
        // open complete order popup
        setRequestedOrderId(orderResult["data"]?.["requestedOrderId"]);
        setOrderSuccessPopup(true);
      } else {
        throw new Error(orderResult["message"] || "Something went wrong!");
      }
    } catch (err) {
      dispatch(setalertMessage(err["message"]));
      dispatch(setalertMessagetype("error"));
      console.log("error placing Order", err);
    } finally {
      setLoading(false);
    }
  };

  const HandleCardHoldPayment = async (cardObjData) => {
    try {
      setLoading(true);
      const final = {
        ...orderRequestData,
        CardObj: cardObjData,
        payment_hold: true,
      };
      let headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const orderResult: IApiResponseRawData = await EquipmentAPIs.addOrder(
        final,
        headers
      );
      if (orderResult["type"] == "RXSUCCESS") {
        setRequestedOrderId(orderResult["data"]?.["requestedOrderId"]);
        setOrderSuccessPopup(true);
        // handlePayWithCardClose();
      } else if (
        orderResult["status"] == false &&
        orderResult["slug"] == "Session Expired"
      ) {
        dispatch(setSessionExpire(true));
        return false;
      } else {
        throw new Error(orderResult["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
      console.log("err", error);
    } finally {
      handlePayWithCardClose();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.entries(equipmentData).length) {
      setTransportCharge((prev) => ({
        ...prev,
        minimumDeliveryCharge: equipmentData["minimum_delivery_charges"],
      }));
      handleDateChanges(equipmentData);
    }
  }, [equipmentData]);

  useEffect(() => {
    if (
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      equipRentedTimes?.startTime &&
      equipRentedTimes?.endTime
    ) {
      // console.log("calculations occurs");
      calculatePrice({
        __rentStartDate: equipRentedDates[0]?.format("YYYY/MM/DD"),
        __rentEndDate: equipRentedDates[1]?.format("YYYY/MM/DD"),
        _startTime: equipRentedTimes?.startTime,
        _endTime: equipRentedTimes?.endTime,
      });
    } else if (
      (!equipRentedDates[0] && !equipRentedDates[1]) ||
      !equipRentedTimes?.startTime ||
      !equipRentedTimes?.endTime
    ) {
      setPriceDetails({
        rentPrice: 0,
        securityDeposit: 0,
        equipProtection: 0,
        rentalTotal: 0,
        deliveryPrice: 0,
        grandTotal: 0,
        totalDays: 0,
      });
    }
  }, [equipRentedDates, equipRentedTimes]);

  return (
    <Box>
      {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      {/* <Container> */}
      {/* The below box is for heading */}
      {bookingSteps === 1 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.2rem",
            mt: 5,
            mb: 4,
          }}
        >
          <Typography
            variant="h3"
            component="h3"
            sx={{
              color: "white",
              fontSize: "3rem",
              fontWeight: "900",
              fontFamily: "GothamBold !important",
            }}
          >
            {equipmentData?.make || "NA"}
          </Typography>
          <Typography
            variant="h5"
            component="h5"
            sx={{
              color: "#faa61a",
              fontSize: "1.5rem",
              fontWeight: "600",
            }}
          >
            {equipmentData?.model || "NA"}
          </Typography>
        </Box>
      )}

      {/* The below box is the main container */}
      {bookingSteps === 1 && (
        <Box
          sx={{
            // display: "grid",
            // gridTemplateColumns: {
            //   xs: "1fr",
            //   sm: "1fr",
            //   md: "1fr",
            //   lg: "1fr 1fr",
            //   xl: "1fr 1fr",
            // },
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
            gap: {
              xs: "2rem",
              sm: "2rem",
              md: "3rem",
              lg: "5rem",
              xl: "5rem",
            },
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {/* first container */}
          {/* {bookingSteps === 1 && ( */}
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "45%",
                lg: "45%",
                xl: "45%",
              },
              position: "relative",
            }}
          >
            <ImageGallery imagesGallerys={galleryImages} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
            >
              {isLoggedin && (
                <>
                  {LikeLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {LikeStatus == "1" || LikeStatus == 1 ? (
                        <Box
                          // className="image-like-icon"
                          onClick={() => {
                            LikeDislikeEquip(0);
                          }}
                          sx={{
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            component={"img"}
                            src={ThumbsImg}
                            alt="Thumps icon"
                            sx={{
                              width: "100%",
                              height: "100%",
                              display: "block",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          // className="image-like-icon"
                          sx={{
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                            filter: "grayscale(100%)",
                          }}
                          onClick={() => {
                            LikeDislikeEquip(1);
                          }}
                        >
                          <Box
                            component={"img"}
                            src={ThumbsImg}
                            alt="Thumps icon"
                            sx={{
                              width: "100%",
                              height: "100%",
                              display: "block",
                            }}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
          {/* )} */}
          {/* second container */}
          {/* {bookingSteps === 1 && ( */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: {
                xs: "100%",
                sm: "100%",
                md: "45%",
                lg: "45%",
                xl: "45%",
              },
            }}
          >
            <Box sx={{ ...flexCommon, cursor: "pointer" }}>
              <Box
                component={"img"}
                src={LocationIcon}
                alt=""
                sx={{
                  width: "30px",
                  height: "30px",
                  display: "block",
                  objectFit: "contain",
                }}
              />
              <Typography
                sx={underlineYellowText}
                onClick={() => {
                  // by GD 12Dec to scrol to map
                  locationViewRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
              >
                {equipmentData?.city || "NA"},{equipmentData?.state || "NA"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0",
              }}
            >
              <Divider sx={grayDivider} />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  padding: "0.5rem 0",
                  gap: "0",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                        lg: "20px",
                        xl: "22px",
                      },
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    <UsCurrencyFormat
                      amount={equipmentData?.price_month}
                      decimal={false}
                    />
                    <Typography
                      component={"span"}
                      // className="rentyrd-rates-span"
                      sx={{
                        color: "#faa61a",
                        fontFamily: "Gotham !important",
                        fontSize: {
                          xs: "10px",
                          sm: "11px",
                          md: "12px",
                          lg: "13px",
                          xl: "14px",
                        },
                      }}
                    >
                      /m
                    </Typography>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderLeft: "2px solid gray",
                    borderRight: "2px solid gray",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                        lg: "20px",
                        xl: "22px",
                      },
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    <UsCurrencyFormat
                      amount={equipmentData?.price_per_week}
                      decimal={false}
                    />
                    <Typography
                      component={"span"}
                      // className="rentyrd-rates-span"
                      sx={{
                        color: "#faa61a",
                        fontFamily: "Gotham !important",
                        fontSize: {
                          xs: "10px",
                          sm: "11px",
                          md: "12px",
                          lg: "13px",
                          xl: "14px",
                        },
                      }}
                    >
                      /w
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                        lg: "20px",
                        xl: "22px",
                      },
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    <UsCurrencyFormat
                      amount={equipmentData?.price_day}
                      decimal={false}
                    />
                    <Typography
                      component={"span"}
                      // className="rentyrd-rates-span"
                      sx={{
                        color: "#faa61a",
                        fontFamily: "Gotham !important",
                        fontSize: {
                          xs: "10px",
                          sm: "11px",
                          md: "12px",
                          lg: "13px",
                          xl: "14px",
                        },
                      }}
                    >
                      /d
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <Divider sx={grayDivider} />
            </Box>
            <Box
              sx={{
                mt: 2,
                mb: 2,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Box
                  component={"img"}
                  src={equipmentData?.profile_pic || dummyProfile}
                  sx={{
                    border: "2px solid #fff",
                    borderRadius: "50%",
                    display: "block",
                    height: "70px",
                    maxHeight: "70px",
                    objectFit: "cover",
                    width: "70px",
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      color: "#faa61a",
                      fontSize: {
                        xs: "14px",
                        sm: "15px",
                        md: "16px",
                        lg: "17px",
                        xl: "18px",
                      },
                      textTransform: "capitalize",
                    }}
                  >
                    Owner:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "14px",
                        sm: "15px",
                        md: "16px",
                        lg: "17px",
                        xl: "18px",
                      },
                      textTransform: "capitalize",
                    }}
                  >
                    {equipmentData?.first_name}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <Box>
                  <Box sx={{ ...flexCommon, cursor: "pointer" }}>
                    <Box
                      component={"img"}
                      src={MessageIcon}
                      alt=""
                      sx={{ width: "30px", height: "30px", display: "block" }}
                    />
                    <Typography
                      sx={{
                        ...underlineYellowText,
                        // fontSize: {
                        //   xs: "12px",
                        //   sm: "12px",
                        //   md: "14px",
                        //   lg: "16px",
                        //   xl: "18px",
                        // },
                        fontSize: "clamp(12px,3vw,18px)",
                        fontFamily: "Montserrat !important",
                        fontWeight: "500",
                      }}
                      onClick={handleChatMessage}
                    >
                      Message Owner
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ ...flexCommon, cursor: "pointer" }}>
                    <Box
                      component={"img"}
                      src={RentalYardIcon}
                      alt=""
                      sx={{ width: "30px", height: "30px", display: "block" }}
                    />
                    <Link
                      href={`/share-rental-yard/rent/${params.ownerid}`}
                      sx={{
                        ...underlineYellowText,
                        // fontSize: {
                        //   xs: "12px",
                        //   sm: "12px",
                        //   md: "14px",
                        //   lg: "16px",
                        //   xl: "16px",
                        // },
                        fontSize: "clamp(12px,3vw,18px)",
                        fontFamily: "Montserrat !important",
                        fontWeight: "500",
                      }}
                    >
                      Visit Rental Yard
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* )} */}

          {/* Third Container */}
          {/* {bookingSteps === 1 && ( */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              width: {
                xs: "100%",
                sm: "100%",
                md: "45%",
                lg: "45%",
                xl: "45%",
              },
            }}
          >
            <Box>
              <Typography variant="h4" component="h4" sx={headingStyle}>
                EQUIPMENT DESCRIPTION
              </Typography>
              <Divider sx={dividerStyle} />
              <Typography sx={textStyle}>
                {equipmentData?.description || "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" component="h4" sx={headingStyle}>
                PRICING OPTIONS
              </Typography>
              <Divider sx={dividerStyle} />
              <Typography
                variant="h6"
                component="h6"
                sx={{ ...subheadingStyle, fontFamily: "Montserrat !important" }}
              >
                RENTAL RATES
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
              >
                <Box sx={rentalFlex}>
                  <Typography sx={textStyle}>Price per hour</Typography>
                  <Typography sx={textStyle}>
                    <UsCurrencyFormat
                      amount={equipmentData?.price_per_hour}
                      decimal={false}
                    />
                  </Typography>
                </Box>
                <Box sx={rentalFlex}>
                  <Typography sx={textStyle}>Price per day</Typography>
                  <Typography sx={textStyle}>
                    <UsCurrencyFormat
                      amount={equipmentData?.price_day}
                      decimal={false}
                    />
                  </Typography>
                </Box>
                <Box sx={rentalFlex}>
                  <Typography sx={textStyle}>Price per week</Typography>
                  <Typography sx={textStyle}>
                    <UsCurrencyFormat
                      amount={equipmentData?.price_per_week}
                      decimal={false}
                    />
                  </Typography>
                </Box>
                <Box sx={rentalFlex}>
                  <Typography sx={textStyle}>Price per month</Typography>
                  <Typography sx={textStyle}>
                    <UsCurrencyFormat
                      amount={equipmentData?.price_month}
                      decimal={false}
                    />
                  </Typography>
                </Box>
                <Box sx={rentalFlex}>
                  <Typography sx={textStyle}>Hours included per day</Typography>
                  <Typography sx={textStyle}>
                    {equipmentData?.included_hrs_day}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                <Typography sx={subheadingStyle}>
                  TRANSPORTATION RATES
                </Typography>
                {parseFloat(equipmentData?.owner_pickup_return) === 0 ? (
                  <Typography sx={textStyle}>
                    Renter must provide transport.
                  </Typography>
                ) : (
                  <>
                    <Typography sx={textStyle}>
                      Owner delivery available. Maximum transport range for this
                      equipment is {""}
                      <Box component={"span"} sx={{ color: "#faa61a" }}>
                        {equipmentData?.max_miles || 0} miles
                      </Box>
                      .
                      {equipmentData?.weekends == 0 &&
                      equipmentData?.sunday == 0 ? (
                        <>
                          {" Owner transport is NOT available on "}
                          <Box component={"span"} sx={{ color: "#faa61a" }}>
                            Saturday
                          </Box>
                          {" and "}
                          <Box component={"span"} sx={{ color: "#faa61a" }}>
                            Sunday
                          </Box>
                          {"."}
                        </>
                      ) : equipmentData?.weekends == 0 ? (
                        <>
                          {" Owner transport is NOT available on "}
                          <Box component={"span"} sx={{ color: "#faa61a" }}>
                            Saturday
                          </Box>
                          {"."}
                        </>
                      ) : equipmentData?.sunday == 0 ? (
                        <>
                          {"Owner transport is NOT available on "}
                          <Box component={"span"} sx={{ color: "#faa61a" }}>
                            Saturday
                          </Box>
                          {"."}
                        </>
                      ) : (
                        <></>
                      )}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Box sx={rentalFlex}>
                        <Typography sx={textStyle}>
                          Min. transport charges (for round trip)
                        </Typography>
                        <Typography sx={textStyle}>
                          <UsCurrencyFormat
                            amount={equipmentData?.minimum_delivery_charges}
                            decimal={false}
                          />
                        </Typography>
                      </Box>
                      <Box sx={rentalFlex}>
                        <Typography sx={textStyle}>
                          Per-mile charge begins after
                        </Typography>
                        <Typography sx={textStyle}>
                          {equipmentData?.free_delivery_miles || 0} mi
                        </Typography>
                      </Box>
                      <Box sx={rentalFlex}>
                        <Typography sx={textStyle}>Per mile rate</Typography>
                        <Typography sx={textStyle}>
                          <UsCurrencyFormat
                            amount={equipmentData?.additional_miles_fee}
                            decimal={false}
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box>
              <Typography variant="h4" component="h4" sx={headingStyle}>
                DELIVERY AND PICKUP
              </Typography>
              <Divider sx={dividerStyle} />
              <Typography sx={textStyle}>
                Delivery and pickup is available for this item and if selected
                will be calculated upon checkout.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" component="h4" sx={headingStyle}>
                DAMAGE & LIABILITY COVERAGE
              </Typography>
              <Divider sx={dividerStyle} />
              <Typography sx={textStyle}>
                The party in possession of the equipment is responsible for
                damage to any other person or property while the equipment is in
                their possession
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  mt: 1,
                }}
              >
                <Box
                  component={"img"}
                  src={EquipProtection}
                  alt=""
                  sx={{ width: "50px", height: "auto", objectFit: "contain" }}
                />

                <Box sx={textStyle}>
                  $50,000 incidental equipment damage coverage included.
                  <Link
                    href="/terms_and_conditions"
                    target="_blank"
                    sx={{
                      color: "#faa61a",
                      textDecorationColor: "#faa61a",
                      textDecorationLine: "underline",
                      ml: "5px",
                    }}
                  >
                    See terms and conditions
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography variant="h4" component="h4" sx={headingStyle}>
                MAINTENANCE & SERVICE
              </Typography>
              <Divider sx={dividerStyle} />
              <Typography sx={textStyle}>No data provided.</Typography>
            </Box>
            <Box>
              <Typography variant="h4" component="h4" sx={headingStyle}>
                CANCELLATION
              </Typography>
              <Divider sx={dividerStyle} />
              <Typography sx={textStyle}>
                Either party can cancel without penalty up to 24 hours prior to
                rental start time.
              </Typography>
            </Box>
          </Box>
          {/* )} */}

          {/* fourth container */}
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "45%",
                lg: "45%",
                xl: "45%",
              },
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              position: {
                xs: "unset",
                sm: "unset",
                md: "sticky",
                lg: "sticky",
                xl: "sticky",
              },
              top: {
                xs: "unset",
                sm: "unset",
                md: "7rem",
                lg: "7rem",
                xl: "7rem",
              },
              marginTop: {
                xs: "0px",
                sm: "0px",
                md: "-65px",
                lg: "-220px",
                xl: "-200px",
              },
            }}
          >
            <RentalSummary
              timeZoneAbbr={timeZoneAbbr}
              equipRentedDates={equipRentedDates}
              setEquipRentedDates={setEquipRentedDates}
              equipRentedTimes={equipRentedTimes}
              setEquipRentedTimes={setEquipRentedTimes}
              minDateTime={minDateTime}
              priceDetails={priceDetails}
              DeliveryInstructionsval={DeliveryInstructionsval}
              DeliveryInstructionsvallength={DeliveryInstructionsvallength}
              DeliveryInstructions={DeliveryInstructions}
              calculateDistance={calculateDistance}
              transportCharge={transportCharge}
              handleTransportSelection={handleTransportSelection}
              chooseTransport={chooseTransport}
              bookingSteps={bookingSteps}
              setBookingSteps={setBookingSteps}
              FullAddress={FullAddress}
              deliveryError={deliveryError}
              equipmentData={equipmentData}
              submitRentalRequest={submitRentalRequest}
              handleAddBalancePopupOpen={handleAddBalancePopupOpen}
              setCompleteProfilePopup={setCompleteProfilePopup}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              calendarRef={calendarRef}
              transportOption={transportOption}
              setTransportOption={setTransportOption}
            />
          </Box>
        </Box>
      )}
      {/* The below box contain calender */}
      {/* {bookingSteps === 1 && (
        <Box
          sx={{
            ...headingStyle,
            mt: 2,
            mb: 1,
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <Box
            sx={{
              mt: 6,
              mb: 6,
              boxSizing: "border-box",
              borderRadius: "10px",
              border: "2px solid gray",
              background: "transparent",
              p: 1,
              display: "flex",
              alignItems: "flex-start",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {!equipRentedDates[0] ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 1,
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "GothamBold !important",
                      color: "#faa61a",
                      fontWeight: "600",
                      fontSize: {
                        xs: "1rem",
                        sm: "1rem",
                        md: "1.1rem",
                        lg: "1.2rem",
                        xl: "1.3rem",
                      },
                    }}
                  >
                    Select Pick Up Date
                  </Typography>
                </Box>
              ) : !equipRentedDates[1] ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 1,
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "GothamBold !important",
                      color: "#faa61a",
                      fontWeight: "600",
                      fontSize: {
                        xs: "1rem",
                        sm: "1rem",
                        md: "1.1rem",
                        lg: "1.2rem",
                        xl: "1.3rem",
                      },
                    }}
                  >
                    Select Drop Off Date
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 1,
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontFamily: "GothamBold !important",
                        color: "#faa61a",
                        fontWeight: "600",
                        fontSize: {
                          xs: "1rem",
                          sm: "1rem",
                          md: "1.1rem",
                          lg: "1.2rem",
                          xl: "1.3rem",
                        },
                      }}
                    >
                      Selected Dates
                    </Typography>
                  </Box>
                </>
              )}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                      mb: "5px",
                      fontWeight: "500",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    Pick Up Date
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      p: "5px",
                      border: "1px solid #5a5a52",
                      fontWeight: "700",
                      fontFamily: "Montserrat !important",
                      borderRadius: 2,
                    }}
                  >
                    {equipRentedDates[0]
                      ? equipRentedDates[0]?.format("MM/DD/YY")
                      : "MM/DD/YY"}
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                      mb: "5px",
                      fontWeight: "500",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    Drop Off Date
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      p: "5px",
                      border: "1px solid #5a5a52",
                      fontWeight: "700",
                      fontFamily: "Montserrat !important",
                      borderRadius: 2,
                    }}
                  >
                    {equipRentedDates[1]
                      ? equipRentedDates[1]?.format("MM/DD/YY")
                      : "MM/DD/YY"}
                  </Typography>
                </Box>
              </Box>
              <CustomCalendar
                equipRentedTimes={equipRentedTimes}
                equipRentedDates={equipRentedDates}
                setEquipRentedDates={setEquipRentedDates}
                minDateTime={minDateTime}
                setEquipRentedTimes={setEquipRentedTimes}
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
                calendarRef={calendarRef}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", sm: "1fr", md: "1fr 1fr" },
                  gap: "1rem",
                  boxSizing: "border-box",
                  width: "100%",
                  flex: "1",
                }}
              >
                <CustomCalendarTime
                  label={"Select Pickup Time"}
                  timeZoneAbbr={timeZoneAbbr}
                  equipRentedTimes={equipRentedTimes}
                  setEquipRentedTimes={setEquipRentedTimes}
                  minDateTime={minDateTime}
                  equipRentedDates={equipRentedDates}
                />
                <CustomCalendarTime
                  label={"Select Drop-off Time"}
                  timeZoneAbbr={timeZoneAbbr}
                  equipRentedTimes={equipRentedTimes}
                  setEquipRentedTimes={setEquipRentedTimes}
                  minDateTime={minDateTime}
                  equipRentedDates={equipRentedDates}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color: "#faa61a",
                    fontFamily: "Montserrat !important",
                    fontWeight: "700",
                    textDecoration: "underline",
                    textDecorationColor: "#faa61a",
                    textTransform: "capitalize",
                    fontSize: "20px",
                    "&:hover": {
                      background: "none",
                      textDecoration: "underline",
                      textDecorationColor: "#faa61a",
                      textTransform: "capitalize",
                    },
                  }}
                  onClick={() => {
                    setEquipRentedTimes({
                      startTime: null,
                      endTime: null,
                    });
                    setEquipRentedDates([null, null]);
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )} */}

      {bookingSteps === 2 && (
        <RentalSummary2
          timeZoneAbbr={timeZoneAbbr}
          equipRentedDates={equipRentedDates}
          equipRentedTimes={equipRentedTimes}
          priceDetails={priceDetails}
          bookingSteps={bookingSteps}
          setBookingSteps={setBookingSteps}
          submitRentalRequest={submitRentalRequest}
          handleAddBalancePopupOpen={handleAddBalancePopupOpen}
          WalletBalance={WalletBalance}
        />
      )}

      <Modal
        open={addBalanceBox}
        onClose={handleAddBalancePopupClose}
        className="addmoney-modal"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            width: {
              xs: "98%",
              sm: "450px",
              md: "550px",
              lg: "550px",
              xl: "550px",
            },
            boxSizing: "border-box",
          }}
        >
          <IconButton
            onClick={handleAddBalancePopupClose}
            sx={{
              position: "absolute",
              top: "-20px",
              right: {
                xs: "-5px",
                sm: "-20px",
                md: "-20px",
                lg: "-20px",
                xl: "-20px",
              },
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box>
            <Box
              sx={{
                width: "100%",
                maxHeight: "700px",
                overflowY: "auto",
                p: 4,
                boxSizing: "border-box",
              }}
            >
              <Typography
                variant="h5"
                style={{ color: "#faa61a", marginBottom: "0.5rem" }}
              >
                Add Funds to Wallet
              </Typography>

              <AddBalance
                onSuccess={(e) => {
                  if (e == "success") {
                    getUserProfile(AuthUserId);
                    handleAddBalancePopupClose();
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal open={payWithCard} className="addmoney-modal">
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            width: {
              xs: "98%",
              sm: "450px",
              md: "550px",
              lg: "550px",
              xl: "550px",
            },
            boxSizing: "border-box",
          }}
        >
          <IconButton
            onClick={handlePayWithCardClose}
            sx={{
              position: "absolute",
              top: "-20px",
              right: {
                xs: "5px",
                sm: "-20px",
                md: "-20px",
                lg: "-20px",
                xl: "-20px",
              },
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box>
            <Box
              sx={{
                width: "100%",
                maxHeight: "700px",
                overflowY: "auto",
                p: 4,
                boxSizing: "border-box",
              }}
            >
              <Typography
                variant="h5"
                style={{ color: "#faa61a", marginBottom: "0.5rem" }}
              >
                Pay With Card
              </Typography>

              <PaymentHold
                amount={holdAmount}
                onSuccess={async (e) => {
                  if (e.status == "success") {
                    HandleCardHoldPayment(e);
                  }
                }}
              />
            </Box>
            {/* </Box> */}
          </Box>
        </Box>
      </Modal>

      <Box>
        <Dialog
          open={orderSuccessPopup}
          aria-labelledby="draggable-dialog-title"
        >
          <Box className="session-expire">
            <DialogContent>
              <DialogContentText style={{ color: "#fff", fontSize: "20px" }}>
                Thank you, you will not be charged until the owner of this
                equipment approves your rental request. Keep an eye on your
                email for confirmation. Also, please reach out to the owner
                directly at this
                <Box component={"span"}
                  sx={{
                    textDecoration: "underline",
                    color: "#fba618",
                    cursor: "pointer",
                    marginLeft: "7px",
                  }}
                  onClick={handleChatMessage}
                >
                  link
                </Box>{" "}
                with questions. Make sure you have your notifications turned on in your GoEquipMe settings.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className=""
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#2e7d32 !important",
                  color: "#fff !important",
                  padding: "6px 50px 6px 50px",
                  borderRadius: "7px",
                  fontFamily: "GothamBold !important",
                  fontSize: "20px !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (requestedOrderId) {
                    window.location.href = `/equipmentOrders/${requestedOrderId}/advance`;
                  } else {
                    window.location.href = "/";
                  }
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};

export default EquipDetailsRent;
