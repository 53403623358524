import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Popover,
  Modal,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dummyImg from "../../../assets/images/jcb.jpg";
import moment from "moment";
import { useSelector } from "react-redux";
import { IStoreValue, RentalItem } from "utils/types";
import gemArrow from "assets/images/gem-arrow.png";
import { convertTimeZoneShortName } from "utils/commonFxns";
import momentTimezone from "moment-timezone";
import UsCurrencyFormat from "./UsCurrencyFormat";
const PastRentals = ({
  handleDetailModalOpen,
  pastRentalsData,
  handleDetailModalData,
  handlePagination,
  loadMore,
  pageNo,
  setPageNo,
  handleRentalYardRedirection,
}) => {
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  // const [pageNo, setPageNo] = useState<number>(1);
  const HandleRentAgain = (ActiveEquip: any) => {
    window.location.href =
      "/detail/" + ActiveEquip["owner_id"] + "/" + ActiveEquip["equip_id"];
  };

  const [popOverOpen, setPopOverOpen] = useState<boolean>(false);
  const [equipUser,setEquipUser] = useState<number>(null);

  const handlePopOverOpen = () => setPopOverOpen(true);

  const handlePopOverClose = () => setPopOverOpen(false);
  

  return (
    Object.entries(pastRentalsData).length > 0 &&
    pastRentalsData.data.length > 0 && (
      <Box>
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: "#faa61a !important",
              fontFamily: "GothamBold !important",
              mb: 1,
              mt: 2,
              fontSize: {
                xs: "20px",
                sm: "26px",
                md: "32px",
                lg: "36px",
                xl: "40px",
              },
              textTransform: "uppercase",
            }}
          >
            {pastRentalsData?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)",
              gap: "1.5rem",
              alignItems: "center",
            },
          }}
        >
          {pastRentalsData.data.length > 0 &&
            pastRentalsData.data.map((element: RentalItem) => (
              <Box
                sx={{
                  background: "#484840",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                  padding: "1rem",
                }}
                key={element?.order_id}
              >
                {/* Image Section */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                  >
                    <Box
                      component={"img"}
                      src={element?.thumbnail}
                      sx={{
                        width: {
                          xs: "55px",
                          sm: "70px",
                          md: "75px",
                          lg: "80px",
                          xl: "85px",
                        },
                        height: {
                          xs: "45px",
                          sm: "60px",
                          md: "65px",
                          lg: "70px",
                          xl: "75px",
                        },
                        objectFit: "cover",
                        borderRadius: "5px",
                        border: "2px solid #faa61a",
                        boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.5)",
                        cursor:
                          AuthUserId === element?.owner_id
                            ? "pointer"
                            : "default",
                      }}
                      onClick={() => {
                        if (AuthUserId === element?.owner_id) {
                          handleRentalYardRedirection(element?.equip_id);
                        }
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#faa61a",
                          fontWeight: "700",
                          fontSize: {
                            xs: "14px",
                            sm: "18px",
                            md: "20px",
                            lg: "23px",
                            xl: "24px",
                          },
                          fontFamily: "Montserrat !important",
                          cursor:
                            AuthUserId === element?.owner_id
                              ? "pointer"
                              : "default",
                        }}
                        onClick={() => {
                          if (AuthUserId === element?.owner_id) {
                            handleRentalYardRedirection(element?.equip_id);
                          }
                        }}
                      >
                        {element?.make
                          ? element?.make.length > 15
                            ? element?.make.slice(0, 15) + "..."
                            : element?.make
                          : "N/A"}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        Order ID #{element?.order_id}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#faa61a",
                          textDecorationLine: "underline",
                          textDecorationColor: "#faa61a",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "13px",
                            xl: "14px",
                          },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleDetailModalData(element, "past");
                          handleDetailModalOpen();
                        }}
                      >
                        Rental Details
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          element?.order_status !== "completed"
                            ? "#faa61a"
                            : "#fff",
                        textDecoration:
                          element?.order_status !== "completed"
                            ? "underline"
                            : "none",
                        cursor:
                          element?.order_status !== "completed"
                            ? "pointer"
                            : "default",
                        fontFamily: "Montserrat !important",
                        fontSize: {
                          xs: "12px",
                          sm: "15px",
                          md: "16px",
                          lg: "17px",
                          xl: "18px",
                        },
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        if (element?.order_status !== "completed") {
                          setEquipUser(element?.owner_id);
                          handlePopOverOpen();
                        }
                      }}
                    >
                      {element?.order_status !== "completed"
                        ? "Pending"
                        : AuthUserId === element?.owner_id
                        ? "You Earned"
                        : "Spent"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontSize: {
                          xs: "15px",
                          sm: "20px",
                          md: "22px",
                          lg: "24px",
                          xl: "26px",
                        },
                        fontWeight: "700",
                      }}
                    >
                      <UsCurrencyFormat amount={element?.total_price} />
                    </Typography>
                  </Box>
                </Box>

                {/* Detail Section */}
                <Box
                  sx={{
                    // display: "grid",
                    // gridTemplateColumns: "1fr 1fr",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      paddingLeft: "0rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        Start:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        {/* {moment(
                          `${element?.start_date} ${element?.start_time}`
                        ).format("MM/DD hh:mm a")} */}
                        {element?.timezone
                          ? convertTimeZoneShortName(
                              momentTimezone
                                .tz(
                                  `${element.start_date} ${element.start_time}`,
                                  element?.timezone
                                )
                                .format("MM/DD hh:mm a (z)")
                            )
                          : moment(
                              `${element.start_date} ${element.start_time}`
                            ).format("MM/DD hh:mm a (UTC)")}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        End:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        {/* {moment(
                          `${element.end_date} ${element.end_time}`
                        ).format("MM/DD hh:mm a")} */}
                        {element?.timezone
                          ? convertTimeZoneShortName(
                              momentTimezone
                                .tz(
                                  `${element.end_date} ${element.end_time}`,
                                  element?.timezone
                                )
                                .format("MM/DD hh:mm a (z)")
                            )
                          : moment(
                              `${element.end_date} ${element.end_time}`
                            ).format("MM/DD hh:mm a (UTC)")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      sx={{
                        // border:AuthUserId === element?.owner_id? "3px solid #008001":"none",
                        background:
                          AuthUserId === element?.owner_id ? "none" : "#008001",
                        color: "#fff",
                        textTransform: "capitalize",
                        fontFamily: "Montserrat !important",
                        borderRadius: "8px",
                        fontWeight: "800",
                        padding: "7px 15px",
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                          lg: "16px",
                          xl: "18px",
                        },
                        cursor:
                          AuthUserId === element?.owner_id
                            ? "default"
                            : "pointer",
                        "&:hover": {
                          background:
                            AuthUserId === element?.owner_id
                              ? "none"
                              : "#006001",
                        },
                      }}
                      onClick={() => {
                        if (AuthUserId !== element?.owner_id) {
                          HandleRentAgain(element);
                        }
                      }}
                    >
                      {AuthUserId === element?.owner_id
                        ? element?.order_status !== "completed"
                          ? ""
                          : "Rental Complete!"
                        : "Rent Again!"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
        {pastRentalsData.data.length === 0 && (
          <Box
            sx={{
              width: "100%",
              padding: "2rem 1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "15px",
                  lg: "16px",
                  xl: "18px",
                },
                fontFamily: "Montserrat !important",
                fontWeight: "800",
              }}
            >
              No Records Found!
            </Typography>
          </Box>
        )}
        {pastRentalsData?.total > pastRentalsData?.data?.length && (
          <Box className="pagination-section">
            {loadMore ? (
              <CircularProgress />
            ) : (
              <Box
                className="btn-load-more"
                onClick={() => {
                  setPageNo((prev) => prev + 1);
                  handlePagination("PAST RENTALS", pageNo);
                }}
              >
                {"Load More"}
                <img
                  src={gemArrow}
                  alt="GoEquipMe"
                  className="gem-arrow arrow-load-more"
                />
              </Box>
            )}
          </Box>
        )}
        <Modal
          open={popOverOpen}
          onClose={handlePopOverClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: {
                xs: "80%",
                sm: "400px",
                md: "450px",
                lg: "450px",
                xl: "450px",
              },
              bgcolor: "#2e2e2e",
              border: "5px solid #faa61a",
              borderRadius: "10px",
              outline: "none !important",
              p: 3,
            }}
          >
            <IconButton
              onClick={handlePopOverClose}
              sx={{
                position: "absolute",
                top: "-20px",
                right: "-20px",
                bgcolor: "#faa61a",
                color: "#000",
                "&:hover": {
                  bgcolor: "#faa61a",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat !important",
                  fontWeight: "800",
                  fontSize: {
                    xs: "13px",
                    sm: "14px",
                    md: "14px",
                    lg: "15px",
                    xl: "16px",
                  },
                  mb: 2,
                  color: "#fff",
                }}
              >
                {AuthUserId === equipUser ? "Renters":"You"} have 48 hours to dispute partial refunds.
              </Typography>
              <Button
                sx={{
                  borderRadius: "8px",
                  bgcolor: "#149247",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat !important",
                  fontWeight: "800",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  fontSize: {
                    xs: "13px",
                    sm: "14px",
                    md: "14px",
                    lg: "15px",
                    xl: "16px",
                  },
                  color: "#fff",
                  "&:hover": {
                    background: "#006001",
                  },
                }}
                onClick={handlePopOverClose}
              >
                Ok
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    )
  );
};

export default PastRentals;
