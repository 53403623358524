import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Collapse,
  Fade,
  Grow,
  Slide,
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  Pagination,
  FormControl,
  Link,
  CircularProgress,
  Modal,
  IconButton,
  useMediaQuery,
  styled,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  FormGroup,
  Checkbox,
  Slider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setalertMessagetype,
  setalertMessage,
  setEquipListType,
  setFromHowItWorks,
} from "redux/redux-slice";
import {
  IApiResponseRawData,
  IEquipment,
  IStore,
  IStoreValue,
} from "utils/types";
import {
  setCategories,
  setCount,
  setEquipments,
  setLoadingEquipments,
} from "redux/redux-slice";
import { EquipmentAPIs } from "service/api-service";
import debounce from "lodash.debounce";
import ClearIcon from "@mui/icons-material/Clear";

//import whiteMark from 'assets/images/white-mark1.png'
import Filtericon from "assets/images/filter.png";
import searchFormBorderLeft from "assets/images/grunge_frame_left.png";
import searchFormBorderRight from "assets/images/grunge_frame_right.png";
import CalendarImg from "assets/images/details-page/calendar_two.png";
import gemArrow from "assets/images/gem-arrow.png";
import "./styles.scss";
//import PlaceComponent from "../place.component";
import EquipmentsView from "../equipments-view/EquipmentsView"; //components/equipments-view/EquipmentsView
import { Label, Star, Swipe } from "@mui/icons-material";
import SwiperCarousel from "components/swipercarousel/SwiperCarousel";

import CloseIcon from "@mui/icons-material/Close";
import { Calendar as NewCalendar } from "react-multi-date-picker";
import moment from "moment";
import "../../pages/equipment-detail/calendar.scss";
import NumericInput from "material-ui-numeric-input";

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const NewCssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});

const NewCssNumericInput = styled(NumericInput)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#2d2d2d",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(46 46 46)", //background.paper
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

var _lat = "";
var _long = "";

var _currentlat = "";
var _currentlng = "";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const searchInputProp = {
  disableUnderline: true,
  style: {
    border: "3px solid #faa61a",
    borderRadius: "3px",
    background: "white",
    height: "45px",
  },
};

const inputLabelProp = {
  style: {
    color: "#7f8084",
  },
};

const SearchSection = () => {
  const dispatch = useDispatch();
  const [showResult, setShowResult] = useState(false);
  const matchesMobileX = useMediaQuery("(max-width:425px)");

  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [currentlat, setCurrentlat] = useState(null);
  const [currentlng, setCurrentlng] = useState(null);
  const [locations, setLocations] = useState<Array<string>>([]);

  const inRef = useRef(null);
  const [page, setPage] = React.useState(0);
  const [pagelimit, setPageLimit] = React.useState(15);
  const [loadmore, setLoadmore] = React.useState(true);

  const currentPage = useSelector(
    (state: IStoreValue) => state.equipmentReducer.currentPage
  );
  const equipments = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipments
  );
  const pagecount = useSelector(
    (state: IStoreValue) => state.equipmentReducer.count
  );
  // by GD 24 Jan 2024 for remember equiplist type
  // const equipListType = useSelector((state: IStoreValue) => state.equipmentReducer.equipListType);
  // console.log("equipListType in header: " , equipListType);

  // by GD 4 march 2024 for filters working from howItWorks page
  const equipFilters = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipFilters
  );
  const fromHowItWorks = useSelector(
    (state: IStoreValue) => state.equipmentReducer.fromHowItWorks
  );
  const seeResultRef = useRef(null);
  const seeResult2Ref = useRef(null);
  // GD end

  const [Keyword, setKeyword] = useState("");
  const [searchApplied, setSearchApplied] = useState(false);
  // Filter Popup states
  const [FilterPopUp, setFilterPopUp] = useState(false);
  const [State, setState] = useState("");
  const [StateLabel, setStateLabel] = useState("");
  const [City, setCity] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [locationRange, setLocationRange] = useState("");
  const [category, setCategory] = useState("");

  const [StateList, setStateList] = useState([]);
  const [StateListLoading, setStateListLoading] = useState(false);
  const [CityList, setCityList] = useState([]);
  const [CityListLoading, setCityListLoading] = useState(false);

  const [CalendarPopup, setCalendarPopup] = useState(false);
  const [values, setValues] = useState([]);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  const [FilterType, setFilterType] = useState("");
  const [FilterTagReset, setFilterTagReset] = useState("");
  const [FilterTagData, setFilterTagData] = useState({
    State: "",
    City: "",
    ZipCode: "",
    StartDate: "",
    EndDate: "",
    category: "",
    locationRange: "",
  });

  // Location range disable attribute
  const [RangeValidate, setRangeValidate] = useState(true);
  const [SearchResults, setSearchResults] = useState(false);

  // by GD to 24 Jan 2024 to remember the filter selected by user
  // used local storage due to redux not working . because on every link the site is reloading thus redux states set to default
  let equipFilterType = localStorage.getItem("equipFilterType");
  const [ListEquipType, setListEquipType] = useState(
    equipFilterType ? equipFilterType : "rent"
  );
  //end by GD 24 Jan 2024

  // const [ListEquipType, setListEquipType] = useState('rent')
  const [APIStatus, setAPIStatus] = useState(false);
  const [Loadtype, setLoadtype] = useState("default");

  // by GD 15Dec for sale and radio buttons disable enable on api loading
  const [radioDisabled, setRadioDisabled] = useState(true);
  // end by GD
  const handleChange = (event, value) => {
    dispatch(setCurrentPage(value));
    setPage(value);
    setTimeout(() => {
      // window.scrollTo({
      //   top: 400,
      //   behavior: 'smooth',
      // });
    }, 300);
  };

  const handleChangeLimit = (event) => {
    setPageLimit(event.target.value);
    setPage(1);
    searchEquipments(event.target.value, null, _lat, _long, 1);
  };

  /**
   * Function: searchEquipments
   * Description:
   * Handles the search functionality for equipment based on the provided parameters.
   * Retrieves equipment data from the server based on the specified search criteria.
   * Updates the global state with the fetched equipment data and count.
   *
   * @param {number} limit - The maximum number of results to fetch.
   * @param {string|null} cat - The category to filter the search results (null for all categories).
   * @param {number|null} lati - The latitude for location-based filtering (null for no location filter).
   * @param {number|null} lngi - The longitude for location-based filtering (null for no location filter).
   * @param {number} page_number - The page number for paginated results.
   */
  // call on simple filter
  const searchEquipments = useCallback(
    async (
      limit = 15,
      cat = null,
      lati = null,
      lngi = null,
      page_number = 0
    ) => {
      try {
        /************ */
        // Check if there is an authenticated user
        let auth_id = null;
        let auth_user = localStorage.getItem("token-info");
        if (auth_user != "undefined") {
          auth_user = JSON.parse(auth_user);
          if (auth_user != null && auth_user) {
            auth_id = auth_user["user_id"];
          }
        }
        /************ */
        // Prepare parameters for the equipment search
        let perms = {
          user_id: auth_id,
          share_user_id: "",
          search: Keyword,
          category: "",
          page: page_number,
        };

        // Fetch equipment data from the server
        const equipments = await EquipmentAPIs.ListEquip(perms);

        // Update global state with fetched equipment data and count
        dispatch(setEquipments(equipments.data));
        dispatch(setCount(equipments.total));
        setLoadmore(false);
      } catch (err) {
        console.log(err);
        dispatch(setLoadingEquipments(false));
      }
      setShowResult(true);
    },
    [Keyword]
  );

  /**
   * Function: EquipFilter
   * Description:
   * Handles the filtering of equipment based on the provided filter criteria.
   * Retrieves equipment data from the server based on the specified filter parameters.
   * Updates the global state with the fetched equipment data and count.
   *
   * @param {Object} ObjData - The object containing filter parameters.
   *                           (Assuming ObjData includes the necessary properties for filtering)
   */
  // Call on select filter options
  const EquipFilter = useCallback(
    async (ObjData) => {
      try {
        // Fetch equipment data from the server based on filter criteria
        const equipments = await EquipmentAPIs.GetFilterEquip(ObjData);
        //console.log('equipments check', equipments);

        // Update global state with fetched equipment data and count
        dispatch(setEquipments(equipments.data));
        dispatch(setCount(equipments.total));
        setLoadmore(false);
      } catch (err) {
        console.log(err);
        dispatch(setLoadingEquipments(false));
      }

      // Set flag to indicate that results should be displayed
      setShowResult(true);
    },
    [Keyword]
  );

  const categories = useSelector(
    (state: IStoreValue) => state.equipmentReducer.categories
  );

  /**
   * Function: searchReset
   * Description:
   * Resets the search parameters and initiates a new search for equipment based on default criteria.
   * Clears the search input, location details, category, price range, and location range.
   * Handles both successful and unsuccessful scenarios, updating the global state accordingly.
   *
   * @param {number} limit - The maximum number of equipment items to retrieve (optional).
   */
  const searchReset = useCallback(
    async (limit = null) => {
      try {
        // Reset input fields and variables
        inRef.current.value = "";
        _lat = null;
        _long = null;
        setCategory("");
        setMinPrice(0);
        setMaxPrice(0);
        setLocationRange("");

        // Dispatch action to indicate loading state
        dispatch(setLoadingEquipments(true));

        /************ */
        // Get user ID from local storage
        let auth_id = null;
        let auth_user = localStorage.getItem("token-info");
        if (auth_user != "undefined") {
          auth_user = JSON.parse(auth_user);
          if (auth_user != null && auth_user) {
            auth_id = auth_user["user_id"];
          }
        }
        /************ */

        // Fetch near equipments with default criteria
        const equipments: IApiResponseRawData =
          await EquipmentAPIs.getNearEquipments({
            category: null,
            zipcode: 0,
            lat: _currentlat,
            lng: _currentlng,
            limit: limit,
            locationRange: 20000,
            page: 1,
            type: "default",
            user_id: auth_id,
          });

        // Update global state with the retrieved equipment data
        dispatch(setCount(equipments.data.rowCount));
        dispatch(setEquipments(equipments.data.rows as Array<IEquipment>));

        // Dispatch action to indicate the end of the loading state
        dispatch(setLoadingEquipments(false));

        // Reset the page number to 1
        setPage(1);
      } catch (err) {
        // Handle any unexpected errors
        console.log(err);
        dispatch(setLoadingEquipments(false));
      }
      // Set flag to show search results
      setShowResult(true);
    },
    [category, dispatch, locationRange, maxPrice, minPrice, ZipCode]
  );

  /**
   * Function: _getlocation
   * Description:
   * Attempts to retrieve the user's current geolocation using the browser's geolocation API.
   * Initiates a search for nearby equipment based on the obtained latitude and longitude.
   * Handles both successful and unsuccessful scenarios, updating the global state accordingly.
   */
  // below getlocation function is commented by pp on 19 apr 2024 due no longer need to user asks location
  // const _getlocation = useCallback(async () => {

  //   try {
  //     // Attempt to retrieve the user's current geolocation
  //     navigator.geolocation.getCurrentPosition(
  //       function (position) {

  //         // Dispatch action to clear existing equipment data
  //         // dispatch(setEquipments([]))
  //         dispatch(setEquipments([] as Array<IEquipment>))
  //         // Set obtained latitude and longitude
  //         setLat(position.coords.latitude)
  //         setLng(position.coords.longitude)
  //         //setFilterType('NearEquip')
  //         // console.log("lat long set Done: " , position.coords.latitude , " : " , position.coords.longitude);
  //         const fromFindPageLocal = localStorage.getItem("fromFindPageLocal");
  //         if(fromFindPageLocal && fromFindPageLocal == 'yes') return;
  //         // Initiate search for nearby equipment
  //         searchFilter('', position.coords.latitude, position.coords.longitude);
  //         //searchEquipmentsV2(pagelimit, null, position.coords.latitude, position.coords.longitude, 1)
  //       },
  //       function (error) { //not access permission
  //         const fromFindPageLocal = localStorage.getItem("fromFindPageLocal");
  //         if(fromFindPageLocal && fromFindPageLocal == 'yes') return;
  //         searchFilter()
  //           //alert('location');
  //           // console.error("Error Code = " + error.code + " - " + error.message);
  //           //dispatch(setEquipments([] as Array<IEquipment>))
  //           // searchEquipments(pagelimit)
  //           ;
  //       }
  //     )
  //   } catch (err) {

  //     // console.error("catch Error Code = " + err);
  //     dispatch(setEquipments([]))
  //     // searchEquipments(pagelimit)
  //   }
  // }, [])

  /**
   * useEffect Hook:
   * Reset Page and Equipment List on Category Change
   * Description:
   * This useEffect hook is triggered when the 'category' state changes.
   * It resets the page number and clears the existing equipment list in preparation for a new search
   * based on the updated category. The 'searchFilter' function is then called to initiate the new search.
   */

  // below useEffect commented by pp on 17 apr 2024 due to category filter is moved to filter popup
  // useEffect(() => {
  //   setPage(0);
  //   dispatch(setEquipments([]));
  //   // if(fromHowItWorks) return;
  //   const fromFindPageLocal = localStorage.getItem("fromFindPageLocal");
  //   if(fromFindPageLocal && fromFindPageLocal == 'yes') return;
  //   console.log("first useEffect runs");
  //   // by GD added 'Filter' in searchFilter due to error while search with Zipcode
  //   if(ZipCode != '' || StartDate != '' || EndDate != '' || locationRange != ''){
  //     searchFilter('Filter');
  //     return;
  //   }
  //   // end by GD
  //   searchFilter();
  // }, [category]);

  /**
   * useEffect Hook:
   * Trigger Search on Page Change
   * Description:
   * This useEffect hook is triggered whenever the 'page' state changes.
   * It checks if the page is not zero and, if true, sets 'loadmore' to true and initiates a search
   * using the 'searchFilter' function with the specified 'FilterType'.
   */
  useEffect(() => {
    if (page != 0) {
      setLoadmore(true);
      searchFilter(FilterType);
    }
  }, [page]);

  /**
   * useEffect Hook:
   * Trigger Search on Filter Tag Reset
   * Description:
   * This useEffect hook is triggered whenever the 'FilterTagReset' state changes.
   * If 'FilterTagReset' is not an empty string, it initiates a search using the 'searchFilter' function.
   * It considers the current filter type ('FilterType') and resets it if certain location-related filters (State, City, ZipCode, locationRange)
   * and date-related filters (StartDate, EndDate) are empty, ensuring an appropriate search is performed.
   * Additionally, it sets 'loadmore' to true and clears the existing equipments by dispatching 'setEquipments([])'.
   */
  useEffect(() => {
    if (FilterTagReset != "") {
      console.log("filter reset useEffect running");
      let typee = FilterType;
      if (
        State == "" &&
        City == "" &&
        ZipCode == "" &&
        locationRange == "" &&
        StartDate == "" &&
        EndDate == ""
      ) {
        typee = "";
      }
      // startDate: StartDate,
      // endDate: EndDate,
      setLoadmore(true);
      dispatch(setEquipments([]));
      searchFilter(typee, "", "", 0);
    }
  }, [FilterTagReset]);

  /**
   * useEffect Hook:
   * Fetch Categories on Component Mount
   * Description:
   * This useEffect hook is triggered on component mount.
   * It checks if the 'categories' state is empty. If true, it initiates an asynchronous function 'getCategories'
   * to fetch categories using the 'getAllCategories' API from EquipmentAPIs.
   * Once the categories are fetched, it dispatches 'setCategories' to update the state with the retrieved categories.
   * The loading state is managed using 'setLoadingEquipments' to indicate whether the categories are being loaded.
   */
  useEffect(() => {
    const getCategories = async (): Promise<void> => {
      try {
        dispatch(setLoadingEquipments(true));
        const categoriesRes: IApiResponseRawData =
          await EquipmentAPIs.getAllCategories();
        const filteredCategory = categoriesRes?.data?.rows?.filter(
          (cat: string) => cat?.toLowerCase() !== "private"
        );
        dispatch(setCategories(filteredCategory as Array<string>));
        // dispatch(setCategories(categoriesRes.data.rows as Array<string>))
        dispatch(setLoadingEquipments(false));
      } catch (err) {
        console.log("Get Categories Error : ", err);
      }
    };
    if (categories.length === 0) {
      getCategories();
    }
  }, [categories.length, dispatch]);

  /**
   * useEffect Hook:
   * Initialize Component and Fetch Initial Data
   * Description:
   * This useEffect hook is triggered on component mount.
   * It performs the following tasks:
   * 1. Fetches states using the 'getStates' function.
   * 2. Fetches city lists using the 'getAllCityList' function.
   * 3. Dispatches 'setEquipments([])' to clear the existing equipment data.
   * 4. Initiates a timeout to wait for 500 milliseconds before executing the next steps.
   * 5. Resets 'FilterType' to an empty string.
   * 6. Calls the '_getlocation' function to fetch the user's current location.
   * 7. Scrolls the window to the top.
   * 8. Returns a cleanup function to clear the timeout when the component is unmounted.
   */
  useEffect(() => {
    // console.log("working on this");

    // getStates()
    // getAllCityList()
    dispatch(setEquipments([]));
    searchFilter();
    let timeOut = setTimeout(() => {
      setFilterType("");
      // _getlocation()
    }, 500);
    window.scrollTo(0, 0);
    return () => clearTimeout(timeOut);
  }, []);

  // set Category value on category select
  const onCategoryChange = useCallback((event) => {
    let val = event.target.value;
    //setCity('')
    if (val != "") {
      setPage(0);
      setCategory(val);
    } else {
      setPage(0);
      setCategory("");
    }
  }, []);

  // const onCategorys = useCallback((e, v) => {
  //   if (v && v.label) {
  //     setCategory(v.label)
  //   } else {
  //     setCategory('')

  //     setPage(0);
  //     dispatch(setEquipments([] as Array<IEquipment>))
  //     searchFilter('Filter', '', '', 0);
  //   }
  // }, [])

  // for category reset
  const Resetcategory = useCallback((event) => {
    setCategory("");
  }, []);

  const onLocationInputChange = debounce(
    useCallback(async (e) => {
      const subString = e.target.value;
      if (subString !== "") {
        const locationsRes = await EquipmentAPIs.getLocation({
          subString: subString,
        });
        setLocations(locationsRes.data.rows);
      }
    }, []),
    1000
  );

  const onRangeChange = useCallback((e) => {
    let rng = e.target.value;
    rng = rng.replace(/[^0-9.]/g, "");
    setLocationRange(rng);
  }, []);

  const ResetlocationRange = useCallback((e) => {
    setLocationRange("");
  }, []);

  const HandleKeyword = useCallback(
    (e) => {
      setKeyword(e.target.value);
    },
    [Keyword]
  );

  const handleKeyUp = (event) => {
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      searchApplied &&
      event.target.value === ""
    ) {
      setPage(0);
      searchFilter("keywordEmpty", "", "", 0);
      setSearchApplied(false);
    }
    if (event.key === "Enter" && event.target.value?.length >= 1) {
      setPage(0);
      dispatch(setEquipments([]));
      setLoadmore(true);
      setFilterType("");
      searchFilter("Filter", "", "", 0);
      setSearchApplied(true);
    }
  };
  const ResetKeyword = () => {
    setKeyword("");
    if (searchApplied) {
      setPage(0);
      searchFilter("keywordEmpty", "", "", 0);
    }
    setSearchApplied(false);
  };

  // for handling zipCode field
  const HandlePostalCode = useCallback((e) => {
    var val = e.target.value;
    val = val.replace(/[^0-9.]/g, "");
    setZipCode(val);
    //set location range disable if zip code not available
    setRangeValidate(true);
    if (val.length > 0) {
      setRangeValidate(false);
    }
    if (val.length == 0) {
      setLocationRange("");
    }
  }, []);

  const ResetZipCode = useCallback((e) => {
    setZipCode("");

    // Handle location range field
    setRangeValidate(true);
    setLocationRange("");
  }, []);

  /**
   * HandleReset Function:
   * Reset Search and Filter Settings
   *
   * Description:
   * This function is triggered when resetting the search and filter settings.
   * It performs the following tasks:
   * 1. Resets the search results count to false.
   * 2. Dispatches 'setEquipments([])' to clear existing equipment data.
   * 3. Resets min and max price, location range, and page number.
   * 4. Resets filter tag data, keyword, state, city, zip code, category, and date values.
   * 5. Dispatches 'setFilterTagReset('')' to reset the filter tag.
   * 6. Calls 'getAllCityList()' to fetch the city list.
   * 7. Closes the filter popup.
   * 8. Resets filter type to an empty string.
   * 9. Sets 'loadmore' to true.
   * 10. Calls '_getlocation()' to fetch the user's current location.
   */
  const HandleReset = useCallback(
    (e) => {
      setSearchResults(false); // reset result count
      dispatch(setEquipments([]));
      setMinPrice(0);
      setMaxPrice(0);
      setLocationRange("");
      setPage(0);
      //searchEquipments(0, null, _lat, _long)

      setFilterTagData({
        State: "",
        City: "",
        ZipCode: "",
        StartDate: "",
        EndDate: "",
        category: "",
        locationRange: "",
      });

      // setKeyword('');
      setState("");
      setStateLabel("");
      setCity("");
      setZipCode("");
      setCategory("");
      setLocationRange("");
      setValues([]);
      setStartDate("");
      setEndDate("");

      setFilterTagReset("");

      getAllCityList();
      setFilterPopUp(false); // close filter popup
      setFilterType("");
      //if (FilterType == '') {
      setLoadmore(true);
      // _getlocation()
      searchFilter("Filter", "", "", 0);
    },
    [Keyword, ListEquipType]
  );

  const HandleFilterPopUp = useCallback(() => {
    setFilterPopUp(true);
    // setKeyword('')
    //setFilterType('Filter')
    // getStates()
    // getAllCityList()
  }, []);

  // const HandleStateChange = useCallback((event) => {
  //   let val = event.target.value

  //   setCityList([])
  //   setCity('')
  //   if (val != '') {
  //     setState(val)
  //     getCityList(val)
  //   } else {
  //     setState('')
  //     getAllCityList();
  //   }
  // }, [])

  const HandleStateChangeAutocomplete = useCallback((e, v) => {
    if (v && v.label) {
      setCityList([]);
      setCity("");
      setState(v.id);
      setStateLabel(v.label);
      getCityList(v.id);
    } else {
      setState("");
      setStateLabel("");
      getAllCityList();
    }
  }, []);

  const ResetState = useCallback((event) => {
    setState("");
    setStateLabel("");
    getAllCityList();
  }, []);

  const HandleCityChange = useCallback((event) => {
    let val = event.target.value;
    if (val) {
      setCity(val);
    } else {
      setCity("");
    }
  }, []);

  const HandleCityChangeAutocomplete = useCallback((e, v) => {
    if (v && v.label) {
      setCity(v.label);
    } else {
      setCity("");
    }
  }, []);

  const ResetCity = useCallback((event) => {
    setCity("");
  }, []);

  // Get all State List
  const getStates = async () => {
    var requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ country: "United States" }),
    };
    try {
      setStateListLoading(true);
      await fetch(
        "https://countriesnow.space/api/v0.1/countries/states",
        requestOptions
      ).then((response) => {
        response.json().then((data) => {
          let _state = [];
          data.data.states.filter((item) => {
            if (
              !item.name.includes("Island") &&
              !item.name.includes("Atoll") &&
              !item.name.includes("Reef")
            ) {
              _state.push(item);
            }
          });

          // let options = [];
          // _state.map((item) => {
          //   options.push({ label: item.name, id: item.name });
          // });
          //setStateList(options);
          setStateList(_state);
          setStateListLoading(false);
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Get all City List
  const getAllCityList = async () => {
    var requestOptions = {
      method: "GET",
      //redirect: "follow",
      headers: { "Content-Type": "application/json" },
      //body: JSON.stringify(''),
    };

    try {
      setCityListLoading(true);
      await fetch(
        "https://countriesnow.space/api/v0.1/countries",
        requestOptions
      ).then((response) => {
        response.json().then((result) => {
          let arr = [...result.data];
          let city_options = [];
          arr.map((item) => {
            if (item.country == "United States") {
              let city_options = item.cities;
              // city_arr.map((cityitem) => {
              //   city_options.push({ label: cityitem, id: cityitem });
              // });
              //console.log('city_options', city_options);
              setCityList(city_options);
              setCityListLoading(false);
            }
          });
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Get all City State Based
  const getCityList = async (state) => {
    // console.log('state', state);
    var requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ country: "United States", state: state }),
      //body: JSON.stringify({ country: "United States", state: 'Utah' }),
    };
    try {
      setCityListLoading(true);
      await fetch(
        "https://countriesnow.space/api/v0.1/countries/state/cities",
        requestOptions
      ).then((response) => {
        response.json().then((data) => {
          let city_arr = data.data;
          // let city_options = [];
          // city_arr.map((item) => {
          //   city_options.push({ label: item, id: item });
          // });
          // setCityList(city_options);
          setCityList(city_arr);
          setCityListLoading(false);
          // console.log('city_arr', city_arr);
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Handle calendar popup
  const HandleCalendarPopup = async () => {
    setCalendarPopup(true);
  };

  // reset the calendar
  const resetCalendar = () => {
    let a = ["", ""];
    setValues(a);
    setStartDate("");
    setEndDate("");
  };

  // handle dates in calendar popup
  const HandleCalendarDates = () => {
    if (!EndDate && !StartDate) {
      dispatch(setalertMessage("Please select both start & end date."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    setCalendarPopup(false);
    // setStartDate(start)
    // setEndDate(end)
  };

  /** commenting by GD
   * Function: HandlepopupFilter
   * Description:
   * Handles the equipment filter popup. If filters are provided (State, City, ZipCode, StartDate, EndDate, or locationRange),
   * it sets the filter tag data, triggers a search with the specified filters, and closes the filter popup.
   * If no filters are provided, it displays an error message.
   */
  // Handle popup filter
  const HandlepopupFilter = async () => {
    try {
      // console.log("HandlepopupFilter locationRange :" , locationRange);
      if (
        State != "" ||
        City != "" ||
        ZipCode != "" ||
        StartDate != "" ||
        EndDate != "" ||
        locationRange != "" ||
        category != ""
      ) {
        setFilterTagData({
          State: State,
          City: City,
          ZipCode: ZipCode,
          StartDate: StartDate,
          EndDate: EndDate,
          category: category,
          locationRange:
            locationRange == null || locationRange == "0" ? "" : locationRange,
        });
        setSearchResults(true);
        // setPage(0);
        dispatch(setEquipments([]));
        dispatch(setCount(0));
        setFilterPopUp(false);
        setFilterType("Filter");
        setLoadmore(true);

        searchFilter("Filter", "", "", 0);
        setPage(0);
      } else {
        // console.log("in else ");
        // dispatch(setalertMessage('Please enter a zipcode.'));
        dispatch(setalertMessage("Please select a filter."));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Handle reset filter
  const HandleResetFilters = async (resetfield = null) => {
    setFilterTagReset("");
    setSearchResults(false);
    if (resetfield == "reset_state") {
      setFilterTagReset(resetfield);
      setState("");
      setStateLabel("");
      setFilterTagData({
        State: "",
        City: City,
        ZipCode: ZipCode,
        StartDate: StartDate,
        EndDate: EndDate,
        category: category,
        locationRange: locationRange == null ? "" : locationRange,
      });
    }

    if (resetfield == "reset_city") {
      setFilterTagReset(resetfield);
      setCity("");
      setFilterTagData({
        State: State,
        City: "",
        ZipCode: ZipCode,
        StartDate: StartDate,
        EndDate: EndDate,
        category: category,
        locationRange: locationRange == null ? "" : locationRange,
      });
    }

    if (resetfield == "reset_zipcode") {
      setFilterTagReset(resetfield);
      setZipCode("");
      setFilterTagData({
        State: State,
        City: City,
        ZipCode: "",
        StartDate: StartDate,
        EndDate: EndDate,
        category: category,
        locationRange: locationRange == null ? "" : locationRange,
      });
    }

    if (resetfield == "reset_locationrange") {
      setFilterTagReset(resetfield);
      setLocationRange("");
      setFilterTagData({
        State: State,
        City: City,
        ZipCode: ZipCode,
        StartDate: StartDate,
        EndDate: EndDate,
        category: category,
        locationRange: "",
      });
    }

    if (resetfield == "reset_category") {
      setFilterTagReset(resetfield);
      setCategory("");
      setFilterTagData({
        State: State,
        City: City,
        ZipCode: ZipCode,
        StartDate: StartDate,
        EndDate: EndDate,
        category: "",
        locationRange: locationRange == null ? "" : locationRange,
      });
    }

    if (resetfield == "reset_dates") {
      setFilterTagReset(resetfield);
      setStartDate("");
      setEndDate("");
      setValues([]);

      setFilterTagData({
        State: State,
        City: City,
        ZipCode: ZipCode,
        StartDate: "",
        EndDate: "",
        category: category,
        locationRange: locationRange == null ? "" : locationRange,
      });
    }
  };
  /** commenting by GD
   * Function: searchFilter
   * Description:
   * Performs a search with the provided filters and updates the equipment list.
   *
   * @param {string} filter - The filter for the search.
   * @param {number} defLat - Default latitude if not provided.
   * @param {number} defLng - Default longitude if not provided.
   * @param {number} pagenumber - The page number for pagination.
   */
  // const searchFilter = async (filter = '', defLat = null, defLng = null, pagenumber = page) => {
  const searchFilter = async (
    filter = "",
    defLat = null,
    defLng = null,
    pagenumber = page,
    cat = category
  ) => {
    try {
      // console.log("defLat:" , defLat);
      // console.log("lat state:" , lat);
      let newlat = defLat == null ? "" : defLat;
      let newlng = defLng == null ? "" : defLng;
      // console.log("new lat lng: " ,newlat + " :: " ,  newlng );
      // console.log("searchFilter running");

      // by GD to enable disable radio buttons
      setRadioDisabled(true);
      setAPIStatus(true);
      let body = {
        search: filter === "keywordEmpty" ? "" : Keyword,
        type: filter,
        State: State,
        City: City,
        ZipCode: ZipCode,
        startDate: StartDate,
        endDate: EndDate,
        // category: category,
        category: cat,
        range_miles:
          locationRange == null || locationRange == "0" ? "" : locationRange,
        page: pagenumber,
        // by GD 29 Jan 2024 changed due to changes in api by MK
        lat: lat || newlat,
        lng: lng || newlng,
        // lat: lat || defLat == null ? '' :defLat,
        // lng: lng || defLng == null ? '' :defLng,
        // lat: lat ? lat : "",
        // lng: lng ? lng : '',
        equip_list_type: ListEquipType,
        // equip_list_type: equipListType
      };
      // console.log('body in api', body);
      if (pagenumber == 0) {
        dispatch(setEquipments([] as Array<IEquipment>));
      }
      // if(fromHowItWorks) return;
      const FilterData = await EquipmentAPIs.searchFilter(body);
      // console.log("data in search: ", FilterData);
      // console.log("filter data in searchFilter api: ", FilterData);

      setLoadtype("Loaded");
      setLoadmore(false);
      if (FilterData["type"] == "RXSUCCESS") {
        if (pagenumber == 0) {
          dispatch(setEquipments([] as Array<IEquipment>));
        }
        setFilterTagReset("");
        dispatch(setEquipments(FilterData.data));
        dispatch(setCount(FilterData.total));
        setAPIStatus(false);
        setRadioDisabled(false); // enable radio buttons
      } else {
        setRadioDisabled(false); // enable radio buttons
        dispatch(setLoadingEquipments(false));
        dispatch(setCount(0));
        setAPIStatus(false);
      }

      //setLoadmore(false)
    } catch (err) {
      console.log(err);
      dispatch(setLoadingEquipments(false));
      setRadioDisabled(false);
    }
    //setShowResult(true)
  };

  // useCallback((e) => {
  //   setLocationRange('')
  // }, [])

  const HandleEquipType = useCallback(
    (event) => {
      if (!radioDisabled) {
        let chk_status = event.target.value;
        if (chk_status == ListEquipType) return;
        // added by GD to prevent mixup of sales and rent 2 jan 2024
        // dispatch(setEquipments([] as Array<IEquipment>));
        // setTimeout(() => {
        //   setListEquipType(chk_status);
        // }, 1000);
        setListEquipType(chk_status);
      }
    },
    [ListEquipType]
  );

  // by GD for rent and sales checkBox's
  const handleEquipeTypeRent = (event) => {
    let val = event.target.checked;
    if (val) {
      setListEquipType("rent");
    } else {
      setListEquipType("sale");
    }
  };

  const handleEquipeTypeSale = (event) => {
    let val = event.target.checked;
    if (val) {
      setListEquipType("sale");
    } else {
      setListEquipType("rent");
    }
  };
  // end by GD

  // by GD 23 Jan 2024 for search hide and show
  const [prevScrollpos, setPrevScrollpos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const scrollThreshold = 350;

  const searchRef = useRef(null);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;
  //     const isScrollingUp = prevScrollpos > currentScrollPos;

  //     setPrevScrollpos(currentScrollPos);
  //     setVisible(isScrollingUp);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [prevScrollpos]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingUp = prevScrollpos > currentScrollPos;

      // Check if the current scroll position is below the threshold
      if (currentScrollPos > scrollThreshold) {
        setVisible(isScrollingUp);
      } else {
        setVisible(true); // If above the threshold, always set it to visible
      }

      setPrevScrollpos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollpos, scrollThreshold]);

  // end by GD 24 Jan 2024 search hide and show

  /**
   * useEffect Dependency Update:
   * Effect to Handle List Equipment Type Changes
   * Description:
   * This effect is triggered when there is a change in the 'ListEquipType' variable.
   * If 'Loadtype' is not 'default' and 'ListEquipType' is defined and not empty, it sets
   * the page to 0, clears the existing equipment list, and initiates a search with the
   * 'Filter' type and page 0.
   */
  useEffect(() => {
    // if(fromHowItWorks)return;
    // one if commented and another added by gd 2 jan 2024
    // if(Loadtype != 'default' && (ListEquipType != undefined || ListEquipType != '')) {
    if (Loadtype != "default" && ListEquipType) {
      setPage(0);
      dispatch(setEquipments([] as Array<IEquipment>));
      // searchFilter('Filter', '', '', 0);
      searchFilter("Filter", lat, lng, 0);
      // searchFilter('Filter', null, null, 0);

      // setTimeout(()=>{
      // searchFilter('Filter', '', '', 0);
      // }, 500)
    }
  }, [ListEquipType]);

  // by GD on 24 Jan for rent sale redux set state
  // useEffect(() => {
  //   // one if commented and another added by gd 2 jan 2024
  //   // if(Loadtype != 'default' && (ListEquipType != undefined || ListEquipType != '')) {
  //   if(Loadtype != 'default' && equipListType) {
  //   setPage(0);
  //   dispatch(setEquipments([] as Array<IEquipment>))
  //   searchFilter('Filter', '', '', 0);

  //   // setTimeout(()=>{
  //   // searchFilter('Filter', '', '', 0);
  //   // }, 500)

  //   }
  // }, [equipListType]);

  // by GD 4 march 2024 for filters working from howItWorks page with redux
  // useEffect(() => {
  //   setPage(0);
  //   dispatch(setEquipments([]));
  //   // by GD added 'Filter' in searchFilter due to error while search with Zipcode
  //   // if(ZipCode != '' || StartDate != '' || EndDate != '' || locationRange != ''){
  //   //   searchFilter('Filter');
  //   //   return;
  //   // }
  //   // // end by GD
  //   // searchFilter();
  //   console.log("equipFilters in search: ", equipFilters);
  //   console.log("fromHowItWorks: " , fromHowItWorks);

  //   if (fromHowItWorks) {
  //     console.log("how it works if fromHowItWorks");
  //     setListEquipType(equipFilters.equipType);
  //     setLocationRange(equipFilters.locationRange);
  //     setStartDate(equipFilters.startDate);
  //     setEndDate(equipFilters.endDate);
  //     setZipCode(equipFilters.zipCode);

  //     setFilterTagData({
  //       State: State,
  //       City: City,
  //       ZipCode: equipFilters.zipCode,
  //       StartDate: equipFilters.startDate,
  //       EndDate: equipFilters.endDate,
  //       category: equipFilters.category,
  //       locationRange: equipFilters.locationRange == "0" || equipFilters.locationRange == "" ? '' : equipFilters.locationRange,
  //     });

  //     setCategory(equipFilters.category);
  //     dispatch(setFromHowItWorks(false));

  //     if (equipFilters.zipCode != '' || equipFilters.startDate != '' || equipFilters.endDate != '' || equipFilters.locationRange != '') {
  //       console.log("in search filters if");
  //       if(equipFilters.category == ''){
  //         searchFilter('Filter');
  //       }
  //       dispatch(setEquipments([]));
  //       setTimeout(() => {
  //         // searchFilter('Filter');
  //         // dispatch(setFromHowItWorks(false));
  //         // seeResultRef.current.click();
  //         if(equipFilters.category == ''){
  //           // searchFilter('Filter');
  //           seeResultRef.current.click();
  //         }
  //       }, 2000);
  //       return;
  //     }else{
  //       console.log("in search filters else");
  //       dispatch(setEquipments([]));
  //       setTimeout(() => {
  //         // searchFilter();
  //         //  dispatch(setFromHowItWorks(false));
  //         // seeResultRef.current.click();
  //         if(equipFilters.category == ''){
  //           // searchFilter('Filter');
  //           seeResultRef.current.click();
  //         }
  //       }, 2000);
  //     }
  //   }else{
  //     console.log("how it works fromHowItWorks is false");
  //   }

  // }, [fromHowItWorks]);

  // by GD 4 march 2024 for filters working from howItWorks page with local storage
  useEffect(() => {
    const fromFindPageLocal = localStorage.getItem("fromFindPageLocal");
    // console.log("fromFindPageLocal: " , fromFindPageLocal);

    if (fromFindPageLocal && fromFindPageLocal == "yes") {
      console.log("firstCheck from local storage works");
      setRadioDisabled(true);
      setAPIStatus(true);
      const equipFiltersObjSt = localStorage.getItem("equipFiltersObj");
      if (equipFiltersObjSt) {
        console.log("how it works if fromHowItWorks");
        console.log("equipFiltersObj: ", JSON.parse(equipFiltersObjSt));
        const equipFiltersObj = JSON.parse(equipFiltersObjSt);

        const locRange = equipFiltersObj["locationRange"] || "";
        const zipCd = equipFiltersObj["zipCode"];
        const sDate = equipFiltersObj["startDate"];
        const eDate = equipFiltersObj["endDate"];
        const cat = equipFiltersObj["category"];

        // setListEquipType(equipFiltersObj['equipType'] || '');
        // setLocationRange(equipFiltersObj['locationRange'] || '');
        // setStartDate(equipFiltersObj['startDate'] || '');
        // setEndDate(equipFiltersObj['endDate'] || '');
        // setZipCode(equipFiltersObj['zipCode'] || '');
        console.log("cat: ", cat);

        setCategory(cat);
        setLocationRange(locRange);
        setStartDate(sDate);
        setEndDate(eDate);
        setZipCode(zipCd);

        setFilterTagData({
          State: State,
          City: City,
          ZipCode: zipCd,
          // StartDate: equipFilters['startDate'],
          // EndDate: equipFilters['endDate'],
          // category: equipFilters['category'],
          StartDate: sDate,
          EndDate: eDate,
          category: cat,
          locationRange: locRange == "0" ? "" : locRange,
        });

        // setCategory(cat);
        // setCategory(equipFiltersObj['category'] || '');
        // setCategory(equipFiltersObj['category']);
        // dispatch(setFromHowItWorks(false));
        // setTimeout(() => {
        //   // setCategory(equipFiltersObj['category'] || '');
        //   // setFilterTagData({
        //   //   State: State,
        //   //   City: City,
        //   //   ZipCode: equipFilters['zipCode'],
        //   //   StartDate: equipFilters['startDate'],
        //   //   EndDate: equipFilters['endDate'],
        //   //   category: equipFilters['category'],
        //   //   locationRange: locRange == "0" ? '' : locRange,
        //   // });
        //   // dispatch(setEquipments([]));
        //   // setCategory(equipFiltersObj['category']);
        //   setCategory(cat);

        // }, 1500);

        setTimeout(() => {
          localStorage.setItem("fromFindPageLocal", "");
        }, 4000);

        if (
          equipFiltersObj["zipCode"] != "" ||
          equipFiltersObj["startDate"] != "" ||
          equipFiltersObj["endDate"] != "" ||
          equipFiltersObj["locationRange"] != ""
        ) {
          console.log("in search filters if");

          if (equipFiltersObj["category"] == "") {
            // searchFilter('Filter');
          }
          // dispatch(setEquipments([]));
          setTimeout(() => {
            // dispatch(setEquipments([]));
            // dispatch(setEquipments([] as Array<IEquipment>))
            // searchFilter('Filter');
            // dispatch(setFromHowItWorks(false));
            // seeResultRef.current.click();
            seeResult2Ref.current.click();
            // _getlocation()
            // if (equipFiltersObj['category'] == '') {
            //   // searchFilter('Filter');
            //   seeResultRef.current.click();
            // }
          }, 2000);
          return;
        } else {
          console.log("in search filters else");
          // dispatch(setEquipments([]));
          // seeResultRef.current.click();

          setTimeout(() => {
            // dispatch(setEquipments([]));
            // dispatch(setEquipments([] as Array<IEquipment>))
            // searchFilter();
            //  dispatch(setFromHowItWorks(false));
            // seeResultRef.current.click();
            setSearchResults(true);
            setFilterType("");
            setLoadmore(true);
            //searchFilter()
            searchFilter("", "", "", 0, cat);
            console.log("lat, lng: ", lat, " : ", lng);
            // _getlocation();
            // searchFilter('', '', '', 0, cat);
            // if (equipFiltersObj['category'] == '') {
            //   // searchFilter('Filter');
            //   seeResultRef.current.click();
            // }
          }, 2000);
        }
      } else {
        console.log("how it works fromHowItWorks is false");
      }
    }
    // localStorage.getItem("equipFiltersObj");
    // localStorage.setItem("fromFindPageLocal", "yes");
  }, []);
  // by GD 4 march 2024

  // by GD Slider Value-----------------------------------//
  //  const [value, setValue] = React.useState("");
  const handleSliderChange = (event: Event, newValue) => {
    // const [locationRange, setLocationRange] = useState('');
    // console.log("new value: " , newValue);
    // console.log("locationRange: " , locationRange);
    //  setValue(newValue as string);
    setLocationRange(newValue as string);
  };
  return (
    <>
      <Box className="carousel-container">
        <SwiperCarousel />
        <Box className="search-form-container">
          <img
            src={searchFormBorderLeft}
            alt="Search Form Border"
            className="img-search-form-border-left"
          />

          <Box className="search-main-container">
            <Box className="search-main-container-div">
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    fontSize: "clamp(1.7rem, 3vw, 2.5rem) !important",
                    fontFamily: "GothamBold !important",
                    lineHeight: "normal",
                  }}
                >
                  <span>Find</span>
                  <span className="yellow-text">Equipment</span>
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    fontSize: "clamp(1.7rem, 3vw, 2.5rem) !important",
                    fontFamily: "GothamBold !important",
                    lineHeight: "normal",
                  }}
                >
                  <span>List</span>
                  <span className="yellow-text">Equipment</span>
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    display: "flex",
                    alignItems: "baseline",
                    gap: "9px",
                    fontSize: "clamp(1.7rem, 3vw, 2.5rem) !important",
                    fontFamily: "GothamBold !important",
                    lineHeight: "normal",
                  }}
                >
                  <span>Build</span>
                  <span className="yellow-text">a Crew</span>
                  {/* below line coomented by pp on 16 apr 2024 */}
                  {/* <span style={{ color: '#fff', fontSize: '10px' }}>
                    (Coming Soon)
                  </span> */}
                </Typography>
              </Box>
              <Typography
                className=""
                sx={{
                  color: "#fff",
                  fontSize: "clamp(1rem, 3vw, 1.2rem) !important",
                  lineHeight: "normal",
                  textAlign: "left",
                  mt: "1rem",
                }}
              >
                Discover the power of peer-to-peer rentals, purchases, lending
                and much, much more!
              </Typography>
            </Box>
          </Box>
          <img
            src={searchFormBorderRight}
            alt="Search Form Border"
            className="img-search-form-border-right"
          />
        </Box>
      </Box>

      {/* <Box>
                <FormControl fullWidth className="form-fields">
                  <FormControl>

                    <TextField
                      label="Keyword"
                      placeholder={"Keyword"}
                      fullWidth
                      type={'text'}
                      size='small'
                      InputProps={searchInputProp}
                      //InputLabelProps={inputLabelProp}
                      variant="filled"
                      //style={{ width: "83%", height: "30px", marginBottom: "15px", alignItems: 'center', alignSelf: 'center', alignContent: 'center' }}
                      //className="field-set-center-range"
                      value={Keyword}
                      onChange={HandleKeyword}
                      onKeyDown={handleKeyUp}
                    />
                    {Keyword.length > 0 && (
                      <span className="cross-icon"
                        onClick={ResetKeyword}>X</span>
                    )}
                  </FormControl>
                </FormControl>
              </Box> */}

      {/* <hr className="orange-line-2" /> */}
      <Box className="pagination-section">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0rem",
            width: "100%",
          }}
        >
          {/* <Grow in={visible}> */}
          <Slide
            in={visible}
            container={searchRef.current}
            //  timeout={300}
          >
            <Box
              className="search-result"
              // by GD 23 Jan  added ref sx section
              ref={searchRef}
            >
              <Box
                className="search-main"
                sx={{
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <Box className="search-div">
                  {/* <Box className='search-grid'> */}
                  <Box className="search-subgrid">
                    <Box className="search-subgrid-inner">
                      <Box
                        className="search-cselect"
                        sx={{ position: "relative" }}
                      >
                        {/* by GD search autofill replaced with select dropdown for category select */}
                        {/* <Select
                          value={category}
                          onChange={onCategoryChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            "& .MuiSelect-select": {
                              color: "#2D2D2D !important",
                              fontFamily: "GothamBold !important",
                            },
                          }}
                          style={{
                            backgroundColor: "#fff",
                            border: "2px solid #FAA61A",
                            borderRadius: "10px",
                          }}
                          className="select-dropdwn"
                        >
                          <MenuItem value="" className="category-dropdown">All</MenuItem>
                          {categories.map((item, index) => (
                            <MenuItem
                              className="list-eqp-select-options"
                              value={item.label}
                              key={index}
                            >
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select> */}
                        {/* above select commented Below line is added by pp on 18 apr 2023 due to search filter based on keyword changes */}
                        <Box className="search-box">
                          <FormControl
                            className="dsfsdf"
                            fullWidth
                            sx={{
                              borderRadius: "7px",
                              oultine: "2px solid #faa61a",
                            }}
                          >
                            <TextField
                              label="Search equipment"
                              // variant="outlined"
                              variant="filled"
                              fullWidth
                              type={"text"}
                              InputProps={{ disableUnderline: true }}
                              // InputProps={searchInputProp}
                              value={Keyword}
                              onChange={HandleKeyword}
                              onKeyUp={handleKeyUp}
                              sx={{
                                "& .MuiInputBase-input": {
                                  paddingRight: "50px",
                                },
                                backgroundColor: "white",
                                color: "#2d2d2d",
                              }}
                            />
                            {Keyword.length >= 1 && (
                              <span
                                className="search-cross-icon"
                                onClick={ResetKeyword}
                              >
                                X
                              </span>
                            )}
                          </FormControl>
                        </Box>
                        {/* en by pp */}
                        {/* end by GD  */}
                        {/* <Autocomplete
                      freeSolo
                      //clearIcon={false}
                      options={
                        categories.length > 0 && categories.map((catitem) => (
                          { label: catitem.label, id: catitem.label }
                        ))
                      }
                      value={category}
                      onChange={onCategorys}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          //label="Equipment Type"
                          placeholder="What type of equipment?"
                          //margin="normal"
                          // fullWidth
                          onChange={(e) => {
                            // console.log("event", e);
                          }}
                          sx={{
                            verticalAlign: "top",
                            backgroundColor: "#fff!important",
                            color: "#2d2d2d!important",
                            borderRadius: "5px",
                            border: "1px solid #faa61a",
                          }}

                        // InputProps={{ disableUnderline: true }}
                        />
                      )}
                      sx={{
                        verticalAlign: "top",
                        backgroundColor: "#fff!important",
                        color: "#2d2d2d!important",
                        borderRadius: "5px",
                        border: "1px solid #faa61a",
                        // padding: "10px 20px",
                      }}
                      className={"search-input"}
                    /> */}
                        {/* {category != '' && (
                    <Box sx={{ position: 'absolute', right: '10px', top: '50%', transform: 'translate(-50%, -10px)', color: '#faa61a', fontFamily: 'GothamBold !important', cursor: 'pointer' }} className="filter-cross-icon"
                      onClick={Resetcategory}>X</Box>
                  )} */}
                      </Box>
                      <Box>
                        <img
                          onClick={HandleFilterPopUp}
                          src={Filtericon}
                          alt="filter-icon"
                          className="search-filter-icon"
                          style={{
                            width: "35px",
                            height: "auto",
                            display: "block",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className="search-btn-grid">
                      <Box className="search-result-btn-div">
                        <Button
                          //disabled={category==''? true:false}
                          ref={seeResultRef}
                          variant="contained"
                          color="success"
                          className="search-result-btn"
                          sx={{
                            height: "50px",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            bgcolor: "#149247",
                            fontFamily: "GothamBold",
                            color: "#fff",
                            width: "100% !important",
                            textTransform: "none",
                            fontSize: "20px",
                            lineHeight: "normal",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "7px",
                          }}
                          onClick={() => {
                            if (Keyword?.length >= 1) {
                              setFilterTagData({
                                State: State,
                                City: City,
                                ZipCode: ZipCode,
                                StartDate: StartDate,
                                EndDate: EndDate,
                                category: category,
                                locationRange:
                                  locationRange == null ? "" : locationRange,
                              });
                              setPage(0);
                              dispatch(setEquipments([] as Array<IEquipment>));
                              //searchEquipments(pagelimit, null, _lat, _long)
                              setSearchApplied(true);
                              if (!loadmore) {
                                setSearchResults(true);
                                setFilterType("");
                                setLoadmore(true);
                                //searchFilter()
                                setPage(0);
                                searchFilter("", "", "", 0);
                              }
                            }
                          }}
                        >
                          See Results
                        </Button>
                      </Box>
                      <Box className="search-radio-btns">
                        {/* for rent and sales radio buttons */}
                        <FormControl className="">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            // onClick={HandleEquipType}
                            className="radio-grp list-eqp-page new-radio-grp"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <FormControlLabel
                                checked={ListEquipType == "rent" ? true : false}
                                // checked={equipListType == 'rent' ? true : false}
                                value={"rent"}
                                control={<Radio disabled={radioDisabled} />}
                                // control={<Radio className='disabled-radio' sx={{ color: 'gray' }} disabled={true} />}
                                onClick={(e) => {
                                  if (radioDisabled) return;
                                  setListEquipType("rent");
                                  localStorage.setItem(
                                    "equipFilterType",
                                    "rent"
                                  );
                                  // dispatch(setEquipListType("rent"));
                                  // HandleEquipType(e)
                                }}
                                label={
                                  <Box>
                                    <Typography sx={{ gap: "8px" }}>
                                      <span
                                        className=""
                                        style={{
                                          fontFamily: "",
                                          color: "#fff !important",
                                        }}
                                      >
                                        For Rent
                                      </span>
                                    </Typography>
                                  </Box>
                                }
                                className="radio-grp"
                              />

                              <FormControlLabel
                                checked={ListEquipType == "sale" ? true : false}
                                // checked={equipListType == 'sale' ? true : false}
                                value={"sale"}
                                control={<Radio disabled={radioDisabled} />}
                                // control={<Radio className='disabled-radio' disabled={true} />}
                                onClick={(e) => {
                                  if (radioDisabled) return;
                                  setListEquipType("sale");
                                  localStorage.setItem(
                                    "equipFilterType",
                                    "sale"
                                  );
                                  // dispatch(setEquipListType("sale"));
                                  // HandleEquipType(e)
                                }}
                                label={
                                  <Box>
                                    <Typography sx={{ gap: "8px" }}>
                                      <span
                                        className=""
                                        style={{
                                          fontFamily: "",
                                          color: "#fff !important",
                                        }}
                                      >
                                        For Sale
                                      </span>
                                    </Typography>
                                  </Box>
                                }
                                className="radio-grp"
                              />
                            </Box>
                          </RadioGroup>
                        </FormControl>

                        {/* by GD for rent and sale checkBox */}
                        {/* <FormGroup sx={{ display: 'flex', alignItems: 'center',gap: '1rem', flexDirection: 'row' ,color: '#fff !important' }}>

                        <FormControlLabel
                             control={<Checkbox
                                sx={{
                                  "&:hover": { bgcolor: "transparent" },
                                }}
                                defaultChecked={ListEquipType == "rent" ? true : false}
                                checked = {ListEquipType == "rent" ? true : false}
                                onChange={handleEquipeTypeRent}
                                disableRipple
                                color="default"
                                // checkedIcon={<BpCheckedIcon />}
                                // icon={<BpIcon />}
                                inputProps={{ "aria-label": "Checkbox demo" }}
                              />} label="For Rent" />
                        <FormControlLabel control={<Checkbox
                                sx={{
                                  "&:hover": { bgcolor: "transparent" },
                                }}
                                // defaultChecked={ListEquipType == "sale" ? true : false}
                                checked = {ListEquipType == "sale" ? true : false}
                                onChange={handleEquipeTypeSale}
                                disableRipple
                                color="default"
                                // checkedIcon={<BpCheckedIcon />}
                                // icon={<BpIcon />}
                                inputProps={{ "aria-label": "Checkbox demo" }}
                              />} label="For Sale" />
                      </FormGroup> */}
                        {/* end by GD */}
                      </Box>
                    </Box>
                  </Box>
                  {/* </Box> */}
                </Box>
              </Box>

              <Box>
                {/* Add filter values */}
                {/* {FilterType == "Filter" && (                 */}
                <Box className="filter-tag-grid">
                  {FilterTagData.State && (
                    <Box className="filter-search">
                      <Box className="filter-search-tags">
                        State: {FilterTagData.State}
                        <span
                          className="tag-cross-icon"
                          onClick={() => HandleResetFilters("reset_state")}
                        >
                          X
                        </span>
                      </Box>
                    </Box>
                  )}

                  {FilterTagData.City && (
                    <Box className="filter-search">
                      <Box className="filter-search-tags">
                        City: {FilterTagData.City}
                        <span
                          className="tag-cross-icon"
                          onClick={() => HandleResetFilters("reset_city")}
                        >
                          X
                        </span>
                      </Box>
                    </Box>
                  )}

                  {FilterTagData.ZipCode && (
                    <Box className="filter-search">
                      <Box className="filter-search-tags">
                        ZipCode: {FilterTagData.ZipCode}
                        <span
                          className="tag-cross-icon"
                          onClick={() => HandleResetFilters("reset_zipcode")}
                        >
                          X
                        </span>
                      </Box>
                    </Box>
                  )}
                  {FilterTagData.locationRange && (
                    <Box className="filter-search">
                      <Box className="filter-search-tags">
                        Range : {FilterTagData.locationRange}
                        <span
                          className="tag-cross-icon"
                          onClick={() =>
                            HandleResetFilters("reset_locationrange")
                          }
                        >
                          X
                        </span>
                      </Box>
                    </Box>
                  )}

                  {FilterTagData.category && (
                    <Box className="filter-search">
                      <Box className="filter-search-tags">
                        Category : {FilterTagData.category}
                        <span
                          className="tag-cross-icon"
                          onClick={() => HandleResetFilters("reset_category")}
                        >
                          X
                        </span>
                      </Box>
                    </Box>
                  )}

                  {FilterTagData.StartDate && FilterTagData.EndDate && (
                    <Box className="filter-search">
                      <Box className="filter-search-tags">
                        Dates : {FilterTagData.StartDate} To{" "}
                        {FilterTagData.EndDate}
                        <span
                          className="tag-cross-icon"
                          onClick={() => HandleResetFilters("reset_dates")}
                        >
                          X
                        </span>
                      </Box>
                    </Box>
                  )}
                </Box>
                {/* )} */}
              </Box>

              <Box sx={{ padding: "0 1rem" }}>
                {!APIStatus &&
                  (FilterTagData.category != "" ||
                    FilterTagData.City != "" ||
                    FilterTagData.State != "" ||
                    FilterTagData.ZipCode != "" ||
                    FilterTagData.locationRange != "" ||
                    FilterTagData.StartDate != "" ||
                    FilterTagData.EndDate != "" ||
                    ListEquipType) && (
                    <Box className="">
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "18px",
                          fontFamily: "GothamBold !important",
                        }}
                      >
                        {pagecount} Results
                        {FilterTagData.City != "" || FilterTagData.State != ""
                          ? " in"
                          : ""}
                        {/* {FilterTagData.category != '' ? FilterTagData.category : ''} */}
                        {FilterTagData.City != ""
                          ? " " + FilterTagData.City
                          : ""}
                        {FilterTagData.City != "" && FilterTagData.State != ""
                          ? ","
                          : ""}
                        {FilterTagData.State != ""
                          ? " " + FilterTagData.State
                          : ""}
                        {/* {FilterTagData.ZipCode != '' ? ', ' + FilterTagData.ZipCode : ''}
                    {FilterTagData.locationRange != '' ? ', ' + FilterTagData.locationRange : ''}
                    {FilterTagData.StartDate != '' ? ', ' + FilterTagData.StartDate : ''}
                    {FilterTagData.EndDate != '' ? '-' + FilterTagData.EndDate : ''} */}
                      </Typography>
                    </Box>
                  )}
              </Box>
            </Box>
            {/* </Grow> */}
          </Slide>
          <Box>
            {APIStatus && (
              <Box className="pagination-section">
                <CircularProgress />
              </Box>
            )}
            <Box sx={{ marginTop: "-1rem" }}>
              <EquipmentsView next={15} />
            </Box>
          </Box>
        </Box>
      </Box>

      {equipments.length > 0 && (
        <Box className="pagination-section">
          {loadmore && <CircularProgress />}
          {
            loadmore ||
              (pagecount > equipments.length && (
                <Link
                  className="btn-load-more"
                  onClick={() => {
                    setLoadmore(true);
                    //searchEquipments(pagelimit, null, _lat, _long, page + 1)
                    let newPage = page + 1;
                    setPage(newPage);
                    //HandleFilter()
                  }}
                >
                  {"See More"}
                  <img
                    src={gemArrow}
                    alt="GoEquipMe"
                    className="gem-arrow arrow-load-more"
                  />
                </Link>
              ))
            // {'See More'}</button>
          }
        </Box>
      )}

      <Modal
        keepMounted
        open={FilterPopUp}
        onClose={() => setFilterPopUp(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popup search-pop"
          sx={{
            ...style,
            borderRadius: " 10px",
            // width: '460px',
            width: matchesMobileX ? "calc(100vw - 100px)" : 400,
            padding: matchesMobileX ? "20px" : 4,
          }}
        >
          <IconButton
            onClick={() => setFilterPopUp(false)}
            sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
          >
            <Typography
              sx={{
                color: "#faa61a",
                fontSize: "25px",
                paddingRight: "7px",
                fontWeight: "800",
              }}
            >
              X
            </Typography>
          </IconButton>

          <Box className="filter-popup">
            <Box>
              <Typography
                sx={{
                  paddingLeft: "0.5rem",
                  mb: " 1rem",
                  color: "#faa61a !important",
                  fontSize: "31px !important",
                  fontFamily: "GothamBold !important",
                }}
              >
                Filters
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1.6rem",
                marginTop: "1rem",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
              >
                <Box sx={{ textAlign: "center", marginBottom: "0.5rem" }}>
                  <Typography
                    sx={{
                      color: "#faa61a !important",
                      marginBottom: "0px",
                      fontFamily: "GothamBold !important",
                      padding: "0 0.6rem",
                    }}
                  >
                    Select Category
                  </Typography>
                </Box>
                {/* below select category added by pp on 18 apr 2024 */}
                <Select
                  value={category}
                  onChange={onCategoryChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    "& .MuiSelect-select": {
                      color: "#2D2D2D !important",
                      fontFamily: "GothamBold !important",
                    },
                  }}
                  style={{
                    backgroundColor: "#fff",
                    border: "2px solid #FAA61A",
                    borderRadius: "10px",
                  }}
                  className="select-dropdwn"
                >
                  <MenuItem value="" className="category-dropdown">
                    All
                  </MenuItem>
                  {categories.map((item, index) => (
                    <MenuItem
                      className="list-eqp-select-options"
                      value={item.label}
                      key={index}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {/* end by pp */}
              </Box>

              {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px', }}> */}
              <Box className="equipment-filter-grid">
                <Box sx={{ textAlign: "center", marginBottom: "0.5rem" }}>
                  {/* <Typography sx={{ color: '#faa61a !important', marginBottom: '0px', fontFamily: 'GothamBold !important', padding: '0 0.6rem' }}>Enter Your Location Manually </Typography> */}
                  {/* above line commented and next line added by pp on 22 apr 2024 */}
                  <Typography
                    sx={{
                      color: "#faa61a !important",
                      marginBottom: "0px",
                      fontFamily: "GothamBold !important",
                      padding: "0 0.6rem",
                    }}
                  >
                    Enter Zip Code
                  </Typography>
                </Box>
                <Box>
                  <FormControl variant="filled" sx={{ width: "100%" }}>
                    <CssTextField
                      id="filled-number"
                      placeholder="Zip Code"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      // inputProps={{ maxLength: 8 }}
                      inputProps={{ maxLength: 6 }}
                      variant="outlined"
                      onChange={HandlePostalCode}
                      value={ZipCode}
                    />
                    {ZipCode.length > 0 && (
                      <span
                        className="filter-cross-icon"
                        onClick={ResetZipCode}
                      >
                        <ClearIcon
                          sx={{ fontSize: "18px", marginRight: "10px" }}
                        />
                      </span>
                    )}
                  </FormControl>
                </Box>
                <Box sx={{ textAlign: "center", marginBottom: "0.5rem" }}>
                  <Typography
                    sx={{
                      color: "#faa61a !important",
                      marginBottom: "0px",
                      fontFamily: "GothamBold !important",
                      padding: "0 0.6rem",
                    }}
                  >
                    Filter Dates
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    cursor: "pointer",
                    gap: "1.5rem",
                  }}
                >
                  <Box onClick={HandleCalendarPopup}>
                    <img
                      src={CalendarImg}
                      alt="GoEquipMe"
                      className=""
                      style={{
                        width: "42px",
                        outline: "3px solid #faa61a",
                        outlineOffset: "6px",
                        borderRadius: "3.5px",
                      }}
                    />
                  </Box>
                  {StartDate && EndDate && (
                    <Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{ color: "#fff !important", fontSize: "14px" }}
                        >
                          Start:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontSize: "14px",
                            marginLeft: "auto",
                          }}
                        >
                          {moment(StartDate).format("MM/DD/YY")}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{ color: "#fff !important", fontSize: "14px" }}
                        >
                          End:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontSize: "14px",
                            marginLeft: "auto",
                          }}
                        >
                          {moment(EndDate).format("MM/DD/YY")}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      color: "#faa61a !important",
                      marginBottom: "0px",
                      fontFamily: "GothamBold !important",
                      padding: "0 0.6rem",
                    }}
                  >
                    {" "}
                    Radius{" "}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff !important",
                      fontSize: "12px",
                      padding: "0 0.6rem",
                    }}
                  >
                    &#40;search radius must have a valid location zipcode&#41;
                  </Typography>
                </Box>
                <Box
                  className="radius-slider-div"
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1.5fr 0.5fr",
                    alignItems: "center",
                    gap: "2rem",
                    padding: "1rem",
                  }}
                >
                  <Slider
                    className={
                      locationRange == ""
                        ? "radius-slider disabled"
                        : "radius-slider"
                    }
                    defaultValue={0}
                    aria-label="Default"
                    // aria-label="Disabled slider"
                    // valueLabelDisplay="auto"
                    // valueLabelDisplay="on"
                    disabled={ZipCode == "" ? true : false}
                    value={locationRange != "" ? parseInt(locationRange) : 0}
                    // value={typeof value === 'number' ? value : 0}
                    onChange={handleSliderChange}
                  />
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.1rem",
                      color: "#fff !important",
                    }}
                  >
                    <span style={{ width: "45%" }}>
                      {locationRange != "" ? parseInt(locationRange) : 0}
                    </span>
                    <span style={{ width: "55%" }}>Miles</span>
                  </Typography>
                  {/* <Input
                    value={value}
                    size="small"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  /> */}
                  {/* <Typography>
                    5 K.M
                  </Typography> */}
                </Box>
                {/* <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: '1rem', alignItems: 'center' }}>
                  <FormControl variant="filled" sx={{ m: 1, minWidth: 120, marginLeft: "8px !important" }}>
                    <TextField
                      id="filled-number"
                      placeholder="XX miles"
                      type="text"
                      sx={{ backgroundColor: '#fff !important', color: '#2d2d2d !important', borderRadius: '5px', border: '1px solid #faa61a' }}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      inputProps={{ maxLength: 3 }}
                      disabled={RangeValidate}
                      variant="filled"
                      onChange={onRangeChange}
                      value={locationRange}
                    />
                    {locationRange.length > 0 && (
                      <span className="filter-cross-icon"
                        onClick={ResetlocationRange}><ClearIcon sx={{ fontSize: '18px', marginRight: '10px' }} /></span>
                    )}
                  </FormControl>
                </Box> */}
              </Box>

              {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px', }}>
                <Box>
                  <FormLabel sx={{ color: '#fff', marginBottom: '0px', fontFamily: 'GothamBold !important', padding: '0 0.6rem' }}> Range </FormLabel>
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: '1rem', alignItems: 'center' }}>

                  <FormControl variant="filled" sx={{ m: 1, minWidth: 120, marginLeft: "8px !important" }}>
                    <TextField
                      id="filled-number"
                      placeholder="XX miles"
                      type="text"
                      sx={{ backgroundColor: '#fff !important', color: '#2d2d2d !important', borderRadius: '5px', border: '1px solid #faa61a' }}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      inputProps={{ maxLength: 3 }}
                      disabled={RangeValidate}
                      variant="filled"
                      onChange={onRangeChange}
                      value={locationRange}

                    />
                    {locationRange.length > 0 && (
                      <span className="filter-cross-icon"
                        onClick={ResetlocationRange}><ClearIcon sx={{ fontSize: '18px', marginRight: '10px' }} /></span>
                    )}
                  </FormControl>
                </Box>
              </Box> */}

              {/* below codes are commented by pp on 22 apr 2024 due to design changes */}
              {/* <Box className='main-search-grid'>

                <Box className='main-search-item'>
                </Box>
              </Box> */}

              {/* <Box>
                <Box
                  onClick={HandleCalendarPopup}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1.5rem', paddingLeft: '1rem', mt: '0rem', mb: '1rem', cursor: 'pointer' }}
                >
                  <img
                    src={CalendarImg}
                    alt='GoEquipMe'
                    className=''
                    style={{ width: "40px", outline: '4px solid #faa61a', outlineOffset: '6px', borderRadius: '3.5px' }}
                  />
                  <Typography sx={{ color: '#fff', fontFamily: 'GothamBold !important', fontSize: '24px !important' }}>
                    Date Availability
                  </Typography>

                </Box>

                {StartDate && EndDate && (
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem', width: '65%', margin: 'auto' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                      <Typography sx={{ color: '#fff !important', fontWeight: '700' }}>
                        START DATE :
                      </Typography>
                      <Typography sx={{ color: '#fff !important' }}>
                        {moment(StartDate).format("M-D-YYYY")}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                      <Typography sx={{ color: '#fff !important', fontWeight: '700' }}>
                        END DATE :
                      </Typography>
                      <Typography sx={{ color: '#fff !important' }}>
                        {moment(EndDate).format("M-D-YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box> */}

              {/* end by pp*/}
            </Box>

            <Box className="reset-grid">
              <Typography
                className="reset-filter"
                sx={{
                  color: "#ffa700",
                  textDecoration: "underline",
                  cursor: "pointer",
                  // paddingLeft: '3rem !important',
                  fontSize: "25px !important",
                }}
                onClick={(e) => {
                  HandleReset(e);
                }}
                variant="h6"
              >
                Reset
              </Typography>

              <Button
                className="filter-btn"
                sx={{
                  boxSizing: "border-box",
                  bgcolor: "#149247",
                  fontFamily: "GothamBold",
                  color: "#fff",
                  width: "200px !important",
                  textTransform: "none",
                  fontSize: "clamp(16px, 3vw ,22px)",
                  padding: "7px 0.5rem !important",
                  lineHeight: "normal",
                }}
                onClick={HandlepopupFilter}
                ref={seeResult2Ref}
              >
                See Results
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Calendar PopUp */}
      <Modal
        keepMounted
        open={CalendarPopup}
        onClose={() => setCalendarPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popup search-pop"
          sx={{
            ...style,
            borderRadius: " 10px",
            width: "460px",
            // width: matchesMobileX ? 'calc(100vw - 100px)' : 400,
            padding: matchesMobileX ? "20px" : 5,
          }}
        >
          <IconButton
            onClick={() => setCalendarPopup(false)}
            sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
          >
            <Typography
              sx={{
                color: "#faa61a",
                fontSize: "25px",
                paddingRight: "7px",
                fontWeight: "800",
              }}
            >
              X
            </Typography>
          </IconButton>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Box
              // className='date-cal'
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <NewCalendar
                numberOfMonths={1}
                disableMonthPicker
                disableYearPicker
                value={values}
                onChange={(rang) => {
                  let start = rang[0]?.format?.();
                  let end = rang[1]?.format?.();
                  if (start || end) {
                    if (start && end) {
                      let a = [start, end];
                      setValues(a);
                      setStartDate(moment(start).format("M-D-YYYY"));
                      setEndDate(moment(end).format("M-D-YYYY"));
                    }
                  }
                  return;
                }}
                minDate={new Date()}
                // className="twin-cal-custom-calendar"
                range
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "100% !important",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "2rem",
              mt: "1rem",
              alignItems: "center",
            }}
          >
            <Typography
              className="reset-filter"
              sx={{
                color: "#ffa700",
                textDecoration: "underline",
                marginLeft: "20px !important",
                cursor: "pointer",
              }}
              onClick={resetCalendar}
              variant="h6"
            >
              Clear dates
            </Typography>

            <Button
              className="filter-btn"
              sx={{
                bgcolor: "#149247",
                fontFamily: "GothamBold",
                color: "#fff",
                width: "100% !important",
              }}
              onClick={HandleCalendarDates}
            >
              Submit
            </Button>

            {/* <Button className='cancel-btn' sx={{ bgcolor: '#faa61a', fontFamily: 'GothamBold', color: '#fff', width: '100% !important' }}
              onClick={resetCalendar}>
              Clear dates
            </Button> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SearchSection;
