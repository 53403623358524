import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Calendar } from "react-multi-date-picker";
import moment from "moment";
import "./styles.css";
import { useSelector, useDispatch } from "react-redux";
import { IStoreValue } from "utils/types";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { isSelectionPartial } from "utils/partiallyBookedTime";
import { calendarClassName } from "utils/constant";
import CalendarLegend from "./CalendarLegend";
const ExtendRentalCalendar = ({
  equipRentedDates,
  setEquipRentedDates,
  minDateTime,
  equipRentedTimes,
  setCurrentMonth,
  calendarRef,
  isNotClickableDate,
}) => {
  // console.log("minDateTime", minDateTime);
  const dispatch = useDispatch();
  const bookedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentBookedDates
  );
  const blockedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentBlockedDates
  );
  const requestedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentRequestedDates
  );

  const isWeekendAvailable = useSelector(
    (state: IStoreValue) => state.equipmentReducer.rentOnWeekend
  );

  const partiallyBookedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partiallyBookedDates
  );
  const partiallyPendingDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partiallyPendingDates
  );

  const AllReservedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.reservedDates
  );

  const partialDatesCounts = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partialDatesCount
  );

  const AllPartialDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.allPartialDates
  );

  const CalendarDateAngle = useSelector(
    (state: IStoreValue) => state.equipmentReducer.calendarDateAngle
  );

  const validDateClassName = (formattedDate) => {
    if (
      !CalendarDateAngle ||
      !CalendarDateAngle.hasOwnProperty(formattedDate)
    ) {
      return "";
    }

    const { startAngle, endAngle } = CalendarDateAngle[formattedDate];

    const key = `${startAngle}-${endAngle}`;
    return calendarClassName[key] || "";
  };

  const getDateStyle = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const today = moment(minDateTime?.minDate).format("YYYY-MM-DD");
    if (
      formattedDate >= today &&
      (!minDateTime?.maxDate || formattedDate <= minDateTime.maxDate)
    ) {
      if (blockedDates?.includes(formattedDate)) {
        return {
          className: "full-booked",
          style: {
            backgroundColor: "#faa61a",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
          highLight: false,
        };
      } else if (AllPartialDates?.includes(formattedDate)) {
        // const highLight =
        //   PartialOrderRenterList[formattedDate]?.["usersId"]?.length > 1;
        if (AllReservedDates[0]?.hasOwnProperty(formattedDate)) {
          return {
            className: validDateClassName(formattedDate),
            style: {
              color: "#fff",
              cursor: "pointer",
            },
            disabled: false,
            highLight: false,
          };
        } else {
          return {
            className: validDateClassName(formattedDate),
            style: {
              color: "#fff",
              cursor: "not-allowed",
            },
            disabled: true,
            highLight: false,
          };
        }
      } else if (bookedDates?.includes(formattedDate)) {
        return {
          className: "full-booked",
          style: {
            backgroundColor: "#faa61a",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
          highLight: false,
        };
      } else if (requestedDates?.includes(formattedDate)) {
        return {
          className: "full-pending",
          style: {
            backgroundColor: "skyblue",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
          highLight: false,
        };
      }
    }

    return {
      style: {},
      disabled: false,
      className: "",
    };
  };

  const handleRangeChange = (range) => {
    const [start, end] = range;
    let setDate = start;
    if (end) {
      setDate = end;
    }
    if (setDate?.weekDay?.index === 0 && isWeekendAvailable?.sunday === "0") {
      dispatch(
        setalertMessage("This owner does not allow [pickup/delivery] on sunday")
      );
      dispatch(setalertMessagetype("error"));
      setDate = null;
    } else if (
      setDate?.weekDay?.index === 6 &&
      isWeekendAvailable?.saturday === "0"
    ) {
      dispatch(
        setalertMessage(
          "This owner does not allow [pickup/delivery] on Saturday."
        )
      );
      dispatch(setalertMessagetype("error"));
      setDate = null;
    }
    setEquipRentedDates((prev: any) => [prev[0], setDate]);
  };

  const removeSelectionAttributes = () => {
    const elementsToRemove = document.querySelectorAll(
      ".partial-selecting-end,.partial-selecting-start"
    );

    elementsToRemove.forEach((element) => {
      element.classList.remove(
        "partial-selecting-end",
        "partial-selecting-start"
      );
    });
  };

  const getPartialDates = ({ startDate, startTime, endDate, endTime }) => {
    if (startDate === endDate) {
      const partialDate = { startDate: null, endDate: null };
      if (startTime === "00:00:00" && endTime === "23:30:00") {
        //
      } else if (startTime === "00:00:00") {
        partialDate["endDate"] = startDate;
      } else {
        partialDate["startDate"] = startDate;
      }
      return partialDate;
    } else {
      const partialDate = { startDate: null, endDate: null };
      if (startTime !== "00:00:00") {
        partialDate["startDate"] = startDate;
      }
      if (endTime !== "23:30:00") {
        partialDate["endDate"] = endDate;
      }
      return partialDate;
    }
  };

  const ModifyPartialSelection = ({ equipRentedDates, equipRentedTimes }) => {
    if (
      equipRentedDates[0] &&
      equipRentedTimes?.startTime &&
      !equipRentedDates[1] &&
      !equipRentedTimes?.endTime
    ) {
      const startDate = equipRentedDates[0]?.format("YYYY-MM-DD");
      const startElements = document.querySelectorAll(".rmdp-day.rmdp-range");
      startElements.forEach((el) => {
        if (startDate && equipRentedTimes?.startTime !== "00:00:00") {
          const dataStatus = el
            .querySelector("span")
            ?.getAttribute("data-status")
            ?.trim();
          if (
            !el.classList.contains("partial-selecting-start") &&
            !dataStatus
          ) {
            el.classList.add("partial-selecting-start");
          }
        } else {
          el.classList.remove("partial-selecting-start");
        }
      });
    } else if (
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      equipRentedTimes?.startTime &&
      equipRentedTimes?.endTime
    ) {
      const { startDate, endDate } = getPartialDates({
        startDate: equipRentedDates[0]?.format("YYYY-MM-DD"),
        startTime: equipRentedTimes?.startTime,
        endDate: equipRentedDates[1]?.format("YYYY-MM-DD"),
        endTime: equipRentedTimes?.endTime,
      });

      const startElements = document.querySelectorAll(
        ".rmdp-day.rmdp-range.start"
      );
      const endElements = document.querySelectorAll(".rmdp-day.rmdp-range.end");
      endElements.forEach((el) => {
        if (endDate && !AllReservedDates[0]?.hasOwnProperty(endDate)) {
          if (!el.classList.contains("partial-selecting-end")) {
            el.classList.add("partial-selecting-end");
          }
        } else {
          el.classList.remove("partial-selecting-end");
        }
      });
      startElements.forEach((el) => {
        if (startDate && !AllReservedDates[0]?.hasOwnProperty(startDate)) {
          if (!el.classList.contains("partial-selecting-start")) {
            el.classList.add("partial-selecting-start");
          }
        } else {
          el.classList.remove("partial-selecting-start");
        }
      });
    } else {
      removeSelectionAttributes();
    }
  };

  const calendarColorization = () => {
    document
      .querySelectorAll("span[data-status='partial-booked-start']")
      ?.forEach((ele) => {
        const startDate = equipRentedDates[0]?.format("DD");
        if (Number(startDate) === Number(ele.textContent)) {
          ele.setAttribute("data-status", "booked-end-pending-start");
        }
      });
  };

  const handleMonthChange = (newMonth) => {
    const currentMonth = newMonth?.format("MM/YYYY");
    const startMonth = equipRentedDates[0]?.format("MM/YYYY");
    if (currentMonth === startMonth) {
      setTimeout(() => calendarColorization(), 100);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      ModifyPartialSelection({ equipRentedDates, equipRentedTimes });
      if (!equipRentedDates[1]) {
        calendarColorization();
      }
    }, 100);
  }, [equipRentedDates, equipRentedTimes]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.5rem",
        width: "100%",
        maxHeight: { xs: "500px", sm: "700px", md: "750px", overflowY: "auto" },
      }}
    >
      <Box
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          maxWidth: "375px",
          // backgroundColor: "#faa61a0d",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #3a3a3a",
          }}
        >
          <Calendar
            ref={calendarRef}
            value={equipRentedDates}
            mapDays={({ date }) => {
              const formattedDate = date.format("YYYY-MM-DD");
              const startDate = equipRentedDates[0]?.format("YYYY-MM-DD");
              const dateConfig = getDateStyle(date);
              return {
                style: dateConfig.style,
                disabled:
                  formattedDate < startDate ||
                  isNotClickableDate === formattedDate
                    ? true
                    : dateConfig.disabled,
                "data-status": dateConfig.className,
                // "data-cross-line": dateConfig?.highLight ? "line" : "",
              };
            }}
            onChange={handleRangeChange}
            onMonthChange={handleMonthChange}
            range
            numberOfMonths={1}
            format="DD/MM/YYYY"
            minDate={minDateTime?.minDate || new Date()}
            maxDate={minDateTime?.maxDate || ""}
            disableMonthPicker={true}
            disableYearPicker={true}
          />
        </Box>

        <CalendarLegend />
      </Box>
    </Box>
  );
};

export default ExtendRentalCalendar;
