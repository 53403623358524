import { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import calendarIcon from "../../../assets/images/calendar-icon.png";
import chatIcon from "../../../assets/images/chat-icon.png";
import locationIcon from "../../../assets/images/location-white-icon.png";
import moment from "moment";
import { useSelector } from "react-redux";
import { IStoreValue, RentalItem } from "utils/types";
import ConfirmationModal from "./ConfirmationModal";
import gemArrow from "assets/images/gem-arrow.png";
import EquipmentCalender from "../../Calendar/EquipmentCalender";
import { convertTimeZoneShortName } from "utils/commonFxns";
import momentTimezone from "moment-timezone";
import UsCurrencyFormat from "./UsCurrencyFormat";
const RentalRequests = ({
  rentalRequestData,
  handleDetailModalOpen,
  handleDetailModalData,
  handleNotificationRedirection,
  handleModalConfirmation,
  setActionType,
  loading,
  isConfirmationOpen,
  handleConfirmationClose,
  handleConfirmationOpen,
  handlePagination,
  loadMore,
  handleMapRedirection,
  pageNo,
  setPageNo,
  handleRentalYardRedirection,
}) => {
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  // const AuthProfile = useSelector(
  //   (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  // );
  const [isCalendarPopupOpen, setIsCalendarPopupOpen] =
    useState<boolean>(false);
  const [activeEquip, setActiveEquip] = useState<any>({});
  const handleCalenderPopupOpen = () => setIsCalendarPopupOpen(true);
  const handleCalenderPopupClose = () => setIsCalendarPopupOpen(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  // const [pageNo, setPageNo] = useState<number>(1);

  const HandleRentAgain = (ActiveEquip: any) => {
    window.location.href =
      "/detail/" + ActiveEquip["owner_id"] + "/" + ActiveEquip["equip_id"];
  };

  return (
    Object.entries(rentalRequestData).length > 0 &&
    rentalRequestData?.data?.length > 0 && (
      <Box>
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: "#faa61a !important",
              fontFamily: "GothamBold !important",
              mb: 1,
              mt: 2,
              fontSize: {
                xs: "20px",
                sm: "26px",
                md: "32px",
                lg: "36px",
                xl: "40px",
              },
              textTransform: "uppercase",
            }}
          >
            {rentalRequestData?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            // mt:4,
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)",
              gap: "1.5rem",
              alignItems: "center",
            },
          }}
        >
          {rentalRequestData.data.length > 0 &&
            rentalRequestData.data.map((element: RentalItem) => {
              const devilry = element["delivery_charges"] * 2;
              const minimun = element["minimum_delivery_fees"];
              const totalMi = devilry + minimun;
              const _changeInTotal = (totalMi / 100) * 10;
              const refunded_protection =
                element["refunded_protection"] != undefined ||
                element["refunded_protection"] != ""
                  ? element["refunded_protection"]
                  : "";
              const grand_total =
                element["protection_fees_refunded"] != "1"
                  ? element["admin_commission"] != null
                    ? element["total_price"] +
                      element["equipment_protection_fees"] +
                      _changeInTotal +
                      element["admin_commission"]
                    : element["total_price"] +
                      element["equipment_protection_fees"]
                  : element["admin_commission"] != null
                  ? element["total_price"] -
                    (element["order_status"] == "order_canceled"
                      ? element["security_deposit"]
                      : 0) +
                    _changeInTotal +
                    element["admin_commission"] +
                    (element["protection_fees_refunded"] == "1"
                      ? element["equipment_protection_fees"] -
                        (refunded_protection == ""
                          ? element["equipment_protection_fees"]
                          : refunded_protection)
                      : 0)
                  : element["total_price"];
              const background =
                "linear-gradient(180deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, 0))";
              const filter = "grayscale(100%)";
              return (
                <Box
                  sx={{
                    // background: element?.request_status === "4"?background:"#484840",
                    background: "#484840",
                    filter: element?.request_status === "4" ? filter : "",
                    pointerEvents:
                      element?.request_status === "4" ? "none" : "",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                    padding: "1rem",
                    position: "relative",
                    height: {
                      xs: "auto",
                      sm: "auto",
                      md: "100%",
                      lg: "100%",
                      xl: "100%",
                    },
                    boxSizing: "border-box",
                    width: "100%",
                    // mt:2
                  }}
                  key={element.order_id}
                >
                  {element?.request_type !== "main_order" && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-15px",
                        right: "20px",
                        padding: "5px 10px",
                        color: "#fff",
                        background: "green",
                        fontFamily: "Montserrat !important",
                        fontWeight: "600",
                        borderRadius: "7px",
                        fontSize: "14px",
                        textTransform:"uppercase",
                      }}
                    >
                      Order Extend Request
                    </Box>
                  )}
                  {/* Image Section */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={element?.thumbnail}
                        sx={{
                          width: {
                            xs: "55px",
                            sm: "70px",
                            md: "75px",
                            lg: "80px",
                            xl: "85px",
                          },
                          height: {
                            xs: "45px",
                            sm: "60px",
                            md: "65px",
                            lg: "70px",
                            xl: "75px",
                          },
                          objectFit: "cover",
                          borderRadius: "5px",
                          border: "2px solid #faa61a",
                          boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.5)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (AuthUserId === element?.owner_id) {
                            handleRentalYardRedirection(element?.equip_id);
                          } else {
                            HandleRentAgain(element);
                          }
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#faa61a",
                            fontWeight: "700",
                            fontSize: {
                              xs: "14px",
                              sm: "18px",
                              md: "20px",
                              lg: "23px",
                              xl: "24px",
                            },
                            fontFamily: "Montserrat !important",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (AuthUserId === element?.owner_id) {
                              handleRentalYardRedirection(element?.equip_id);
                            } else {
                              HandleRentAgain(element);
                            }
                          }}
                        >
                          {element?.make
                            ? element?.make.length > 15
                              ? element?.make.slice(0, 15) + "..."
                              : element?.make
                            : "N/A"}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "13px",
                              lg: "14px",
                              xl: "14px",
                            },
                          }}
                        >
                          Order ID #
                          {element?.request_type === "extend_order"
                            ? element?.parent_order_id
                            : element?.order_id}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#faa61a",
                            textDecorationLine: "underline",
                            textDecorationColor: "#faa61a",
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "13px",
                              lg: "13px",
                              xl: "14px",
                            },
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleDetailModalData(element, "request");
                            handleDetailModalOpen();
                          }}
                        >
                          Rental Details
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "15px",
                            md: "16px",
                            lg: "17px",
                            xl: "18px",
                          },
                          fontWeight: "500",
                        }}
                      >
                        {AuthUserId === element?.owner_id
                          ? "You'll Earn"
                          : "Pending"}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "15px",
                            sm: "20px",
                            md: "22px",
                            lg: "24px",
                            xl: "26px",
                          },
                          fontWeight: "700",
                        }}
                      >
                        {AuthUserId === element?.owner_id ? (
                          <UsCurrencyFormat amount={element?.total_price} />
                        ) : (
                          <UsCurrencyFormat amount={grand_total} />
                        )}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Detail Section */}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "1rem",
                      padding: "0.8rem 0.2rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: {
                          xs: "0.5rem",
                          sm: "0.5rem",
                          md: "0.5rem",
                          lg: "0.5rem",
                          xl: "0.5rem",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "8px",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={calendarIcon}
                          alt=""
                          sx={{
                            width: {
                              xs: "12px",
                              sm: "15px",
                              md: "18px",
                              lg: "20px",
                              xl: "20px",
                            },
                            mt: "2px",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: "4px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                              }}
                            >
                              Start:
                            </Typography>
                            <Typography
                              sx={{
                                color: "#faa61a",
                                textDecoration: "underline",
                                textDecorationColor: "#faa61a",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setActiveEquip(element);
                                handleCalenderPopupOpen();
                              }}
                            >
                              {/* {moment(
                                `${element.start_date} ${element.start_time}`
                              ).format("MM/DD hh:mm a")} */}
                              {element?.timezone
                                ? convertTimeZoneShortName(
                                    momentTimezone
                                      .tz(
                                        `${element.start_date} ${element.start_time}`,
                                        element?.timezone
                                      )
                                      .format("MM/DD hh:mm a (z)")
                                  )
                                : moment(
                                    `${element.start_date} ${element.start_time}`
                                  ).format("MM/DD hh:mm a (UTC)")}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: "4px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                                cursor: "pointer",
                              }}
                            >
                              End:
                            </Typography>
                            <Typography
                              sx={{
                                color: "#faa61a",
                                textDecoration: "underline",
                                textDecorationColor: "#faa61a",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                              }}
                              onClick={() => {
                                setActiveEquip(element);
                                handleCalenderPopupOpen();
                              }}
                            >
                              {/* {moment(
                                `${element.end_date} ${element.end_time}`
                              ).format("MM/DD hh:mm a")} */}
                              {element?.timezone
                                ? convertTimeZoneShortName(
                                    momentTimezone
                                      .tz(
                                        `${element.end_date} ${element.end_time}`,
                                        element?.timezone
                                      )
                                      .format("MM/DD hh:mm a (z)")
                                  )
                                : moment(
                                    `${element.end_date} ${element.end_time}`
                                  ).format("MM/DD hh:mm a (UTC)")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={chatIcon}
                          alt=""
                          sx={{
                            width: {
                              xs: "12px",
                              sm: "15px",
                              md: "18px",
                              lg: "20px",
                              xl: "20px",
                            },
                            mt: "-1px",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: "4px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#faa61a",
                                textDecoration: "underline",
                                textDecorationColor: "#faa61a",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() =>
                                handleNotificationRedirection(element)
                              }
                            >
                              Message{" "}
                              {AuthUserId === element?.owner_id
                                ? "Renter"
                                : "Owner"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "8px",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={locationIcon}
                          alt=""
                          sx={{
                            width: {
                              xs: "12px",
                              sm: "15px",
                              md: "18px",
                              lg: "20px",
                              xl: "20px",
                            },
                            mt: "2px",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "baseline",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                              }}
                            >
                              {AuthUserId === element?.owner_id
                                ? element?.delivery_required === "1"
                                  ? "Deliver to"
                                  : "Renter Pickup"
                                : "Pickup / Delivery"}
                              {":"}
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  AuthUserId === element?.owner_id
                                    ? "#faa61a"
                                    : "#fff",
                                textDecoration:
                                  AuthUserId === element?.owner_id
                                    ? "underline"
                                    : "none",
                                textDecorationColor:
                                  AuthUserId === element?.owner_id
                                    ? "#faa61a"
                                    : "#fff",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                                cursor:
                                  AuthUserId === element?.owner_id
                                    ? "pointer"
                                    : "default",
                              }}
                              onClick={() => {
                                if (AuthUserId === element?.owner_id) {
                                  handleMapRedirection(element);
                                }
                              }}
                            >
                              {AuthUserId === element?.owner_id
                                ? element?.delivery_required === "1"
                                  ? element?.delivery_address
                                  : element?.equip_address
                                : "Awaiting Approval"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {/* Accept/reject */}
                  <Box
                    sx={{
                      // border: "3px solid #E61A23",
                      borderRadius: "10px",
                      padding: "0.5rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.5rem",
                      // boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.5)",
                    }}
                  >
                    {AuthUserId === element?.owner_id ? (
                      <>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Montserrat !important",
                            fontWeight: "500",
                            fontSize: {
                              xs: "11px",
                              sm: "14px",
                              md: "16px",
                              lg: "18px",
                              xl: "20px",
                            },
                          }}
                        >
                          {element?.renter?.replace(
                            element?.renter[0],
                            element?.renter[0]?.toUpperCase()
                          )}{" "}
                          has requested a rental.
                        </Typography>
                        {element?.request_status === "4" ? (
                          <Button
                            sx={{
                              textTransform: "capitalize",
                              background: "#E61A23",
                              color: "#fff",
                              fontFamily: "Montserrat !important",
                              borderRadius: "8px",
                              fontWeight: "800",
                              padding: "7px 15px",
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "18px",
                              },
                              "&:hover": {
                                background: "#b81118",
                              },
                            }}
                          >
                            Rental Request Has Expired
                          </Button>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "1rem",
                            }}
                          >
                            <Button
                              sx={{
                                textTransform: "capitalize",
                                background: "#E61A23",
                                color: "#fff",
                                fontFamily: "Montserrat !important",
                                borderRadius: "8px",
                                fontWeight: "800",
                                padding: "7px 15px",
                                fontSize: {
                                  xs: "12px",
                                  sm: "13px",
                                  md: "14px",
                                  lg: "16px",
                                  xl: "18px",
                                },
                                "&:hover": {
                                  background: "#b81118",
                                },
                              }}
                              onClick={() => {
                                setConfirmationMessage("REJECT");
                                setActionType("reject");
                                handleDetailModalData(element);
                                handleConfirmationOpen();
                              }}
                            >
                              Reject
                            </Button>
                            <Button
                              sx={{
                                textTransform: "capitalize",
                                background: "#008001",
                                color: "#fff",
                                fontFamily: "Montserrat !important",
                                borderRadius: "8px",
                                fontWeight: "800",
                                padding: "7px 15px",
                                fontSize: {
                                  xs: "12px",
                                  sm: "13px",
                                  md: "14px",
                                  lg: "16px",
                                  xl: "18px",
                                },
                                "&:hover": {
                                  background: "#006001",
                                },
                              }}
                              onClick={() => {
                                setConfirmationMessage("ACCEPT");
                                setActionType("accept");
                                handleDetailModalData(element);
                                handleConfirmationOpen();
                              }}
                            >
                              Accept
                            </Button>
                          </Box>
                        )}
                      </>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Montserrat !important",
                            fontWeight: "500",
                            fontSize: {
                              xs: "11px",
                              sm: "14px",
                              md: "16px",
                              lg: "18px",
                              xl: "20px",
                            },
                          }}
                        >
                          You have requested a rental.
                        </Typography>
                        {element?.request_status === "4" ? (
                          <Button
                            sx={{
                              textTransform: "capitalize",
                              background: "#E61A23",
                              color: "#fff",
                              fontFamily: "Montserrat !important",
                              borderRadius: "8px",
                              fontWeight: "800",
                              padding: "7px 15px",
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "18px",
                              },
                              "&:hover": {
                                background: "#b81118",
                              },
                            }}
                          >
                            Rental Request Has Expired
                          </Button>
                        ) : (
                          <Button
                            sx={{
                              textTransform: "capitalize",
                              background: "#E61A23",
                              color: "#fff",
                              fontFamily: "Montserrat !important",
                              borderRadius: "8px",
                              fontWeight: "800",
                              padding: "7px 15px",
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                                xl: "18px",
                              },
                              "&:hover": {
                                background: "#b81118",
                              },
                            }}
                            onClick={() => {
                              setConfirmationMessage("CANCEL");
                              setActionType("cancel");
                              handleDetailModalData(element);
                              handleConfirmationOpen();
                            }}
                          >
                            Cancel Request
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              );
            })}
        </Box>
        {rentalRequestData.data.length === 0 && (
          <Box
            sx={{
              width: "100%",
              padding: "2rem 1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "15px",
                  lg: "16px",
                  xl: "18px",
                },
                fontFamily: "Montserrat !important",
                fontWeight: "800",
              }}
            >
              No Records Found!
            </Typography>
          </Box>
        )}
        {rentalRequestData?.total > rentalRequestData?.data?.length && (
          <Box className="pagination-section">
            {loadMore ? (
              <CircularProgress />
            ) : (
              <Box
                className="btn-load-more"
                onClick={() => {
                  setPageNo((prev) => prev + 1);
                  handlePagination("RENTAL REQUESTS", pageNo);
                }}
              >
                {"Load More"}
                <img
                  src={gemArrow}
                  alt="GoEquipMe"
                  className="gem-arrow arrow-load-more"
                />
              </Box>
            )}
          </Box>
        )}
        <ConfirmationModal
          isConfirmationOpen={isConfirmationOpen}
          handleConfirmationClose={handleConfirmationClose}
          onConfirm={handleModalConfirmation}
          message={"Are you sure want to " + confirmationMessage + "?"}
          loading={loading}
        />
        <EquipmentCalender
          activeEquip={activeEquip}
          isCalendarPopupOpen={isCalendarPopupOpen}
          handleCalenderPopupClose={handleCalenderPopupClose}
        />
      </Box>
    )
  );
};

export default RentalRequests;
