import { Modal, Box, IconButton, Button } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomCalendarTime from "components/Calendar/CustomCalendarTime";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  //  overflowX:'hidden',
  bgcolor: "#2e2e2e",
  border: "5px solid #faa61a",
  borderRadius: "10px",
  outline: "none !important",
  // p: 3,
  width: {
    xs: "80%",
    sm: "380px",
    md: "420px",
    lg: "420px",
    xl: "420px",
    // sm:'auto'
  },
};
const CustomTimeModal = ({
  open,
  handleClose,
  label,
  timeZoneAbbr,
  equipRentedTimes,
  setEquipRentedTimes,
  minDateTime,
  equipRentedDates,
  openInModal,
  handleTimeSelectionOnNext,
  setTempRentalTimes = undefined,
  modalOpen = false,
  extendIsOpen = false,
}) => {
  return (
    <Modal open={open} onClose={handleClose} keepMounted>
      <Box sx={modalStyle}>
        <IconButton
          onClick={(event) => handleClose(event, "cross")}
          sx={{
            position: "absolute",
            top: "-20px",
            right: {
              xs: "5px",
              sm: "-20px",
              md: "-20px",
              lg: "-20px",
              xl: "-20px",
            },
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Box
            sx={{
              maxHeight: {
                xs: "530px",
                sm: "680px",
                md: "720px",
                overflowY: "auto",
              },
            }}
          >
            <CustomCalendarTime
              label={label}
              timeZoneAbbr={timeZoneAbbr || "UTC"}
              equipRentedTimes={equipRentedTimes}
              setEquipRentedTimes={setEquipRentedTimes}
              minDateTime={minDateTime}
              equipRentedDates={equipRentedDates}
              openInModal={openInModal}
              setTempRentalTimes={setTempRentalTimes}
              modalOpen={modalOpen}
              extendIsOpen = {extendIsOpen}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              mt: 2,
              mb: 1,
            }}
          >
            <Button
              sx={{
                background: "#179346",
                fontFamily: "Montserrat !important",
                fontWeight: "800",
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "17px",
                  xl: "18px",
                },
                color: "#fff",
                textTransform: "capitalize",
                padding: "2.5px 20px",
                "&:hover": {
                  background: "green",
                },
              }}
              disabled={
                label?.includes("Pickup")
                  ? !equipRentedTimes?.startTime
                  : label?.includes("Drop-off")
                  ? !equipRentedTimes?.endTime
                  : false
              }
              onClick={handleTimeSelectionOnNext}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomTimeModal;
