import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import LocationIcon from "assets/images/location.png";
import UsCurrencyFormat from "components/MyofficeTrailer/EquipmentOrders/UsCurrencyFormat";
import GOOGLELOCATION from "../../components/equipment-list/GoogleautoComplete";
import { useState, useRef } from "react";

const subheadingStyle = {
  color: "#faa61a",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  textTransform: "uppercase",
  mb: 0.5,
};
const textStyle = {
  color: "#fff",
  fontFamily: "Montserrat !important",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  fontWeight: "500",
};
const TransportOptions = ({
  priceDetails,
  DeliveryInstructionsval,
  DeliveryInstructionsvallength,
  DeliveryInstructions,
  calculateDistance,
  transportCharge,
  chooseTransport,
  handleTransportSelection,
  equipmentData,
  inRef,
  searchRef,
  autoCompAddress,
  setAutoCompAddress,
}) => {
  return (
    <Box sx={{ mt: 4, mb: 2 }}>
      <Typography
        sx={{
          ...subheadingStyle,
          fontFamily: "GothamBold !important",
          fontWeight: "900",
        }}
      >
        Transport Options
      </Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="renter"
        name="radio-buttons-group"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0rem",
        }}
        onChange={handleTransportSelection}
      >
        <FormControlLabel
          sx={textStyle}
          value="renter"
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: "1.5rem",
                  color: "#fff",
                },
                "&.Mui-checked .MuiSvgIcon-root": {
                  color: "#fff",
                },
              }}
              checked={chooseTransport === "renter"}
            />
          }
          label={
            <Typography sx={textStyle}>
              I will pick up and return (Renter transports)
            </Typography>
          }
        />
        {chooseTransport === "renter" && (
          <Box sx={{ pl: 4, mb: 2, fontFamily: "Montserrat !important" }}>
            <Typography
              sx={{
                ...textStyle,
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              By selecting Renter Pick Up and Return, you verify that you have
              the necessary transport equipment and assume all liability.
            </Typography>
            <Typography
              sx={{
                ...subheadingStyle,
                fontFamily: "GothamBold !important",
                fontWeight: "900",
                mt: 1,
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "13px",
                  lg: "14px",
                  xl: "15px",
                },
              }}
            >
              Equipment Specifications
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      ...textStyle,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >
                    Height
                  </Typography>
                  <Typography
                    sx={{
                      ...textStyle,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >{`${equipmentData?.height} ${equipmentData?.height_unit_type}.`}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      ...textStyle,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >
                    Length
                  </Typography>
                  <Typography
                    sx={{
                      ...textStyle,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >{`${equipmentData?.length} ${equipmentData?.length_unit_type}.`}</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <Box
                  // sx={{
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "flex-start",
                  //   gap: "2rem",
                  // }}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      ...textStyle,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >
                    Width
                  </Typography>
                  <Typography
                    sx={{
                      ...textStyle,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >{`${equipmentData?.width} ${equipmentData?.width_unit_type}.`}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      ...textStyle,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >
                    Weight
                  </Typography>
                  <Typography
                    sx={{
                      ...textStyle,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >{`${equipmentData?.weight} ${
                    equipmentData?.weight_unit_type || "lbs"
                  }.`}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <FormControlLabel
          sx={{
            ...textStyle,
            opacity: equipmentData["owner_pickup_return"] === "0" ? 0.5 : 1,
          }}
          value="owner"
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: "1.5rem", // Adjust size as needed
                  color: "#fff", // White ring color when unchecked
                },
                "&.Mui-checked .MuiSvgIcon-root": {
                  color: "#fff", // Ensures outer ring stays white when checked
                },
              }}
              checked={chooseTransport === "owner"}
            />
          }
          label={
            <Typography sx={textStyle}>
              Delivery and pick up needed (Owner transports)
            </Typography>
          }
          disabled={equipmentData["owner_pickup_return"] === "0"}
        />
      </RadioGroup>
      {chooseTransport === "owner" && (
        <Box className="owner-section-detail">
          <Box className="secondoption">
            <Box className="renter-section-grid">
              <Box></Box>
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                  }}
                >
                  Please tell us the delivery location
                </Typography>
              </Box>
            </Box>

            <Box className="renter-section-grid">
              <Box className="secondoption-img">
                <img src={LocationIcon} alt="" />
              </Box>
              <Box className="map-location">
                {/* {!transportoptionpopup && (
                        )} */}
                <GOOGLELOCATION
                  onPress={(center, address) => {
                    // console.log("Adress : ", address);
                    setAutoCompAddress(address.address);
                    if (center?.lat && center?.lng) {
                      calculateDistance(center, address);
                    }
                  }}
                  inputRefs={inRef}
                  searchRef={searchRef}
                  equipAddress={autoCompAddress}
                />
              </Box>
            </Box>
            <Box className="secondoption-inner">
              <Box className="renter-section-grid">
                <Box></Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "clamp(12px, 3vw, 12px) !important",
                    }}
                  >
                    Delivery Instructions. Provide any instructions that might
                    be helpful to the owner for the delivery.
                  </Typography>
                </Box>
              </Box>
              <Box className="renter-section-grid">
                <Box></Box>
                <Box>
                  <Box className="secondoption-inner-div">
                    <TextField
                      id="outlined-multiline-static"
                      //label="Max. 400 characters"
                      placeholder={"Max. 400 characters"}
                      multiline
                      maxRows={4}
                      rows={4}
                      fullWidth
                      sx={{
                        color: "#adadad",
                        "& .MuiInputBase-root": {
                          color: "#000",
                          background: "#fff",
                          fontFamily: "GothamBold !important",
                          fontSize: "clamp(10px, 3vw, 12px) !important",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#adadad",
                        },
                        "& label.Mui-focused": {
                          color: "#000",
                          fontWeight: "bold",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#2e2e2e",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& input": {
                            color: "#000 !important",
                          },
                          "& fieldset": {
                            borderColor: "#faa61a",
                            // backgroundColor: "#fff",
                            color: "#faa61a !important",
                          },
                          "&:hover fieldset": {
                            borderColor: "#2e2e2e",
                            border: "2px solid #faa61a",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#faa61a",
                            backgroundColor: "transparent",
                            color: "#000 !important",
                          },
                        },
                      }}
                      onChange={(e) => DeliveryInstructions(e)}
                      value={DeliveryInstructionsval}
                    />
                    <Typography className="ltr-left" sx={{ color: "#fff" }}>
                      {DeliveryInstructionsvallength} characters left
                    </Typography>

                    <Box className="equipment-rate-inner">
                      <Box
                        className="rate-list-grids"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <Typography sx={{ color: "#fff" }}>
                          Min. Transport (Round Trip) /
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            flex: "1",
                          }}
                          className="dash-border"
                        ></Typography>
                        <Typography sx={{ color: "#fff" }}>
                          <UsCurrencyFormat
                            amount={transportCharge?.minimumDeliveryCharge || 0}
                          />
                          {/* $ */}
                          {/* {new Intl.NumberFormat("en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }).format("result["minimum_delivery_charges"]")} */}
                        </Typography>
                      </Box>
                      <Box
                        className="rate-list-grids"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <Typography sx={{ color: "#fff" }}>
                          Additional Miles Charge
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            flex: "1",
                          }}
                          className="dash-border"
                        ></Typography>
                        <Typography sx={{ color: "#fff" }}>
                          {/* $
                                {new Intl.NumberFormat("en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }).format(deliveryPrice)} */}
                          <UsCurrencyFormat
                            amount={transportCharge?.additionalDeliveryCharge}
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <hr />

            <Box className="equipment-rate-inner">
              <Box
                className="rate-list-grids"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <Typography
                  sx={{
                    color: "#faa61a",
                    fontWeight: "bold",
                    fontFamily: "GothamBold !important",
                    marginLeft: "25px",
                  }}
                >
                  Transport Total
                </Typography>
                <Typography
                  sx={{ color: "#fff", flex: "1" }}
                  className="dash-border"
                ></Typography>
                <Typography
                  sx={{
                    color: "#faa61a",
                    fontWeight: "bold",
                    fontFamily: "GothamBold !important",
                  }}
                >
                  {/* $
                        {transportTotal
                          ? new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }).format(transportTotal)
                          : "0.00"} */}
                  <UsCurrencyFormat amount={priceDetails?.deliveryPrice} />
                </Typography>
              </Box>
              <Box
                className="rate-list-grids"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              ></Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TransportOptions;
