import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  Link,
  Backdrop,
  CircularProgress,
  Slide,
} from "@mui/material";
import locationIcon from "assets/images/location.png";
import msgIcon from "assets/images/details-page/msgg.png";
import chatIcon from "assets/images/location.png";
import dummyProfile from "assets/images/dummy-profile-pic.jpeg";
import leftArrow from "assets/images/myofficetrailer/left_yellow.png";
import rightArrow from "assets/images/myofficetrailer/right_yellow.png";
import "./styles.scss";
import Autorenew from "@mui/icons-material/Autorenew";
import btnLeftArrow from "assets/images/gem-arrow.png";
import btnRightArrow from "assets/images/gem-arrow.png";
import phnIcon from "assets/images/serviceDirectory/Scall.png";
import photoIcon from "assets/images/serviceDirectory/Sphoto.png";
import webIcon from "assets/images/serviceDirectory/global.png";
import locIcon from "assets/images/serviceDirectory/newPin.png";
import locpin from "assets/images/serviceDirectory/locpin.png";
import textIcon from "assets/images/serviceDirectory/text_icon.png";
import userIcon from "assets/images/serviceDirectory/user_icon.png";

import MainLayout from "layouts/MainLayout";
import { EquipmentAPIs } from "../../service/api-service";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalOpen,
  setLoginStatus,
  setaccessToken,
  setSessionExpire,
  setalertMessagetype,
  setalertMessage,
  setLoginModalOpen,
  setLogInLoading,
  SetRedirectPage,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import { useEffect, useState, useRef, useCallback } from "react";
import GoogleMapLocation from "../equipment-list/GoogleautoComplete";
import Geocode from "react-geocode";
import debounce from "lodash.debounce";

// import { Link } from 'react-router-dom'
// popups/Models
import PhotosModal from "./PhotosModal";
import SendTextMsgModal from "./SendTextMsgModal";
import EmailRedirectModal from "./EmailRedirectModal";
import { useNavigate } from "react-router-dom";
import MakeServiceLiveModal from "./MakeServiceLiveModal";
import EditServicePhotosModal from "./EditServicePhotosModal";
import EditServiceLocationModal from "./editServiceLocationModal";
import EditServiceDescriptionModal from "./EditServiceDescriptionModal";
import DeleteServiceModal from "./DeleteServiceModal";
const NewCssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});

const ServiceDirectory = () => {
  const inRef = useRef(null);
  const searchRef = useRef();
  // const EditRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const UserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );

  const LoginPopup = () => {
    dispatch(SetRedirectPage("add-service"));
    dispatch(setLoginModalOpen(true));
    dispatch(setLogInLoading(false));
  };

  const [loading, setLoading] = useState(false);
  const [showFooterBar, setShowFooterBar] = useState(true);
  // State used for serviceListing
  const [serviceList, setServiceList] = useState([]);
  // for categories listing in category select in filter
  const [serviceCategories, setServiceCategories] = useState([]);

  // for filters values
  const [filterCategory, setFilterCategory] = useState("");
  const [filterKeyword, setFilterKeyword] = useState("");
  // const [filterLocation, setFilterLocation] = useState('');
  const [filterState, setFilterState] = useState("");
  const [filterCity, setFilterCity] = useState("");
  // const [EquipLocation, setEquipLocation] = useState({
  //     "lat": '',
  //     "lng": '',
  //   });
  const [EquipAddress, setEquipAddress] = useState("");
  const [FullAddress, setFullAddress] = useState({
    address: "",
    city: "",
    state: "",
    short_state: "",
    postal_code: "",
    country: "",
    lat: "",
    lng: "",
  });
  // end filter value states
  // for paginations
  const [page, setPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const [PhotosModelOpen, setPhotosModelOpen] = useState(false);
  const [textModelOpen, setTextModelOpen] = useState(false);
  const [emailModelOpen, setEmailModelOpen] = useState(false);

  const [sendTextMobileNo, setSendTextMobileNo] = useState("");
  const [photosArray, setPhotosArray] = useState([]);
  const [sendToEmail, setSendToEmail] = useState("");

  const [expandedItems, setExpandedItems] = useState([]);

  const [prevScrollpos, setPrevScrollpos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const scrollThreshold = 80;

  const searchFilterRef = useRef(null);
  // const onLocationInputChange = debounce(useCallback(async (e) => {
  //     const subString = e.target.value
  //     if (subString !== '') {
  //       const locationsRes = await EquipmentAPIs.getLocation({ subString: subString })
  //       setLocations(locationsRes.data.rows)
  //     }
  //   }, []), 1000)


  const [serviceData, setServiceData] = useState<any>({});
  const [serviceDescModalOpen, setServiceDescModalOpen] = useState<boolean>(false);
  const [serviceLocationModalOpen, setServiceLocationModalOpen] = useState<boolean>(false);
  const [servicePhotosModalOpen, setServicePhotosModalOpen] = useState<boolean>(false);
  const [serviceLiveModalOpen, setServiceLiveModalOpen] = useState<boolean>(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState<boolean>(false);
  const [deleteServiceId, setDeleteServiceId] = useState("");
  // to reset filter values
  const resetFilters = useCallback(() => {
    setFilterCategory("");
    // setFilterLocation('');
    setFilterKeyword("");
    // setEquipAddress('');
    setFilterState("");
    setFilterCity("");
    // setFullAddress({
    //   "address": "",
    //   "city": "",
    //   "state": "",
    //   "short_state": "",
    //   "postal_code": "",
    //   "country": "",
    //   "lat": "",
    //   "lng": "",
    // });
    setPage(0);
  }, [
    filterCategory,
    filterKeyword,
    EquipAddress,
    page,
    filterState,
    filterCity,
  ]);
  // to fetch service categories from api
  const fetchServiceCategories = async () => {
    try {
      // Call the API to get the service categories
      setLoading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const serviceCategories = await EquipmentAPIs.fetchServiceCategories();
      // console.log("serviceCategories: ", serviceCategories);

      if (serviceCategories["type"] == "RXSUCCESS") {
        // let newData = serviceList['data'].filter(data => data.is_deleted != 1);
        // console.log("serviceCategories Data length : ", newData.length);
        setServiceCategories(
          serviceCategories["data"]
            .filter((data) => data.status == 1)
            .sort((a, b) => a.title.localeCompare(b.title))
        );
      } else {
        // Display an error message if deletion fails
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("error while fetching service Categories."));
        // setDeletePopup(false)
      }
      setLoading(false);
    } catch (err) {
      // Handle errors that occur during the deletion process
      console.log(err);
      dispatch(setalertMessagetype("error"));
      dispatch(setalertMessage("error while fetching service Categories."));
      setLoading(false);
    }
  };

  // to fetch service list from api
  const fetchService = async () => {
    try {
      setLoading(true);
      let data = {};
      // Call the API to delete the equipment
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const servicesList = await EquipmentAPIs.fetchService(data, headers);
      // console.log("servicesList: ", servicesList);

      // Check if the deletion was successful
      if (servicesList["type"] == "RXSUCCESS") {
        // let newData = servicesList['data'].filter(data => data.is_deleted != 1);
        // console.log("newData: ", newData);
        // console.log("newData length : ", newData.length);
        // setServiceList(newData)
        setServiceList(
          servicesList["data"].filter((data) => data.is_deleted != 1)
        );

        /***** */
        // let abc = [];
        // for (let i = 0; i < 20; i++) {
        //     abc.push(servicesList["data"]);
        // }
        // setServiceList(abc);

        setLoading(false);
      } else {
        // Display an error message if deletion fails
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("error while fetching service."));
        setLoading(false);
        // setDeletePopup(false)
        console.log("error while fetching service: ");
      }
    } catch (err) {
      // Handle errors that occur during the deletion process
      console.log(err);
      dispatch(setalertMessagetype("error"));
      dispatch(setalertMessage("error while fetching service."));
      setLoading(false);
    }
  };

  const updateService = async (serviceLive = "2") => {
    try {
      console.log("updating service data: ", serviceData.service_live_status);
      console.log("updating serviceLive: ", serviceLive);
      // setLoading(false);
      // return;
      if (!serviceData.company_name.trim()) {
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("Please add a Company name."));
        return false;
      }

      if (!serviceData.service_category) {
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("Please select a Service Category."));
        return false;
      }

      if (serviceData.email && serviceData.email != "") {
        let emailRegex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmailValid = emailRegex.test(serviceData.email);
        if (!isEmailValid) {
          dispatch(setalertMessagetype("error"));
          dispatch(setalertMessage("Please enter a valid mail."));
          return false;
        }
      }

      if (serviceData.phone_voice && serviceData.phone_voice !== "") {
        if (serviceData.phone_voice.length < 10) {
          dispatch(setalertMessagetype("error"));
          dispatch(setalertMessage("Please enter a valid phone/voice."));
          return false;
        }
      }

      if (serviceData.phone_text && serviceData.phone_text !== "") {
        if (serviceData.phone_text.length < 10) {
          dispatch(setalertMessagetype("error"));
          dispatch(setalertMessage("Please enter a valid phone/text."));
          return false;
        }
      }

      setLoading(true);
      const data = {
        company_name: serviceData.company_name,
        service_category: serviceData.service_category,
        key_description_search: serviceData.key_description_search,
        address: serviceData.address,
        city: serviceData.city,
        state: serviceData.state,
        short_state_name: serviceData.short_state_name,
        country: serviceData.country,
        latitude: serviceData.latitude,
        longitude: serviceData.longitude,
        phone_voice: serviceData.phone_voice,
        phone_text: serviceData.phone_text,
        email: serviceData.email,
        website: serviceData.website,
        service_description: serviceData.service_description,
        user_id: AuthUserID,
        service_id: serviceData.id,
        location_status: serviceData.location_status,
        service_live_status:
          serviceLive == "2" ? serviceData.service_live_status : serviceLive,
      };

      // let params = JSON.stringify({
      //     company_name: this.state.company_name,
      //     service_category: this.state.category,
      //     key_description_search: this.state.search_term,
      //     address: this.state.address,
      //     city: this.state.city,
      //     state: this.state.state,
      //     short_state_name: this.state.short_state_name,
      //     country: this.state.country,
      //     latitude: this.state.latitude,
      //     longitude: this.state.longitude,
      //     phone_voice: this.state.phone_voice,
      //     phone_text: this.state.phone_txt,
      //     email: this.state.email,
      //     website: this.state.website,
      //     service_description: this.state.service_description,
      //     user_id: this.state.user_id,
      //     service_id: this.state.service_id,
      //     location_status: this.state.hideLocation == false ? 0 : 1,
      //   });

      console.log("data in update Service:", data);

      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const updateService = await EquipmentAPIs.updateServiceDetails(
        data,
        headers
      );
      console.log("updateService: ", updateService);
      if (updateService["type"] == "RXSUCCESS") {
        console.log("service updated successfully");
        dispatch(setalertMessagetype("success"));
        dispatch(setalertMessage("Service updated successfully."));
        // setLoading(false);
        // setTimeout(() => {
        //     navigate("/service-directory");
        // }, 1000);
        setServiceDescModalOpen(false);
        setServiceLocationModalOpen(false);
        setServiceLiveModalOpen(false);
        fetchService();
      } else {
        // Display an error message if deletion fails
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("error while adding service."));
        setLoading(false);
      }
    } catch (error) {
      console.log("error in updateService fxn: ", error.message);
      dispatch(setalertMessagetype("error"));
      dispatch(setalertMessage("error while updating service."));
      setLoading(false);
    }
  };

  // api for searching services
  const searchService = async () => {
    try {
      setLoading(true);
      // Call the API to get the service categories
      const data = {
        category_name: filterCategory,
        key_description_search: filterKeyword,
        city: filterCity,
        state: filterState,
        page: 0,
      };
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const searchService = await EquipmentAPIs.searchService(data, headers);
      console.log("searchService: ", searchService);

      if (searchService["type"] == "RXSUCCESS") {
        // let newData = serviceList['data'].filter(data => data.is_deleted != 1);
        console.log("newData length : ", searchService["data"].length);
        // console.log("newData : ", searchService);
        setServiceList(
          searchService["data"].filter((data) => data.is_deleted != 1)
        );
        setLoading(false);
        // setServiceCategories(serviceCategories['data'].filter(data => data.status == 1))
      } else {
        // Display an error message if deletion fails
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("error while fetching service search."));
        setLoading(false);
        // setDeletePopup(false)
      }
    } catch (err) {
      // Handle errors that occur during the deletion process
      console.log(err);
      dispatch(setalertMessagetype("error"));
      dispatch(setalertMessage("error while fetching service search."));
      setLoading(false);
    }
  };

  // Get city state country address etc from geocode
  const getFullAddress = async (center) => {
    if (center.lat != null && center.lng != null) {
      // Use the Geocode API to fetch address details based on latitude and longitude.
      Geocode.fromLatLng(center.lat, center.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let city, state, country, short_state, postal_code;
          // Extract address components from the Geocode API response.
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  short_state =
                    response.results[0].address_components[i].short_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
                // case "administrative_area_level_1":
                //   short_state = response.results[0].address_components[i].short_name;
                //   break;
                case "postal_code":
                  postal_code =
                    response.results[0].address_components[i].short_name;
                  break;
              }
            }
          }
          // Construct a full address object.
          let full_address = {
            address: address,
            city: city,
            state: state,
            short_state: short_state,
            postal_code: postal_code,
            country: country,
            lat: center.lat,
            lng: center.lng,
          };
          // Update state variable with the full address.
          setFullAddress(full_address);
          setFilterState(full_address.state);
          setFilterCity(full_address.city);
          console.log("full address: ", full_address);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      // Handle the scenario when latitude or longitude is not provided.
      setFullAddress({
        address: "",
        city: "",
        state: "",
        short_state: "",
        postal_code: "",
        country: "",
        lat: "",
        lng: "",
      });
      setFilterState("");
      setFilterCity("");
    }
  };

  const handleSeeMoreClick = (itemId) => {
    setExpandedItems((prevExpandedItems) => {
      if (prevExpandedItems.includes(itemId)) {
        return prevExpandedItems.filter((id) => id !== itemId);
      } else {
        return [...prevExpandedItems, itemId];
      }
    });
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const debouncedSearch = debounce(searchService, 800);

  useEffect(() => {
    // setLoading(true);
    // if (AuthProfileData && AuthProfileData != undefined && AuthProfileData != null && AuthToken) {
    fetchServiceCategories();
    // fetchService();
    // console.log("auth profileData: " , AuthProfileData);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // }
  }, [AuthProfileData]);

  useEffect(() => {
    // if (AuthProfileData && AuthProfileData != undefined && AuthProfileData != null && AuthToken) {
    if (filterCategory || filterState || filterCity) {
      searchService();
    } else {
      fetchService();
    }
    // }
  }, [filterCategory, filterState, filterCity]);

  useEffect(() => {
    // if (AuthProfileData && AuthProfileData != undefined && AuthProfileData != null && AuthToken) {
    if (filterKeyword) {
      // searchService();
      debouncedSearch();
    } else {
      fetchService();
    }
    // }
  }, [filterKeyword]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingUp = prevScrollpos > currentScrollPos;

      // Check if the current scroll position is below the threshold
      if (currentScrollPos > scrollThreshold) {
        setVisible(isScrollingUp);
      } else {
        setVisible(true); // If above the threshold, always set it to visible
      }

      setPrevScrollpos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollpos, scrollThreshold]);

  return (
    <>
      <MainLayout>
        {loading && (
          <Box>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}
        <Box className="service-directory">
          <Slide
            in={visible}
            container={searchFilterRef.current}
            //  timeout={300}
          >
            <Box className="sd-filter">
              {/* <Typography>Filters</Typography> */}
              <Box className="filter-grid">
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      "& .MuiSelect-select": {
                        color: "#2D2D2D !important",
                        fontFamily: "Gotham !important",
                      },
                    }}
                    style={{
                      backgroundColor: "#fff",
                      // border: "2px solid #2d2d2d",
                      borderRadius: "10px",
                    }}
                    className="select-dropdwn"
                    value={filterCategory}
                    onChange={(e) => setFilterCategory(e.target.value)}
                  >
                    <MenuItem value="">All</MenuItem>

                    {serviceCategories.map((item) => (
                      <MenuItem key={item.id} value={item.title}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <TextField
                                className='sd-input'
                                id="outlined-basic"
                                label=""
                                variant="outlined"
                                placeholder='Location'
                                InputLabelProps={{
                                    shrink: false,
                                }}
                                value={filterLocation}
                                onChange={(e) => setFilterLocation(e.target.value)}
                                sx={{

                                }}
                            /> */}
                {/* google map code */}
                <Box className="filter-map">
                  {!serviceLocationModalOpen && <GoogleMapLocation
                    onPress={(center) => {
                      // console.log("center: " , center);
                      if (center && center.lat && center.lng) {
                        getFullAddress(center);
                      }
                    }}
                    inputRefs={inRef}
                    searchRef={searchRef}
                    // equipLocation={EquipLocation}
                    equipAddress={EquipAddress}
                  />}
                </Box>
                <TextField
                  id="outlined-basic"
                  className="sd-input"
                  label=""
                  variant="outlined"
                  placeholder="Keyword"
                  InputLabelProps={{
                    shrink: false,
                  }}
                  sx={{}}
                  value={filterKeyword}
                  onChange={(e) => setFilterKeyword(e.target.value)}
                />
              </Box>

              <Button className="filter-reload">
                <Autorenew
                  className="reload-icon"
                  onClick={() => {
                    resetFilters();
                    setEquipAddress("     ");
                    setTimeout(() => {
                      setEquipAddress("");
                    }, 300);
                  }}
                />
              </Button>
            </Box>
          </Slide>

          <Container>
            <Box>
              <Box className="sd-header">
                {/* <img src={leftArrow} alt="" /> */}
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "GothamBold !important",
                    textAlign: "center",
                  }}
                >
                  Services Directory
                </Typography>
                {/* <img src={rightArrow} alt="" /> */}
              </Box>

              <Box className="sd-cards-main">
                {/* <Box className='sd-cards' sx={{ display: 'flex', alignItems: 'flex-start', gap: '1rem' }}>
                                    <Box>
                                        <img src={dummyProfile} alt="" className='sd-card-profile' />
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', justifyContent: 'flex-start' }}>

                                        <Typography sx={{ backgroundColor: '#faa61a', width: 'fit-content', padding: '2px 5px', borderRadius: '20px', minWidth: '50px', fontSize: '10.5px', fontFamily: 'GothamBold !important', textTransform: 'uppercase', color: '#000 !important', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            hello
                                        </Typography>

                                        <Typography sx={{ width: 'fit-content', padding: '5px 2px', borderRadius: '20px', fontSize: '16px', fontFamily: 'GothamBold !important', textTransform: 'capitalize', color: '#fff !important', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            Name Com
                                        </Typography>

                                        <Box className='sd-card-grid'>
                                            <Box className='sd-name-div'>
                                                <img src={locationIcon} alt="" className='sd-name-icon' />
                                                <Typography >
                                                    Name
                                                </Typography>
                                            </Box>
                                            <Box className='sd-location-div'>
                                                <img src={locationIcon} alt="" className='sd-location-icon' />
                                                <Typography>
                                                    Sahibzada Ajit Singh Nagar, Punjab
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Typography className='sd-card-msg'>
                                            Can we consider the Stock Exchange Q & A process to be research? And we can refer...
                                            <Button className='sd-more-btn'>&#40;More&#41;</Button>
                                        </Typography>

                                        <Box className='icons-grp'>
                                            <img src={msgIcon} alt="" className='sd-cards-icon' />
                                            <img src={msgIcon} alt="" className='sd-cards-icon' />
                                            <img src={msgIcon} alt="" className='sd-cards-icon' />
                                            <img src={msgIcon} alt="" className='sd-cards-icon' />
                                            <img src={msgIcon} alt="" className='sd-cards-icon' />
                                            <img src={msgIcon} alt="" className='sd-cards-icon' />
                                            <img src={msgIcon} alt="" className='sd-cards-icon' />
                                        </Box>

                                    </Box>
                                </Box> */}
                {serviceList.length == 0 && (
                  <Box>
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "clamp(16px, 3vw, 20px)",
                        textAlign: "center",
                        fontFamily: "GothamBold !important",
                        margin: "5rem 1rem 3rem 1rem",
                      }}
                    >
                      No Data Found!{" "}
                    </p>
                  </Box>
                )}

                {serviceList.map((item) => (
                  <Box
                    key={item.id}
                    className="sd-cards"
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                    }}
                  >
                    <Box>
                      {item.profile_pic ? (
                        <img
                          src={item.profile_pic}
                          alt=""
                          className="sd-card-profile"
                        />
                      ) : (
                        <img
                          src={dummyProfile}
                          alt=""
                          className="sd-card-profile"
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          backgroundColor: "#faa61a",
                          width: "fit-content",
                          padding: "2px 5px",
                          borderRadius: "20px",
                          minWidth: "50px",
                          fontSize: "10.5px",
                          fontFamily: "GothamBold !important",
                          textTransform: "uppercase",
                          color: "#000 !important",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.service_category}
                      </Typography>

                      <Typography
                        sx={{
                          width: "fit-content",
                          padding: "5px 2px",
                          borderRadius: "20px",
                          fontSize: "16px",
                          fontFamily: "GothamBold !important",
                          textTransform: "capitalize",
                          color: "#fff !important",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.company_name}
                      </Typography>

                      <Box className="sd-card-grid">
                        <Box className="sd-name-div">
                          <img src={userIcon} alt="" className="sd-name-icon" />
                          <Typography>{item.first_name}</Typography>
                        </Box>
                        <Box className="sd-location-div">
                          <img
                            src={locationIcon}
                            alt=""
                            className="sd-location-icon"
                          />
                          <Typography>
                            {/* Sahibzada Ajit Singh Nagar, Punjab */}
                            {item.city && <>{item.city},</>} {item.state}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          overflow: "hidden",
                        }}
                      >
                        {/* <Typography className='sd-card-msg'>
                                                        {item.service_description} */}
                        {/* <Button className='sd-more-btn'>&#40;More&#41;</Button> */}
                        {/* </Typography> */}

                        {item.service_description && (
                          <Typography className="sd-card-msg">
                            {expandedItems.includes(item.id)
                              ? item.service_description
                              : truncateText(item.service_description, 100)}
                            {item.service_description.length > 100 && (
                              <Button
                                onClick={() => handleSeeMoreClick(item.id)}
                                className="sd-more-btn"
                              >
                                {expandedItems.includes(item.id)
                                  ? "Show Less"
                                  : "Show More"}
                              </Button>
                            )}
                          </Typography>
                        )}
                      </Box>

                      <Box className="icons-grp">
                        {item.email && (
                          <img
                            src={msgIcon}
                            alt=""
                            style={{
                              cursor: "pointer",
                              width: "35px",
                              display: "block",
                              objectFit: "contain",
                              height: "auto",
                            }}
                            onClick={() => {
                              // const mailtoLink = `mailto:${item.email}`;
                              // // Open the default email client
                              // window.location.href = mailtoLink;
                              setEmailModelOpen(true);
                              setSendToEmail(item.email);
                            }}
                          />
                        )}
                        {item.phone_voice && (
                          <Link href={`tel:${item.phone_voice}`}>
                            <img
                              src={phnIcon}
                              alt=""
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                display: "block",
                                objectFit: "contain",
                                height: "auto",
                              }}
                            />
                          </Link>
                        )}
                        {item.phone_text && (
                          <img
                            src={textIcon}
                            alt=""
                            style={{
                              cursor: "pointer",
                              width: "20px",
                              display: "block",
                              objectFit: "contain",
                              height: "auto",
                            }}
                            onClick={() => {
                              setSendTextMobileNo(item.phone_text);
                              setTextModelOpen(true);
                            }}
                          />
                        )}
                        {item.service_photos && (
                          <img
                            src={photoIcon}
                            alt=""
                            style={{
                              cursor: "pointer",
                              width: "20px",
                              display: "block",
                              objectFit: "contain",
                              height: "auto",
                            }}
                            onClick={() => {
                              setPhotosArray(item.service_photos);
                              setPhotosModelOpen(true);
                            }}
                          />
                        )}

                        {item.latitude &&
                          item.longitude &&
                          item.location_status == 0 && (
                            // <Link href={`https://www.google.com/maps/@${item.lat},${item.lng},11z?hl=en&entry=ttu`} target="_blank">
                            <Link
                              href={`https://www.google.com/maps?q=${item.latitude},${item.longitude}`}
                              target="_blank"
                            >
                              <img
                                src={locpin}
                                alt=""
                                style={{
                                  width: "16px",
                                  display: "block",
                                  objectFit: "contain",
                                  height: "auto",
                                }}
                              />
                            </Link>
                          )}

                        {item.website && (
                          <Link
                            href={
                              item.website.startsWith("http")
                                ? item.website
                                : `http://${item.website}`
                            }
                            target="_blank"
                          >
                            <img
                              src={webIcon}
                              alt=""
                              style={{
                                width: "25px",
                                display: "block",
                                objectFit: "contain",
                                height: "auto",
                              }}
                            />
                          </Link>
                        )}
                        {/* <img src={msgIcon} alt="" className='sd-cards-icon' />
                                                    <img src={msgIcon} alt="" className='sd-cards-icon' />
                                                    <img src={msgIcon} alt="" className='sd-cards-icon' />
                                                    <img src={msgIcon} alt="" className='sd-cards-icon' /> */}
                      </Box>
                      {UserID == item.user_id && <Box className="btn-grp">
                        <Button
                          sx={{
                            background: "#149247 !important",
                            color: "#fff !important",
                            fontFamily: "GothamBold !important",
                          }}
                          onClick={() => {
                            setServiceData(item);
                            setServiceDescModalOpen(true);
                          }}
                        >
                          Edit Service Description
                        </Button>

                        <Button
                          sx={{
                            background: "#149247 !important",
                            color: "#fff !important",
                            fontFamily: "GothamBold !important",
                          }}
                          onClick={() => {
                            setServiceData(item);
                            setServiceLocationModalOpen(true);
                          }}
                        >
                          Edit Service Location
                        </Button>

                        <Button
                          sx={{
                            background: "#149247 !important",
                            color: "#fff !important",
                            fontFamily: "GothamBold !important",
                          }}
                          onClick={() => {
                            setServiceData(item);
                            setServicePhotosModalOpen(true);
                          }}
                        >
                          Edit Service Images
                        </Button>

                        {item.service_live_status == "0" && (
                          <Button
                            sx={{
                              background: "#149247 !important",
                              color: "#fff !important",
                              fontFamily: "GothamBold !important",
                            }}
                            onClick={() => {
                              setServiceData(item);
                              setServiceLiveModalOpen(true);
                            }}
                          >
                            Live service
                          </Button>
                        )}

                        <Button
                          sx={{
                            background: "#ff0000 !important",
                            color: "#fff !important",
                            fontFamily: "GothamBold !important",
                          }}
                          onClick={() => {
                            setDeleteServiceId(item.id);
                            setDeleteModelOpen(true);
                          }}
                        >
                          Delete Service
                        </Button>
                      </Box>}
                    </Box>
                  </Box>
                ))}
              </Box>

              {showFooterBar && (
                <Box sx={{ padding: "1rem" }}>
                  <Box className="get-started">
                    <Box className="close-get-started">
                      <Typography
                        className="close-text"
                        onClick={() => setShowFooterBar(false)}
                      >
                        X Close
                      </Typography>
                    </Box>
                    <Box className="get-started-content">
                      <Typography>
                        {/* Want To List YOUR Services on GoEquipMe ? */}
                        List YOUR Services on GoEquipMe for Free!
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "0.5rem",
                        }}
                      >
                        <Link
                          className="get-started-btn"
                          sx={{ cursor: "pointer" }}
                          // href='/add-service'
                          onClick={() => {
                            if (UserID) {
                              navigate("/add-service");
                            } else {
                              LoginPopup();
                            }
                          }}
                        >
                          {/* <img src={btnRightArrow} alt="" className='btn-arrow' style={{ transform: 'rotate(90deg)' }} /> */}
                          GET STARTED
                          {/* <img src={btnLeftArrow} alt="" className='btn-arrow' style={{ transform: 'rotate(-90deg)' }} /> */}
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Container>
        </Box>

        {/* service photos popUp/Model */}
        {PhotosModelOpen && (
          <PhotosModal
            PhotosModelOpen={PhotosModelOpen}
            setPhotosModelOpen={setPhotosModelOpen}
            photosArray={photosArray}
          />
        )}
        {/* send text msg popUp/Model */}
        {textModelOpen && (
          <SendTextMsgModal
            textModelOpen={textModelOpen}
            setTextModelOpen={setTextModelOpen}
            sendTextMobileNo={sendTextMobileNo}
          />
        )}
        {/* email redirect confirmation modal */}
        {emailModelOpen && (
          <EmailRedirectModal
            emailModelOpen={emailModelOpen}
            setEmailModelOpen={setEmailModelOpen}
            sendToEmail={sendToEmail}
          />
        )}

        {/* modal for delete a service */}
      {deleteModelOpen && (
        <DeleteServiceModal
          deleteModelOpen={deleteModelOpen}
          setDeleteModelOpen={setDeleteModelOpen}
          deleteServiceId={deleteServiceId}
          setLoading={setLoading}
          onDeleteSuccess={(serviceId) => {
            console.log("serviceId in onDeleteSuccess: ", serviceId);
            setServiceList(
              serviceList.filter((item) => item.id != serviceId)
            );
            setLoading(false);
          }}
        />
      )}

      {/* modal for edit service descriptions */}
      {serviceDescModalOpen && (
        <EditServiceDescriptionModal
          loading={loading}
          serviceDescModalOpen={serviceDescModalOpen}
          setServiceDescModalOpen={setServiceDescModalOpen}
          serviceCategories={serviceCategories}
          serviceData={serviceData}
          setServiceData={setServiceData}
          updateService={updateService}
        />
      )}

      {/* modal for service location update */}
      {serviceLocationModalOpen && (
        <EditServiceLocationModal
          loading={loading}
          serviceLocationModalOpen={serviceLocationModalOpen}
          setServiceLocationModalOpen={setServiceLocationModalOpen}
          serviceData={serviceData}
          setServiceData={setServiceData}
          updateService={updateService}
        />
      )}

      {/* modal for photos update */}
      {servicePhotosModalOpen && (
        <EditServicePhotosModal
          servicePhotosModalOpen={servicePhotosModalOpen}
          setServicePhotosModalOpen={setServicePhotosModalOpen}
          serviceData={serviceData}
          myServices={fetchService}
        />
      )}

      {/* to make a service live */}
      {serviceLiveModalOpen && (
        <MakeServiceLiveModal
          loading={loading}
          setLoading={setLoading}
          serviceLiveModalOpen={serviceLiveModalOpen}
          setServiceLiveModalOpen={setServiceLiveModalOpen}
          serviceData={serviceData}
          setServiceData={setServiceData}
          updateService={updateService}
          myServices={fetchService}
        />
      )}

      </MainLayout>
    </>
  );
};

export default ServiceDirectory;
