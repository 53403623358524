import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  Modal,
  TextField,
  Slider,
  styled,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useNavigate, Link as RLink } from "react-router-dom";
import MainLayout from "layouts/MainLayout";

// redux
import { useSelector, useDispatch } from "react-redux";
import { IStoreValue, IApiResponseRawData } from "utils/types";
import {
  setEquipSelectType,
  setCategories,
  setalertMessagetype,
  setalertMessage,
  setEquipFilters,
  setFromHowItWorks,
} from "redux/redux-slice";

// components
import FindEqpQuesRent from "components/howItWorks/GotQuestions/FindEqpQuesRent";
import FindEqpQuesSale from "components/howItWorks/GotQuestions/FindEqpQuesSale";
import Testimonials from "components/howItWorks/Testimonials/Testimonials";
import FindEqpTab from "components/howItWorks/TabLikeSec/FindEqpTab";
import FindEqpSaleTab from "components/howItWorks/TabLikeSec/FindEqpSaleTab";
import SearchSection from "components/search-section/SearchSection";
// images
import Filtericon from "../../../assets/images/filter.png";
import Jcb from "../../../assets/images/howitworks/tractor.png";
import whyGemImg from "../../../assets/images/howitworks/why-gem.png";
import edpImg from "../../../assets/images/howitworks/protection.png";
import liveHelp from "../../../assets/images/gem-livehelp.png";
import flexImg from "../../../assets/images/howitworks/flex.png";
import communityImg from "../../../assets/images/howitworks/itsaboutcommunity.png";
import handsKey from "../../../assets/images/howitworks/keys.png";
import gemLending from "../../../assets/images/howitworks/gem-lending-logo.png";

// styles
import "./styles.scss";

// Added on 28FEB2024
import { Calendar as NewCalendar } from "react-multi-date-picker";
import moment from "moment";
import CalendarImg from "assets/images/details-page/calendar_two.png";
import ClearIcon from "@mui/icons-material/Clear";
import { EquipmentAPIs } from "service/api-service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(46 46 46)",
  boxShadow: 24,
  p: 4,
};

// Added on 28 FEB 2024
const FindEquip = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const equipType = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipSelectType
  );
  const equipFilters = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipFilters
  );

  // console.log("equipFilters: ", equipFilters);

  // const [equipType, setEquipType] = useState('rent');
  useEffect(() => {
    // window.scroll(0, 0);
    window.scroll(0, -100);
  }, []);

  // useEffect(() => {
  //   if (equipType == 'sale') {
  //     // navigate("/howItWorksFindSale");
  //   }
  // }, [equipType]);

  // useEffect(() => {
  //   dispatch(setEquipFilters({ ...equipFilters, equipType }));
  // }, [equipType]);

  // useEffect(() => {
  //   console.log("equipFilters changed: ", equipFilters);
  // }, [equipFilters]);

  {
    /* Added on 28 Feb 2024 Start */
  }

  const matchesMobileX = useMediaQuery("(max-width:425px)");
  const categories = useSelector(
    (state: IStoreValue) => state.equipmentReducer.categories
  );
  const [category, setCategory] = useState("");
  // Filter Popup states
  const [FilterPopUp, setFilterPopUp] = useState(false);
  const [ZipCode, setZipCode] = useState("");
  const [locationRange, setLocationRange] = useState("");

  useEffect(() => {
    const getCategories = async (): Promise<void> => {
      try {
        //dispatch(setLoadingEquipments(true))
        const categoriesRes: IApiResponseRawData =
          await EquipmentAPIs.getAllCategories();
        dispatch(setCategories(categoriesRes.data.rows as Array<string>));
        //dispatch(setLoadingEquipments(false))
      } catch (err) {
        console.log("Get Categories Error : ", err);
      }
    };
    if (categories.length === 0) {
      getCategories();
    }
  }, [categories.length, dispatch]);

  const onCategoryChange = useCallback((event) => {
    let val = event.target.value;
    //setCity('')
    if (val != "") {
      //setPage(0);
      setCategory(val);
      // dispatch(setEquipFilters({ ...equipFilters, category: val }));
    } else {
      //setPage(0);
      setCategory("");
      // dispatch(setEquipFilters({ ...equipFilters, category: '' }));
    }
  }, []);

  const HandleFilterPopUp = useCallback(() => {
    setFilterPopUp(true);
  }, []);

  const handleSliderChange = (event: Event, newValue) => {
    setLocationRange(newValue as string);
    // dispatch(setEquipFilters({ ...equipFilters, locationRange: newValue as string }));
  };

  // Location range disable attribute
  const [RangeValidate, setRangeValidate] = useState(true);
  // for handling zipCode field
  const HandlePostalCode = useCallback((e) => {
    var val = e.target.value;
    val = val.replace(/[^0-9.]/g, "");
    setZipCode(val);
    // dispatch(setEquipFilters({ ...equipFilters, zipCode: val, category }));
    //set location range disable if zip code not available
    setRangeValidate(true);
    if (val.length > 0) {
      setRangeValidate(false);
    }
    if (val.length == 0) {
      setLocationRange("");
    }
  }, []);

  const ResetZipCode = useCallback((e) => {
    setZipCode("");

    // Handle location range field
    setRangeValidate(true);
    setLocationRange("");
  }, []);

  const [CalendarPopup, setCalendarPopup] = useState(false);
  const [values, setValues] = useState([]);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  // Handle calendar popup
  const HandleCalendarPopup = async () => {
    setCalendarPopup(true);
  };

  // reset the calendar
  const resetCalendar = () => {
    let a = ["", ""];
    setValues(a);
    setStartDate("");
    setEndDate("");
    // dispatch(setEquipFilters({ ...equipFilters, startDate: '' }));
    // dispatch(setEquipFilters({ ...equipFilters, endDate: '' }));
  };

  // handle dates in calendar popup
  const HandleCalendarDates = () => {
    if (!EndDate && !StartDate) {
      dispatch(setalertMessage("Please select both start & end date."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    setCalendarPopup(false);
    // setStartDate(start);
    // setEndDate(end);
  };

  const [FilterType, setFilterType] = useState("");
  const [FilterTagReset, setFilterTagReset] = useState("");
  // const [FilterTagData, setFilterTagData] = useState({
  //   State: '',
  //   City: '',
  //   ZipCode: '',
  //   StartDate: '',
  //   EndDate: '',
  //   category: '',
  //   locationRange: '',
  // });

  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  // Handle popup filter
  const HandleFilter = async () => {
    try {
      let searchObj = {
        State: State,
        City: City,
        ZipCode: ZipCode,
        StartDate: StartDate,
        EndDate: EndDate,
        category: category,
        locationRange: locationRange,
      };

      console.log("searchObj", searchObj);

      if (
        State != "" ||
        City != "" ||
        ZipCode != "" ||
        StartDate != "" ||
        EndDate != "" ||
        locationRange != ""
      ) {
        // setFilterTagData({
        //   State: State,
        //   City: City,
        //   ZipCode: ZipCode,
        //   StartDate: StartDate,
        //   EndDate: EndDate,
        //   category: category,
        //   locationRange: (locationRange == null || locationRange == "0")? '' : locationRange,
        // });
        //searchFilter('Filter', '', '', 0);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  {
    /* Added on 28Feb2024 End */
  }

  const handleSeeResults = () => {
    dispatch(
      setEquipFilters({
        category,
        zipCode: ZipCode,
        locationRange: locationRange,
        startDate: StartDate,
        endDate: EndDate,
        equipType,
      })
    );

    dispatch(setFromHowItWorks(true));

    let equipFiltersObj = {
      category,
      zipCode: ZipCode,
      locationRange: locationRange.toString(),
      startDate: StartDate,
      endDate: EndDate,
      equipType,
    };

    localStorage.setItem("equipFiltersObj", JSON.stringify(equipFiltersObj));
    localStorage.setItem("fromFindPageLocal", "yes");
    localStorage.setItem("equipFilterType", equipType);
    // navigate("/");
    window.location.href = "/";

    // setTimeout(() => {

    // }, 1000);
  };

  return (
    <MainLayout>
      <>
        <Box className="listeqp-bg-img">
          <img src={Jcb} alt="" />
        </Box>
        <Box className="hw">
          <Box>
            <Box className="bac-top">
              <Box className="bac-L">
                <h1 className="bac-heading">
                  FIND <span>equipment</span>
                </h1>
                {equipType == "rent" ? (
                  <p className="bac-subheading">
                    Enjoy savings and the benefits of an equipment sharing
                    marketplace.
                  </p>
                ) : (
                  <p className="bac-subheading">
                    Know what you are buying with Rent Purchase Options (RPO).
                    Utilize GoEquipMe’s in-house financing to get your purchase
                    done.
                  </p>
                )}
              </Box>
            </Box>

            <Box className="fq-search">
              <Box className="search-subgrid">
                <Box className="fq-search-gridL">
                  <Box className="search-cselect" sx={{ position: "relative" }}>
                    {/* <Select
                      labelId="demo-simple-select-label"
                      id="fq-select"
                      disabled={true}
                      value={10}
                      label="What type of equipment"
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        "& .MuiSelect-select": {
                          color: "#2D2D2D !important",
                          fontFamily: "GothamBold !important",
                          width: '100%',
                        },
                      }}
                      style={{
                        backgroundColor: "#fff",
                        border: "2px solid #FAA61A",
                        borderRadius: "10px",
                        width: '100%',
                      }}
                      className="select-dropdwn"
                    >
                      <MenuItem value={10}>What type of equipment?</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select> */}

                    <Select
                      value={category}
                      onChange={onCategoryChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        "& .MuiSelect-select": {
                          color: "#2D2D2D !important",
                          fontFamily: "GothamBold !important",
                        },
                      }}
                      style={{
                        backgroundColor: "#fff",
                        border: "2px solid #FAA61A",
                        borderRadius: "10px",
                      }}
                      className="select-dropdwn"
                    >
                      <MenuItem value="" className="category-dropdown">
                        All
                      </MenuItem>
                      {categories.map((item, index) => (
                        <MenuItem
                          className="list-eqp-select-options"
                          value={item.label}
                          key={index}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* Added on 28Feb2024 End */}
                  </Box>
                  <Box className="">
                    <img
                      onClick={HandleFilterPopUp} // Added on 28Feb2024 End
                      src={Filtericon}
                      alt="filter-icon"
                      className="fq-search-filter-icon"
                      style={{
                        width: "35px",
                        height: "auto",
                        display: "block",
                      }}
                    />
                  </Box>
                </Box>
                <Box className="fq-search-btn-grid">
                  <Box className="search-result-btn-div">
                    <Button
                      variant="contained"
                      color="success"
                      className="fq-search-result-btn"
                      // onClick={HandleFilter} // Added on 28Feb2024 End
                      // onClick={() => navigate('/')} // Added on 28Feb2024 End
                      onClick={handleSeeResults} // Added on 28Feb2024 End
                    >
                      See Results
                    </Button>
                  </Box>
                  <Box className="search-radio-btns">
                    {/* for rent and sales radio buttons */}
                    <FormControl className="">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        // onClick={HandleEquipType}
                        className="radio-grp list-eqp-page new-radio-grp"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <FormControlLabel
                            value={"rent"}
                            checked={equipType == "rent" ? true : false}
                            onClick={() => dispatch(setEquipSelectType("rent"))}
                            control={<Radio />}
                            label={
                              <Box>
                                <Typography
                                  className="fq-radio-label"
                                  sx={{ color: "#fff !important" }}
                                >
                                  For Rent
                                </Typography>
                              </Box>
                            }
                            className="fq-radio-grp"
                          />

                          <FormControlLabel
                            value={"sale"}
                            checked={equipType == "sale" ? true : false}
                            onClick={() => dispatch(setEquipSelectType("sale"))}
                            control={<Radio />}
                            label={
                              <Box>
                                <Typography
                                  className="fq-radio-label"
                                  sx={{ color: "#fff !important" }}
                                >
                                  For Sale
                                </Typography>
                              </Box>
                            }
                            className="fq-radio-grp"
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>

            {equipType == "rent" ? <FindEqpTab /> : <FindEqpSaleTab />}

            {/* Keep Your Equipment Working for You! */}
            <Box className="lgt">
              <Box className="lgt-content">
                <p className="lgt-heading">Why GoEquipMe?</p>
                {equipType == "rent" ? (
                  <p className="lgt-text">
                    Simply put, it’s a better, more cost efective way, to rent!
                    As the US leader in equipment sharing, GoEquipMe offers an
                    easy to use platform. You can typically find the equipment
                    you are looking for at prices that are 30%, or greater,
                    below standard brick and mortar locations.
                  </p>
                ) : (
                  <p className="lgt-text">
                    It’s a better way to buy. GoEquipMe encourages transparency
                    and fair representation of equipment condition and can
                    assist you. We are here to help.
                  </p>
                )}
              </Box>
              <Box className="lgt-img">
                <img src={whyGemImg} alt="" />
              </Box>
            </Box>

            {/* Equip damage protection */}
            {equipType == "rent" ? (
              <Box className="edp">
                <Box className="edp-img">
                  <img src={edpImg} alt="" />
                </Box>
                <Box className="edp-content">
                  <p className="edp-heading">KNOW YOU ARE COVERED</p>
                  <p className="edp-text">
                    We know things can go wrong. So, GoEquipMe’s{" "}
                    <span>$50,000 of Equipment Damage Protection</span> ensures
                    peace-of-mind renting.
                  </p>
                  <p className="edp-text">
                    Need more coverage or have your own? No worries, just
                    contact us in the &nbsp;
                    <Link
                      onClick={() => {
                        console.log("intercom working");
                        window["Intercom"]?.("show");
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      Live Help
                    </Link>
                    &nbsp; and we will work with you and the equipment owner to
                    ensure you have the coverage you need but not the coverage
                    you don’t need.
                  </p>
                </Box>
              </Box>
            ) : (
              <Box className="hk">
                <Box className="hk-img-2">
                  <img src={handsKey} alt="" />
                </Box>
                <Box className="hk-content">
                  <p className="hk-heading">
                    RPO’S POSSIBLE WITH LOCAL PURCHASES
                  </p>
                  <p className="hk-subheading-2">
                    It’s a big purchase, so give it a test drive first. Many
                    listings offer
                    <span> GoEquipMe’s Rental Purchase Option (RPO)</span> that
                    allows you to rent the equipment before you buy. Once you
                    decide to buy, a portion of the rental proceeds will go
                    towards your purchase.
                  </p>
                </Box>
              </Box>
            )}

            {/* 24/7 */}
            <Box className="mipt">
              {equipType == "rent" ? (
                <Box className="mipt-item">
                  <Box className="mipt-item-img">
                    <img src={flexImg} alt="" />
                  </Box>
                  <p className="mipt-item-heading">RENT NOW… PAY LATER</p>
                  <p className="mipt-item-text">
                    Got a project, but need some equipment to get you to that
                    payday? We’ve got you covered. Apply for a
                    <span> Flex Pro Account</span> and get going with the
                    equipment you need now and pay on a 30 day billing cycle.
                  </p>
                </Box>
              ) : (
                <Box className="mipt-item">
                  <Box className="mipt-item-img">
                    <img src={gemLending} alt="" />
                  </Box>
                  <p className="mipt-item-heading">NEED FINANCING? OKAY!</p>
                  <p className="mipt-item-text">
                    We don’t just farm-out loans we do loans. Utilize &nbsp;
                    <RLink to="/equipmentlending">
                      GoEquipMe’s in-house financing
                    </RLink>
                    &nbsp; to get your purchase done.
                  </p>
                  <p className="mipt-item-text">
                    Bad credit, no credit, no financials, business startups…we
                    can help!
                  </p>
                </Box>
              )}

              <Box className="mipt-item">
                <Box className="mipt-item-img">
                  <img src={liveHelp} alt="" />
                </Box>
                <p className="mipt-item-heading">
                  NEED HELP FINDING WHAT YOU ARE LOOKING FOR?
                </p>
                <p className="mipt-item-text">
                  We are growing, fast, but if you don’t see the equipment you
                  need in your area simply click on GoEquipMe’s &nbsp;
                  <Link
                    onClick={() => {
                      console.log("intercom working");
                      window["Intercom"]?.("show");
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    24/7 Live Chat
                  </Link>
                  &nbsp; and we will get to work finding it for you.
                </p>
              </Box>
            </Box>

            {/* Testimonials */}
            <Testimonials />

            {/*  */}
            <Box className="lgt-2">
              <Box className="lgt-img">
                <img src={communityImg} alt="" />
              </Box>
              <Box className="lgt-content-2">
                <p className="lgt-heading">It’s About Community</p>
                <p className="lgt-text">
                  GoEquipMe encourages transparency, fairness in business, and
                  supporting one another in building mutual success in a free
                  market society. It’s about building strong communities. It’s
                  our culture and it’s what we are all about.
                </p>
              </Box>
            </Box>

            {/* Got questions */}
            {equipType == "rent" ? <FindEqpQuesRent /> : <FindEqpQuesSale />}
          </Box>
        </Box>

        {/* Added on 28Feb2024 Start */}
        <Modal
          keepMounted
          open={FilterPopUp}
          onClose={() => setFilterPopUp(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popup search-pop"
            sx={{
              ...style,
              borderRadius: " 10px",
              width: matchesMobileX ? "calc(100vw - 100px)" : 400,
              padding: matchesMobileX ? "20px" : 4,
            }}
          >
            <IconButton
              onClick={() => setFilterPopUp(false)}
              sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
            >
              <Typography
                sx={{
                  color: "#faa61a",
                  fontSize: "25px",
                  paddingRight: "7px",
                  fontWeight: "800",
                }}
              >
                X
              </Typography>
            </IconButton>

            <Box className="filter-popup">
              <Box>
                <Typography
                  sx={{
                    paddingLeft: "0.5rem",
                    mb: " 1rem",
                    color: "#faa61a !important",
                    fontSize: "31px !important",
                    fontFamily: "GothamBold !important",
                  }}
                >
                  Filters
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  marginTop: "-1.5rem",
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1.5fr 0.5fr",
                      columnGap: "0.5rem",
                      rowGap: "0.5rem",
                      alignItems: "center",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
                >
                  <Box sx={{ textAlign: "center", marginBottom: "0.5rem" }}>
                    <Typography
                      sx={{
                        color: "#faa61a !important",
                        marginBottom: "0px",
                        fontFamily: "GothamBold !important",
                        padding: "0 0.6rem",
                      }}
                    >
                      Enter Your Location Manually{" "}
                    </Typography>
                  </Box>
                  <Box>
                    <FormControl variant="filled" sx={{ width: "100%" }}>
                      <TextField
                        id="filled-number"
                        placeholder="Zip Code"
                        type="text"
                        sx={{
                          backgroundColor: "#fff !important",
                          color: "#2d2d2d !important",
                          borderRadius: "5px",
                          border: "1px solid #faa61a",
                        }}
                        InputLabelProps={{
                          shrink: false,
                        }}
                        inputProps={{ maxLength: 8 }}
                        variant="filled"
                        onChange={HandlePostalCode}
                        value={ZipCode}
                      />
                      {ZipCode.length > 0 && (
                        <span
                          className="filter-cross-icon"
                          onClick={ResetZipCode}
                        >
                          <ClearIcon
                            sx={{ fontSize: "18px", marginRight: "10px" }}
                          />
                        </span>
                      )}
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        color: "#faa61a !important",
                        marginBottom: "0px",
                        fontFamily: "GothamBold !important",
                        padding: "0 0.6rem",
                      }}
                    >
                      {" "}
                      Radius{" "}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff !important",
                        fontSize: "12px",
                        padding: "0 0.6rem",
                      }}
                    >
                      &#40;search radius must have a valid location zipcode&#41;
                    </Typography>
                  </Box>
                  <Box
                    className="radius-slider-div"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1.5fr 0.5fr",
                      alignItems: "center",
                      gap: "2rem",
                      padding: "1rem",
                    }}
                  >
                    <Slider
                      className={
                        locationRange == ""
                          ? "radius-slider disabled"
                          : "radius-slider"
                      }
                      defaultValue={0}
                      aria-label="Default"
                      disabled={ZipCode == "" ? true : false}
                      value={locationRange != "" ? parseInt(locationRange) : 0}
                      onChange={handleSliderChange}
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.1rem",
                        color: "#fff !important",
                      }}
                    >
                      <span style={{ width: "45%" }}>
                        {locationRange != "" ? parseInt(locationRange) : 0}
                      </span>
                      <span style={{ width: "55%" }}>Miles</span>
                    </Typography>
                  </Box>

                  <Box className="main-search-grid">
                    <Box className="main-search-item"></Box>
                  </Box>

                  <Box>
                    <Box
                      onClick={HandleCalendarPopup}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "1.5rem",
                        paddingLeft: "1rem",
                        mt: "0rem",
                        mb: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={CalendarImg}
                        alt="GoEquipMe"
                        className=""
                        style={{
                          width: "40px",
                          outline: "4px solid #faa61a",
                          outlineOffset: "6px",
                          borderRadius: "3.5px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "GothamBold !important",
                          fontSize: "24px !important",
                        }}
                      >
                        Date Availability
                      </Typography>
                    </Box>

                    {StartDate && EndDate && (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "1rem",
                          width: "65%",
                          margin: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px",
                          }}
                        >
                          <Typography
                            sx={{ color: "#fff !important", fontWeight: "700" }}
                          >
                            START DATE :
                          </Typography>
                          <Typography sx={{ color: "#fff !important" }}>
                            {moment(StartDate).format("M-D-YYYY")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px",
                          }}
                        >
                          <Typography
                            sx={{ color: "#fff !important", fontWeight: "700" }}
                          >
                            END DATE :
                          </Typography>
                          <Typography sx={{ color: "#fff !important" }}>
                            {moment(EndDate).format("M-D-YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box className="reset-grid">
                  <Typography
                    className="reset-filter"
                    sx={{
                      color: "#ffa700",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontSize: "25px !important",
                    }}
                    onClick={(e) => {
                      // HandleReset(e)
                    }}
                    variant="h6"
                  >
                    Reset
                  </Typography>

                  <Button
                    className="filter-btn"
                    sx={{
                      boxSizing: "border-box",
                      bgcolor: "#149247",
                      fontFamily: "GothamBold",
                      color: "#fff",
                      width: "200px !important",
                      textTransform: "none",
                      fontSize: "clamp(16px, 3vw ,22px)",
                      padding: "7px 0.5rem !important",
                      lineHeight: "normal",
                    }}
                    // onClick={HandleFilter}
                    onClick={handleSeeResults}
                  >
                    See Results
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* Calendar PopUp */}
        <Modal
          keepMounted
          open={CalendarPopup}
          onClose={() => setCalendarPopup(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popup search-pop"
            sx={{
              ...style,
              borderRadius: " 10px",
              width: "460px",
              // width: matchesMobileX ? 'calc(100vw - 100px)' : 400,
              padding: matchesMobileX ? "20px" : 5,
            }}
          >
            <IconButton
              onClick={() => setCalendarPopup(false)}
              sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
            >
              <Typography
                sx={{
                  color: "#faa61a",
                  fontSize: "25px",
                  paddingRight: "7px",
                  fontWeight: "800",
                }}
              >
                X
              </Typography>
            </IconButton>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
                // className='date-cal'
              >
                <NewCalendar
                  numberOfMonths={1}
                  disableMonthPicker
                  disableYearPicker
                  value={values}
                  onChange={(rang) => {
                    let start = rang[0]?.format?.();
                    let end = rang[1]?.format?.();
                    if (start || end) {
                      if (start && end) {
                        let a = [start, end];
                        setValues(a);
                        setStartDate(moment(start).format("M-D-YYYY"));
                        setEndDate(moment(end).format("M-D-YYYY"));
                        // dispatch(setEquipFilters({ ...equipFilters, startDate: moment(start).format("M-D-YYYY") }));
                        // dispatch(setEquipFilters({ ...equipFilters, endDate: moment(end).format("M-D-YYYY") }));
                      }
                    }
                    return;
                  }}
                  minDate={new Date()}
                  // className="twin-cal-custom-calendar"
                  range
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100% !important",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "2rem",
                mt: "1rem",
                alignItems: "center",
              }}
            >
              <Typography
                className="reset-filter"
                sx={{
                  color: "#ffa700",
                  textDecoration: "underline",
                  marginLeft: "20px !important",
                  cursor: "pointer",
                }}
                onClick={resetCalendar}
                variant="h6"
              >
                Clear dates
              </Typography>

              <Button
                className="filter-btn"
                sx={{
                  bgcolor: "#149247",
                  fontFamily: "GothamBold",
                  color: "#fff",
                  width: "100% !important",
                }}
                onClick={HandleCalendarDates}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Modal>
        {/* Added on 28Feb2024 End */}
      </>
    </MainLayout>
  );
};

export default FindEquip;
