// Helper to generate all time slots in 24-hour format with half-hour intervals
export function generateTimeSlots() {
  const slots = {};
  for (let hour = 0; hour < 24; hour++) {
    const hourStr = String(hour).padStart(2, "0");
    slots[`${hourStr}:00:00`] = {disable:false,status:"default"};
    slots[`${hourStr}:30:00`] = {disable:false,status:"default"};
  }
  return slots;
}

// Helper to get time slots between two times, with optional adjustments for start and end inclusivity
function getTimeSlotsBetween({
  startTime,
  endTime,
  slots,
  includeStart,
  includeEnd,
  status,
}) {
  const slotKeys = Object.keys(slots);
  let startIndex = slotKeys.indexOf(startTime) + (includeStart ? 1 : 0);
  let endIndex = slotKeys.indexOf(endTime) + (includeEnd ? 1 : 0);
  return slotKeys.slice(startIndex, endIndex);
}

// Main function to get all slots with booked status for a specific date
export function getAllSlotsForDate({
  date,
  reservedDate: bookedDates,
}) {
  const timeSlots = generateTimeSlots();

  bookedDates.forEach((booking) => {
    // const {start_date:startDate,start_time:startTime,end_date:endDate,end_time:endTime} = booking;
    const { startDate, startTime, endDate, endTime,status } = booking;
    const bookingStartDate = new Date(startDate);
    const bookingEndDate = new Date(endDate);
    const targetDate = new Date(date);

    // Case 1: Single-day booking within target date
    if (startDate === date && endDate === date) {
      const slotsForBooking = getTimeSlotsBetween({
        startTime,
        endTime,
        slots: timeSlots,
        includeStart: false,
        includeEnd: true,
        status,
      });
      slotsForBooking.forEach((slot) => {
        timeSlots[slot]["disable"] = true;
        timeSlots[slot]["status"] = status;
      });

      // Case 2: Multi-day booking, target date is the start day
    } else if (startDate === date && bookingEndDate > targetDate) {
      const slotsForBooking = getTimeSlotsBetween({
        startTime,
        endTime: "23:30:00",
        slots: timeSlots,
        includeStart: false,
        includeEnd: true,
        status,
      });
      slotsForBooking.forEach((slot) => {
        timeSlots[slot]["disable"] = true;
        timeSlots[slot]["status"] = status;
      });

      // Case 3: Multi-day booking, target date is the end day
    } else if (
      bookingEndDate.toISOString().split("T")[0] === date &&
      bookingStartDate < targetDate
    ) {
      const slotsForBooking = getTimeSlotsBetween({
        startTime: "00:00:00",
        endTime,
        slots: timeSlots,
        includeStart: false,
        includeEnd: false,
        status,
      });
      slotsForBooking.forEach((slot) => {
        timeSlots[slot]["disable"] = true;
        timeSlots[slot]["status"] = status;
      });

      // Case 4: Multi-day booking, target date is between start and end days
    } else if (bookingStartDate < targetDate && bookingEndDate > targetDate) {
      Object.keys(timeSlots).forEach((slot) => (timeSlots[slot] = {disbale:true,status}));
    }
  });

  // const slotKeys = Object.keys(timeSlots);
  // for (let i = 1; i < slotKeys.length - 1; i++) {
  //   if (
  //     timeSlots[slotKeys[i - 1]]["disable"]&&
  //     timeSlots[slotKeys[i + 1]]["disable"]&&
  //     !timeSlots[slotKeys[i]]["disable"]
  //   ) {
  //     timeSlots[slotKeys[i]]["disable"] = true;
  //   }
  // }

  return timeSlots;
}



export function getHalfHourSlots(startTime, endTime) {
  let slots = [];
  let time = startTime;
  while(time<=endTime) {
      slots.push(time);
      let [hours,minutes] = time.split(":");
      if(minutes === "30") {
          hours = (Number(hours) + 1 + "").padStart(2,"0");
          minutes = "00"
      } else {
          minutes = "30"
      }
      time = `${hours}:${minutes}:00`
  }
  return slots.length;
}

export function isSelectionPartial({slots,startTime,endTime}) {
    const bookingCount = getHalfHourSlots(startTime,endTime);
    const slotsTime = Object.keys(slots);
    let count = 0;
    slotsTime.forEach(time=>{
      if(slots[time]["disable"]) {
        count++;
      }
    })
    console.log("is partial count",Math.abs(48 - (bookingCount+count)));
    return Math.abs(48 - (bookingCount+count));
}

export function hasMultipleRenter ({date,allPartialDatesTimes}) {
  const renterList = new Set();
  allPartialDatesTimes.forEach(({startDate,userId})=>{
    if(startDate === date && !renterList.has(userId)) {
      renterList.add(userId);
    }
  });
  return {usersId:Array.from(renterList)};
}


export function getDateAngles({allPartialDatesTimes, date}) {
  const result = { startAngle: "default", endAngle: "default" };

  const orders = allPartialDatesTimes.filter((order) => order.startDate === date);

  let bookedCount = 0;
  let bookedOrder = null;
  let earliestOrder = { time: "24:00:00", status: "default" };
  let latestOrder = { time: "00:00:00", status: "default" };

  for (const order of orders) {
    if (order.status === "booked") {
      bookedCount++;
      bookedOrder = order;
    } else {
      // Track earliest order (based on startTime)
      if (order.startTime < earliestOrder.time) {
        earliestOrder = { time: order.startTime, status: order.status };
      }

      // Track latest order (based on endTime)
      if (order.endTime > latestOrder.time) {
        latestOrder = { time: order.endTime, status: order.status };
      }
    }
  }
  // console.log("order length",date,orders);
  // Handle multiple booked statuses
  if (bookedCount > 1) {
    result.startAngle = "booked";
    result.endAngle = "booked";
  } else if (bookedCount === 1) {
    // Handle single booked status
    if (
      bookedOrder.startTime === "00:00:00" ||
      bookedOrder.endTime === "00:00:00"
    ) {
      result.startAngle = "booked";
      result.endAngle = latestOrder.status;
    } else if (
      bookedOrder.startTime === "23:30:00" ||
      bookedOrder.endTime === "23:30:00"
    ) {
      result.startAngle = earliestOrder.status;
      result.endAngle = "booked";
    } else {
      // Determine placement of booked based on times
      if (
        bookedOrder.startTime > latestOrder.time ||
        bookedOrder.endTime > latestOrder.time
      ) {
        result.endAngle = "booked";
        result.startAngle = earliestOrder.status;
      } else {
        result.startAngle = "booked";
        result.endAngle = latestOrder.status;
      }
    }
  } else {
    // No booked orders; use requested statuses
    if (orders?.length === 1) {
      const order = orders[0];
      if (order.startTime === "00:00:00" || order.endTime === "00:00:00") {
        result.startAngle = "requested";
      } else {
        result.endAngle = "requested";
      }
    } else {
      result.startAngle = earliestOrder.status;
      result.endAngle = latestOrder.status;
    }
  }

  return result;
}