import {
  Box,
  Button,
  FormControl,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  Slider,
  Backdrop,
  CircularProgress,
  Modal,
  IconButton,
  FormLabel,
  ToggleButtonGroup,
  ToggleButton,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
} from "@mui/material";
import MainLayout from "layouts/MainLayout";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import moment from "moment";
import { convertTimeZone, convertTimeZoneShortName } from "utils/commonFxns";
import momentTimeZone from "moment-timezone";
import { Calendar } from "react-multi-date-picker";
import CustomCalendar from "components/Calendar/CustomCalendar";
import EquipDetailsRent from "./EquipDetailsRent";
import RentalSummary from "./RentalSummary";
import EquipDetailsSale from "./EquipDetailsSale";
import { useSelector, useDispatch } from "react-redux";
import {
  SetRedirectPage,
  setLogInLoading,
  setLoginModalOpen,
  setalertMessage,
  setalertMessagetype,
} from "redux/redux-slice";
import RentSteps from "components/user-signup/signupPopup/RentSteps";
import RentalSummary2 from "./RentalSummary2";
import LocationIcon from "assets/images/location.png";
const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
const EquipmentDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [LikeLoading, setLikeLoading] = useState<boolean>(false);
  const [LikeStatus, setLikeStatus] = useState(null);
  const [equipmentData, setEquipmentData] = useState<any>({});
  const [blockedDates, setBlockedDates] = useState<any>([]);
  const [timeZone, setTimeZone] = useState<string>("UTC");
  const [timeZoneAbbr, setTimeZoneAbbr] = useState<string>("UTC");
  const [calendarStartDate, setCalendarStartDate] = useState(new Date());
  const [galleryImages, setGalleryImages] = useState<any>([]);
  const [mapLocation, setMaplocation] = useState<string>("");
  const locationViewRef = useRef(null);

  const [bookingSteps, setBookingSteps] = useState<number>(1);
  const [completeProfilePopup, setCompleteProfilePopup] =
    useState<boolean>(false);
  const [transportOption, setTransportOption] = useState<boolean>(false);

  function formatCoordinate(coord) {
    const [whole, decimal] = coord.toString().split(".");
    const formattedDecimal = (decimal || "").slice(0, 4).padEnd(4, "0");
    return `${whole}.${formattedDecimal}`;
  }

  const getEquipDetails = async (id: string) => {
    try {
      setLoading(true);
      const equipmentsRes: IApiResponseRawData =
        await EquipmentAPIs.equipDetails({
          equip_id: id,
        });
      console.log("equipmentsRes", equipmentsRes);
      if (equipmentsRes["type"] === "RXSUCCESS") {
        const equipmentData = equipmentsRes.data;
        // const deepLink = equipmentsRes.data.deep_links;
        const blockDates = `${equipmentData["block_dates"]}`;
        const blockedDatesArray = blockDates.split(",");
        const modifiedBlockedDates = [];
        blockedDatesArray.forEach((date) => {
          if(date){
            modifiedBlockedDates.push(date);
          }
          // const dateString = new Date(date);
          // if (dateString instanceof Date && !isNaN(dateString?.getTime())) {
          //   const today = moment().format("YYYY-MM-DD");
          //   const blocked = moment(dateString).format("YYYY-MM-DD");
          //   if (blocked >= today) {
          //     modifiedBlockedDates.push(blocked);
          //   }
          // }
        });
        setEquipmentData(equipmentData);
        setBlockedDates(modifiedBlockedDates);
        const images = equipmentData["images"]?.split(",");
        const modifiedImages = images.map((img) => {
          return {
            original: img,
            thumbnail: img,
            thumbnailHeight: "70px",
          };
        });
        setGalleryImages(modifiedImages);
        const lat = formatCoordinate(equipmentData["lat"]);
        const lng = formatCoordinate(equipmentData["lng"]);
        const mapLocation =
          `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&size=400x450&zoom=11&maptype=roadmap` +
          `&path=color:0x83827f8c|weight:150|${lat},${lng}|${lat}1,${lng}1` +
          `&path=color:0xf9f8f63b|weight:125|${lat},${lng}|${lat}1,${lng}1&key=${APIKEY}`;
        setMaplocation(mapLocation);
        const { time, currentDate } = convertTimeZone(
          equipmentData["timezone"]
        );
        if (equipmentData["timezone"]) {
          setTimeZone(equipmentData["timezone"]);
          const timeZoneAbbr = convertTimeZoneShortName(
            momentTimeZone().tz(equipmentData["timezone"]).zoneAbbr()
          );
          setTimeZoneAbbr(timeZoneAbbr);
        }
        setCalendarStartDate(new Date(currentDate));
      }
    } catch (err) {
      console.log("Error Fetching equipments", err);
    } finally {
      setLoading(false);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // For smooth scrolling
        });
      }, 0);
    }
  };

  const HandleChatOwnerToRenter = (redirect=true) => {
    if (+AuthUserID === +equipmentData?.["user_id"]) {
      return;
    }
    let EquipMake = equipmentData["make"] || "Not Available";

    // Create a chat object with relevant details
    let ChatObj = {
      equipId: equipmentData["id"],
      senderID: AuthUserID,
      reciverID: equipmentData["user_id"],
      reciverName: equipmentData["first_name"],
      EquipMake: EquipMake,
    };

    // Store the chat object in local storage
    localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
    //ChatClick(ChatObj)

    // Redirect the user to the notifications page for chat interaction
    if(redirect){
      window.location.href = "/notifications";
    }
  };

  //by GD 21 Feb 2024 for chat redirection without login
  const HandleChatOwnerToRenterWithotLogin = () => {
    // Get the sender ID
    let senderID = "";
    // Extract equipment make or set a default value if not available
    let EquipMake = equipmentData["make"] || "Not Available";

    // Create a chat object with relevant details
    let ChatObj = {
      equipId: equipmentData["id"],
      senderID: senderID,
      reciverID: equipmentData["user_id"],
      reciverName: equipmentData["first_name"],
      EquipMake: EquipMake,
    };

    // Store the chat object in local storage
    localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
  };

  const handleChatMessage = () => {
    if (AuthUserID) {
      if (parseFloat(AuthProfileData?.["is_profile_completed"]) === 0) {
        setCompleteProfilePopup(true);
        HandleChatOwnerToRenter(false);
        dispatch(SetRedirectPage("/notifications"))
        return false;
      }
      HandleChatOwnerToRenter();
    } else {
      HandleChatOwnerToRenterWithotLogin();
      dispatch(setLoginModalOpen(true));
      dispatch(setLogInLoading(false));
    }
  };

  const LikeDislikeEquip = async (status) => {
    try {
      setLikeLoading(true);

      // Make the API call to AddToLikeDislike
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const EquipData = await EquipmentAPIs.AddToLikeDislike(
        {
          user_id: AuthUserID.toString(),
          equip_id: params.id.toString(),
          status: status.toString(),
          liked_equip_list_type: params?.list_type,
        },
        headers
      );

      // Check the type of response received
      if (EquipData["type"] == "RXSUCCESS") {
        setLikeLoading(false);
        setLikeStatus(status);
      } else if (
        EquipData["status"] == false &&
        EquipData["slug"] == "Session Expired"
      ) {
        setLikeLoading(false);
        return false;
      } else {
        setLikeLoading(false);
        return false;
      }
    } catch (err) {
      setLikeLoading(false);
      console.log(err);
    }
  };

  const CheckEquipStatus = async () => {
    try {
      setLikeLoading(true);

      // Set headers for the API request
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      // Create the request body
      let reqBody = {
        user_id: AuthUserID?.toString(),
        equip_id: params?.id?.toString(),
        liked_equip_list_type: params?.list_type,
      };

      // Make the API call to EquipStatus
      const EquipStatus = await EquipmentAPIs.EquipStatus(reqBody, headers);

      // Check the type of response received
      if (EquipStatus["type"] == "RXSUCCESS") {
        setLikeLoading(false);

        // Check if the data is not null
        if (EquipStatus["data"] == null) {
          // console.log('comming in null condition...');
        } else {
          setLikeStatus(EquipStatus["data"].status);
        }
      } else if (
        EquipStatus["status"] == false &&
        EquipStatus["slug"] == "Session Expired"
      ) {
        // session expired handling
        setLikeLoading(false);
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(EquipStatus["message"]));
        dispatch(setalertMessagetype("error"));
        setLikeLoading(false);
        return false;
      }
    } catch (err) {
      // Set loading state to false in case of an error
      setLikeLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getEquipDetails(params.id);
    }
  }, []);

  useEffect(() => {
    if (AuthUserID && params?.id && params?.list_type) {
      CheckEquipStatus();
    }
  }, [AuthUserID]);

  return (
    <MainLayout>
      {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      <Container maxWidth={params?.list_type === "sale" ? "md" : "lg"}>
        {params?.list_type === "sale" && !loading ? (
          <EquipDetailsSale
            galleryImages={galleryImages}
            equipmentData={equipmentData}
            handleChatMessage={handleChatMessage}
            LikeLoading={LikeLoading}
            LikeStatus={LikeStatus}
            LikeDislikeEquip={LikeDislikeEquip}
            locationViewRef={locationViewRef}
          />
        ) : !loading ? (
          <EquipDetailsRent
            galleryImages={galleryImages}
            equipmentData={equipmentData}
            handleChatMessage={handleChatMessage}
            LikeLoading={LikeLoading}
            LikeStatus={LikeStatus}
            LikeDislikeEquip={LikeDislikeEquip}
            locationViewRef={locationViewRef}
            timeZoneAbbr={timeZoneAbbr}
            timeZone={timeZone}
            bookingSteps={bookingSteps}
            setBookingSteps={setBookingSteps}
            setCompleteProfilePopup={setCompleteProfilePopup}
            transportOption = {transportOption}
            setTransportOption = {setTransportOption}
          />
        ) : (
          <></>
        )}
        {/* <RentalSummary /> */}
        {bookingSteps === 1 && !loading && (
          <Box ref={locationViewRef}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Box
                component={"img"}
                src={LocationIcon}
                alt=""
                sx={{
                  width: "25px",
                  height: "25px",
                  display: "block",
                  objectFit: "contain",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "GothamBold !important",
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                    md: "20px",
                    lg: "22px",
                    xl: "24px",
                  },
                  mb: 1,
                  mt: 2,
                  textTransform: "uppercase",
                }}
              >
                Equipment Location
              </Typography>
            </Box>
            <Box
              component={"img"}
              src={mapLocation}
              sx={{
                width: "100%",
                display: "block",
                boxSizing: "border-box",
                maxWidth: "400px",
              }}
            />
          </Box>
        )}
        <RentSteps
          open={completeProfilePopup}
          handleClose={() => setCompleteProfilePopup(false)}
          setOpenModal={setTransportOption}
        />
      </Container>
    </MainLayout>
  );
};

export default EquipmentDetail;
