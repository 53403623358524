import {
  Box,
  Button,
  CircularProgress,
  Container,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import LocationIcon from "assets/images/location.png";
import dummyProfile from "assets/images/dummy-profile-pic.jpeg";
import ImageGallery from "./ImageGallery";
import check from "assets/images/check.png";
import dollar from "assets/images/go-dollar.png";
import UsCurrencyFormat from "components/MyofficeTrailer/EquipmentOrders/UsCurrencyFormat";
import ThumbsImg from "assets/images/likeMe.png";
import { useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
const headingStyle = {
  color: "#fff",
  fontFamily: "GothamBold !important",
  fontSize: {
    xs: "16px",
    sm: "18px",
    md: "20px",
    lg: "22px",
    xl: "24px",
  },
};

const subheadingStyle = {
  color: "#faa61a",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
    xl: "18px",
  },
  textTransform: "uppercase",
  mb: 0.5,
};

const textStyle = {
  color: "#fff",
  fontFamily: "Montserrat !important",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
    xl: "18px",
  },
};

const dividerStyle = {
  borderColor: "#faa61a",
  borderWidth: "3px",
  mb: 1,
  mt: 1,
};

const grayDivider = {
  borderColor: "gray",
  borderWidth: "1.5px",
  mb: 0.5,
  mt: 0.5,
};

const rentalFlex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",
};

const flexCommon = {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
};

const underlineYellowText = {
  color: "#faa61a",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  textDecorationColor: "#faa61a",
  textDecoration: "underline",
};

const greenButton = {
  background: "#149247",
  "&:hover": {
    background: "#096e32",
  },
  fontSize: {
    xs: "16px",
    sm: "18px",
    md: "20px",
    lg: "24px",
    xl: "28px",
  },
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Montserrat !important",
  padding: "0px 15px",
  fontWeight: "800",
  width: "100%",
  borderRadius: "7px",
};

const EquipDetailsSale = ({
  galleryImages,
  equipmentData,
  handleChatMessage,
  LikeLoading,
  LikeStatus,
  LikeDislikeEquip,
  locationViewRef,
}) => {
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );

  return (
    <Box>
      {/* <Container maxWidth={"md"} sx={{ padding: { xs: "unset" } }}> */}
      {/* Gallery Section */}
      <Box
        sx={{
          mb: 2,
          mt: 2,
          position: "relative",
          borderBottom: "5px solid #faa61a",
        }}
      >
        <ImageGallery imagesGallerys={galleryImages} type="sale" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          {isLoggedin && (
            <>
              {LikeLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {LikeStatus == "1" || LikeStatus == 1 ? (
                    <Box
                      // className="image-like-icon"
                      onClick={() => {
                        LikeDislikeEquip(0);
                      }}
                      sx={{ width: "30px", height: "30px", cursor: "pointer" }}
                    >
                      <Box
                        component={"img"}
                        src={ThumbsImg}
                        alt="Thumps icon"
                        sx={{ width: "100%", height: "100%", display: "block" }}
                      />
                    </Box>
                  ) : (
                    <Box
                      // className="image-like-icon"
                      sx={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        filter: "grayscale(100%)",
                      }}
                      onClick={() => {
                        LikeDislikeEquip(1);
                      }}
                    >
                      <Box
                        component={"img"}
                        src={ThumbsImg}
                        alt="Thumps icon"
                        sx={{ width: "100%", height: "100%", display: "block" }}
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Box>
        <Box
          // className="eqp-price"
          sx={{
            backgroundColor: "#000",
            borderRadius: "0",
            bottom: ".5rem",
            padding: "5px 10px",
            position: "absolute",
            right: ".5rem",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "baseline",
              gap: "4px",
              fontSize: "20px",
            }}
          >
            <Typography
              component={"span"}
              sx={{ color: "#fff", fontSize: "18px" }}
            >
              <UsCurrencyFormat
                amount={equipmentData?.equip_sale_price}
                decimal={false}
              />
            </Typography>
            <Typography
              component={"span"}
              sx={{ color: "#faa61a", fontSize: "15px" }}
            >
              {equipmentData?.obo == 1 && <> obo</>}
            </Typography>
          </Typography>
        </Box>
      </Box>

      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.2rem",
          mt: 2,
          mb: 1,
        }}
      >
        <Typography
          variant="h3"
          component="h3"
          sx={{
            color: "white",
            fontSize: "3rem",
            fontWeight: "900",
            fontFamily: "GothamBold !important",
          }}
        >
          {equipmentData?.make || "NA"}
        </Typography>
        <Typography
          variant="h5"
          component="h5"
          sx={{
            color: "#faa61a",
            fontSize: "1.5rem",
            fontWeight: "600",
          }}
        >
          {equipmentData?.model || "NA"}
        </Typography>
      </Box>

      {/* Location / Owner */}
      <Box sx={rentalFlex}>
        <Box sx={{ ...flexCommon, cursor: "pointer" }}>
          <Box
            component={"img"}
            src={LocationIcon}
            alt=""
            sx={{
              width: "30px",
              height: "30px",
              display: "block",
              objectFit: "contain",
            }}
          />
          <Typography
            sx={{ ...underlineYellowText, color: "#fff" }}
            onClick={() => {
              // by GD 12Dec to scrol to map
              locationViewRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }}
          >
            {equipmentData?.city || "NA"},{equipmentData?.state || "NA"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Box
            component={"img"}
            src={equipmentData?.profile_pic || dummyProfile}
            sx={{
              border: "2px solid #fff",
              borderRadius: "50%",
              display: "block",
              height: "70px",
              maxHeight: "70px",
              objectFit: "cover",
              width: "70px",
            }}
          />
          <Box>
            <Typography
              sx={{
                color: "#faa61a",
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "17px",
                  xl: "18px",
                },
                textTransform: "capitalize",
              }}
            >
              Owner:
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "17px",
                  xl: "18px",
                },
                textTransform: "capitalize",
              }}
            >
              {equipmentData?.first_name}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box sx={{ ...flexCommon, mt: 2, mb: 1 }}>
          <Box
            component={"img"}
            src={check}
            alt=""
            sx={{ width: "22px", height: "auto", objectFit: "contain" }}
          />
          <Typography
            sx={{
              textTransform: "inherit",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                ...subheadingStyle,
                fontFamily: "GothamBold !important",
                fontWeight: "900",
              }}
            >
              RPO Available :
            </Typography>

            <Typography component={"span"} sx={{ ...textStyle }}>
              &nbsp;The owner may offer this as a rental with purchase option.
            </Typography>
          </Typography>
        </Box>

        {/* Button */}
        <Box sx={{ ...flexCommon, justifyContent: "center", mb: 2, mt: 2 }}>
          <Button
            sx={{
              ...greenButton,
              border: "2px solid #faa61a",
              fontSize: {
                xs: "16px",
                sm: "18px",
                md: "20px",
                textTransform: "capitalize",
              },
              width: {
                xs: "100%",
                sm: "auto",
                md: "50%",
                lg: "50%",
                xl: "50%",
              },
              fontWeight: "700",
              padding: "5px",
            }}
            onClick={handleChatMessage}
          >
            Message Owner
          </Button>
        </Box>

        {/* Details */}
        <Box
          sx={{
            ...flexCommon,
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Typography
              sx={{
                ...subheadingStyle,
                textTransform: "inherit",
                fontWeight: "900",
                display: "flex",
                alignItems: "baseline",
                fontFamily: "GothamBold !important",
              }}
            >
              Asking Price :
              <Typography component={"span"} sx={{ ...textStyle }}>
                &nbsp;
                <UsCurrencyFormat
                  amount={equipmentData?.equip_sale_price}
                  decimal={false}
                />
                {equipmentData?.obo == 1 && <> obo</>}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                ...subheadingStyle,
                textTransform: "inherit",
                fontWeight: "900",
                display: "flex",
                alignItems: "baseline",
                fontFamily: "GothamBold !important",
              }}
            >
              Year :
              <Typography component={"span"} sx={{ ...textStyle }}>
                &nbsp;{equipmentData?.year || "NA"}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                ...subheadingStyle,
                textTransform: "inherit",
                fontWeight: "900",
                display: "flex",
                alignItems: "baseline",
                fontFamily: "GothamBold !important",
              }}
            >
              Mileage :
              <Typography component={"span"} sx={{ ...textStyle }}>
                &nbsp;{equipmentData?.mileage || "NA"}{" "}
                {equipmentData?.mileage && "miles"}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                ...subheadingStyle,
                textTransform: "inherit",
                fontWeight: "900",
                display: "flex",
                alignItems: "baseline",
                fontFamily: "GothamBold !important",
              }}
            >
              Hours :
              <Typography component={"span"} sx={{ ...textStyle }}>
                &nbsp;{equipmentData?.hours || "NA"}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                ...subheadingStyle,
                textTransform: "inherit",
                fontWeight: "900",
                display: "flex",
                alignItems: "baseline",
                fontFamily: "GothamBold !important",
              }}
            >
              Description :
              <Typography component={"span"} sx={{ ...textStyle }}>
                &nbsp;{equipmentData?.description || "NA"}
              </Typography>
            </Typography>
          </Box>
        </Box>

        {/* Need Financing */}
        <Box
          sx={{
            border: "2px solid #faa61a",
            borderRadius: "10px",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            mt: 2,
          }}
        >
          <Box
            component={"img"}
            src={dollar}
            alt=""
            sx={{ width: "55px", height: "55px", objectFit: "contain" }}
          />
          <Typography
            sx={{
              textTransform: "inherit",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                ...subheadingStyle,
                fontFamily: "GothamBold !important",
                fontWeight: "900",
                textTransform: "capitalize",
              }}
            >
              Need Financing?
            </Typography>

            <Typography component={"span"} sx={{ ...textStyle }}>
              &nbsp;GoEquipme Lending can help.
              <Link
                href={"/equipmentlending"}
                sx={{ ...textStyle, textDecoration: "underline", ml: "4px" }}
              >
                Click here to get approved.
              </Link>
            </Typography>
          </Typography>
        </Box>
      </Box>
      {/* </Container> */}
    </Box>
  );
};

export default EquipDetailsSale;
