import { Box } from '@mui/material';
import React from 'react';

interface UsCurrencyFormatProps {
  amount: number;
  decimal?:boolean;
}

const UsCurrencyFormat: React.FC<UsCurrencyFormatProps> = ({ amount ,decimal=true}): JSX.Element => {
  amount = typeof amount === "string"?parseFloat(amount):amount;
  let convertedAmount = !isNaN(amount)
    ? amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '$0.00';
  
  if(!decimal && amount >= 1) {
    convertedAmount = convertedAmount?.toString()?.split(".")[0];
  }

  return <Box component={"span"}>{convertedAmount}</Box>;
};

export default UsCurrencyFormat;
