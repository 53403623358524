import {
  Box,
  Button,
  Container,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import UsCurrencyFormat from "components/MyofficeTrailer/EquipmentOrders/UsCurrencyFormat";
import addImg from "assets/images/details-page/Add.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import bookingimg from "assets/images/details-page/booking2.png";
import moment from "moment";

const headingStyle = {
  color: "#fff",
  fontFamily: "GothamBold !important",
  fontSize: {
    xs: "16px",
    sm: "18px",
    md: "20px",
    lg: "22px",
    xl: "24px",
  },
};

const textStyle = {
  color: "#fff",
  fontFamily: "Montserrat !important",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
};

const greenButton = {
  background: "#149247",
  "&:hover": {
    background: "#096e32",
  },
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Montserrat !important",
  padding: "0px 15px",
  fontWeight: "800",
  width: "100%",
  borderRadius: "7px",
};

const yellowButton = {
  background: "#faa61a",
  "&:hover": {
    background: "#faa61a",
  },
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
  color: "#000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Montserrat !important",
  padding: "0px 15px",
  fontWeight: "800",
  width: "100%",
  borderRadius: "7px",
};

const rentalFlex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",
};

const underlineYellowText = {
  color: "#faa61a",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  textDecorationColor: "#faa61a",
  textDecoration: "underline",
};

const selectHereButton = {
  background: "#2d2d2d",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  fontFamily: "Montserrat !important",
  fontSize: "14px",
  // border: "none",
  border: "1px solid #faa61a",
  borderRadius: "7px",
  width: "100%",
};

const flexCommon = {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
};

const underlinewhiteText = {
  color: "#fff",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "15px",
    xl: "16px",
  },
  textDecorationColor: "#fff",
  textDecoration: "underline",
  fontFamily: "Montserrat !important",
  fontWeight: "600",
  cursor:"pointer",
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  //  overflowX:'hidden',
  bgcolor: "#2e2e2e",
  border: "5px solid #faa61a",
  borderRadius: "10px",
  outline: "none !important",
  // p: 3,
  width: {
    xs: "90%",
    sm: "400px",
    md: "450px",
    lg: "450px",
    xl: "450px",
    // sm:'auto'
  },
};

const RentalSummary2 = ({
  timeZoneAbbr,
  equipRentedDates,
  equipRentedTimes,
  priceDetails,
  bookingSteps,
  setBookingSteps,
  submitRentalRequest,
  handleAddBalancePopupOpen,
  WalletBalance,
}) => {
  return (
    <Box sx={{ p: { xs: "2", md: "4" } }}>
      <Container maxWidth="md">
        <Box
          sx={{ width: { xs: "100%", sm: "100%", md: "90%" }, margin: "auto" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Button
              variant="text"
              onClick={() => {
                setBookingSteps(1);
              }}
              sx={{ minWidth:'unset !important' }}
            >
              <ArrowBackIcon sx={{ color: "#fff", fontSize: "32px" }} />
            </Button>
            <Box>
              <Box
                component={"img"}
                src={bookingimg}
                alt=""
                sx={{ display: "block", width: "35px", height: "auto" }}
              />
            </Box>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "GothamBold !important",
                fontSize: "clamp(35px,3vw,50px) !important",
              }}
            >
              Let's{" "}
              <Typography
                component={"span"}
                sx={{
                  marginLeft:'-13px',
                  color: "#faa61a",
                  fontFamily: "GothamBold !important",
                  fontSize: "clamp(35px,3vw,50px) !important",
                }}
              >
                Go!
              </Typography>
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: { xs: "90%", sm: "70%", md: "60%" },
            margin: "auto",
            mt: 3,
          }}
        >
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="h5"
              component={"h5"}
              sx={{ ...headingStyle, mb: 2 }}
            >
              Rental Dates
            </Typography>
            <Box sx={{ ...flexCommon, flexDirection: "column", gap: "0.4rem" }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1fr",
                  alignItems: "center",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    ...textStyle,
                    fontSize: "clamp(15px, 3vw, 18px) !important",
                    fontWeight: "500",
                  }}
                >
                  Start
                </Typography>
                <Typography
                  sx={{
                    ...textStyle,
                    fontSize: "clamp(15px, 3vw, 18px) !important",
                    fontWeight: "500",
                    textAlign: "right",
                  }}
                >
                  {equipRentedDates[0]
                    ? moment(
                        `${equipRentedDates[0]?.format("MM/DD/YY")} ${
                          equipRentedTimes.startTime
                        }`
                      ).format("MM/DD/YY hh:mm A") + ` (${timeZoneAbbr})`
                    : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1fr",
                  alignItems: "center",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    ...textStyle,
                    fontSize: "clamp(15px, 3vw, 18px) !important",
                    fontWeight: "500",
                  }}
                >
                  End
                </Typography>
                <Typography
                  sx={{
                    ...textStyle,
                    fontSize: "clamp(15px, 3vw, 18px) !important",
                    fontWeight: "500",
                    textAlign: "right",
                  }}
                >
                  {equipRentedDates[1]
                    ? moment(
                        `${equipRentedDates[1]?.format("MM/DD/YY")} ${
                          equipRentedTimes.endTime
                        }`
                      ).format("MM/DD/YY hh:mm A") + ` (${timeZoneAbbr})`
                    : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography variant="h5" component="h5" sx={headingStyle}>
            Rental Summary
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "3px",
              mt: 1.5,
              mb: 1.5,
            }}
          >
            <Box sx={rentalFlex}>
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: "clamp(15px, 3vw, 18px) !important",
                  fontWeight: "500",
                }}
              >
                Rental Price
              </Typography>
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: "clamp(15px, 3vw, 18px) !important",
                  fontWeight: "500",
                }}
              >
                <UsCurrencyFormat amount={priceDetails?.rentPrice || 0} />
              </Typography>
            </Box>
            <Box sx={rentalFlex}>
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: "clamp(15px, 3vw, 18px) !important",
                  fontWeight: "500",
                }}
              >
                Security Deposit
              </Typography>
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: "clamp(15px, 3vw, 18px) !important",
                  fontWeight: "500",
                }}
              >
                <UsCurrencyFormat amount={priceDetails?.securityDeposit || 0} />
              </Typography>
            </Box>
            <Box sx={rentalFlex}>
              <Typography
                sx={{
                  ...underlineYellowText,
                  fontSize: "clamp(15px, 3vw, 18px) !important",
                  fontWeight: "500",
                }}
              >
                EquipMe Protection
              </Typography>
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: "clamp(15px, 3vw, 18px) !important",
                  fontWeight: "500",
                }}
              >
                <UsCurrencyFormat amount={priceDetails?.equipProtection || 0} />
              </Typography>
            </Box>
            <Box sx={rentalFlex}>
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: "clamp(15px, 3vw, 18px) !important",
                  fontWeight: "500",
                }}
              >
                Transport Total
              </Typography>
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: "clamp(15px, 3vw, 18px) !important",
                  fontWeight: "500",
                }}
              >
                <UsCurrencyFormat amount={priceDetails?.deliveryPrice || 0} />
              </Typography>
            </Box>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: "#faa61a",
                fontWeight: "900",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "16px",
                  sm: "18px",
                  md: "20px",
                  lg: "24px",
                  xl: "28px",
                },
              }}
            >
              Total:
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: "900",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "16px",
                  sm: "18px",
                  md: "20px",
                  lg: "24px",
                  xl: "28px",
                },
              }}
            >
              <UsCurrencyFormat amount={priceDetails?.grandTotal || 0} />
            </Typography>
          </Box> */}
          <Box
            sx={{
              ...flexCommon,
              mt: 2,
              justifyContent: "center",
              flexDirection: "column",
              gap: "5px",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: "#faa61a",
                fontWeight: "700",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "20px",
                  sm: "21px",
                  md: "22px",
                  lg: "23px",
                  xl: "24px",
                },
              }}
            >
              Total Amount to Pay
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: "700",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "20px",
                  sm: "21px",
                  md: "22px",
                  lg: "23px",
                  xl: "24px",
                },
              }}
            >
              <UsCurrencyFormat amount={priceDetails?.grandTotal || 0} />
            </Typography>
          </Box>
          <Button
            sx={{
              ...greenButton,
              padding: "5px 10px",
              textTransform: "capitalize",
              borderRadius: "8px",
            }}
            onClick={submitRentalRequest}
          >
            Request Rental
          </Button>
          <Box sx={{ ...flexCommon, mt: 2, justifyContent: "center" }}>
            <Typography
              sx={{
                color: "#faa61a",
                fontWeight: "700",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "18px",
                  xl: "20px",
                },
              }}
            >
              My Wallet Balance:
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: "700",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "18px",
                  xl: "20px",
                },
              }}
            >
              <UsCurrencyFormat amount={WalletBalance || 0} />
            </Typography>
          </Box>
          <Box
            sx={{
              ...flexCommon,
              justifyContent: "center",
              mt: 2,
              gap: "0.5rem",
            }}
          >
            <Box
              component={"img"}
              src={addImg}
              alt=""
              sx={{ width: "20px", height: "20px", objectFit: "contain" }}
            />
            <Typography
              sx={{...underlinewhiteText, fontSize:'clamp(15px, 3vw, 18px)'}}
              onClick={handleAddBalancePopupOpen}
            >
              Add Money
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default RentalSummary2;
