import { useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExtendRental from "../../../pages/equipment-detail/ExtendRental";
import { EquipmentAPIs } from "../../../service/api-service";
import { useDispatch, useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};
const ExtendRentalPopup = ({
  extendRentalPopup,
  handleExtendPopupClose,
  orderId,
  onExtendConfirmation,
  extendRentalEquipment,
  handleNotificationRedirection,
}) => {
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );

  const [loading, setLoading] = useState<boolean>(false);
  extendRentalEquipment["id"] = extendRentalEquipment["equip_id"];
  extendRentalEquipment["user_id"] = extendRentalEquipment["owner_id"];
  // const [rentedEquip, setRentedEquip] = useState<any>({});
  // const fetchEquipDetails = async () => {
    // console.log("extendRentalEquipment 2",extendRentalEquipment);
    // if (Object.entries(extendRentalEquipment || {}).length) {
      // const equipmentRented = extendRentalEquipment?.find(
      //   (equip) => equip?.order_id === orderId
      // );
      // if (equipmentRented) {
        // }
      // extendRentalEquipment["id"] = extendRentalEquipment["equip_id"];
      // extendRentalEquipment["user_id"] = extendRentalEquipment["owner_id"];
  //     setRentedEquip(extendRentalEquipment);
  //   }
  // };

  // useEffect(() => {
  //   if (extendRentalPopup) {
  //     fetchEquipDetails();
  //   }
  // }, [extendRentalPopup]);
  return (
    <Modal
      keepMounted
      open={extendRentalPopup}
      onClose={handleExtendPopupClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",

          //  overflowX:'hidden',
          bgcolor: "#2e2e2e",
          border: "5px solid #faa61a",
          borderRadius: "10px",
          outline: "none !important",
          // p: 1,
          width: {
            xs: "90%",
            sm: "400px",
            md: "500px",
            lg: "500px",
            xl: "500px",
          },
        }}
      >
        <IconButton
          onClick={handleExtendPopupClose}
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-20px",
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          
          <Box
            // className="login__main-div__form popup-cmmn popup-cmmn-modal pupup-scroll edit-eqp-inner extend-rental"
            sx={{
              width: "100%",
              maxHeight: "690px",
              overflowY: "auto",
              p: 3,
              boxSizing: "border-box",
            }}
          >
            {loading && (
              <Box>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>
            )}

            <Box className="">
              {extendRentalPopup && Object.entries(extendRentalEquipment)?.length > 0 && (
                <ExtendRental
                  setLocal={true}
                  ActiveOrder={extendRentalEquipment}
                  handleChatMessage = {handleNotificationRedirection}
                  Result={(data: string) => {
                    if (data) {
                      if (data === "orderExtended") {
                        onExtendConfirmation();
                        handleExtendPopupClose();
                      }
                    }
                  }}
                />
              )}
            </Box>
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
    </Modal>
  );
};

export default ExtendRentalPopup;
